import { Button } from "react-bootstrap";

import { appConstants, colorsConstants } from "../../../../../_constants/app.constants";
import { useEffect, useState } from "react";

import "./styles.scss";

function ButtonAction({ children, className: classNameProps = "", onClick, color, style: styleProps, disabled = false }) {
    const [className, setClassName] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        switch (color) {
            case colorsConstants.PURPLE:
                setClassName("tah-purple-btn");
                break;
            case colorsConstants.LIGHT_BLUE:
                setClassName("tah-light-blue-btn");
                break;
            case colorsConstants.BLACK:
                setClassName("tah-black-btn");
                break;
            case colorsConstants.RED:
                setClassName("tah-red-btn");
                break;
            case colorsConstants.GREEN:
                setClassName("tah-green-btn");
                break;
            default:
                setClassName("tah-purple-btn");
                break;
        }
    }, [color]);

    function handleClick(event) {
        onClick(event);
    }

    return (
        <Button
            variant=""
            className={classNameProps ? `${className} ${classNameProps}` : `${className} w-100 px-3 py-2 align-self-center scrollbar-hidden`}
            style={{
                overflow: !styleProps? "auto hidden" : "",
                fontSize:
                    windowWidth > appConstants.SCREEN_WIDTH_EXTRA_LARGE
                        ? 1 + "vw"
                        : windowWidth > 992
                            ? 1 + "vw"
                            : windowWidth >= appConstants.SCREEN_WIDTH_MEDIUM
                                ? 1.3 + "vw"
                                : 2.2 + "vw",
                ...styleProps,
            }}
            onClick={handleClick}
            disabled={disabled}
        >
            {children ? children : null}
        </Button>
    )
}


ButtonAction.propTypes = {

}


ButtonAction.defaultProps = {
    onClick: () => { return },
}

export default ButtonAction;