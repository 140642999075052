import AddressFormGroupInput from "components/admin/Others/forms/components/addressFormGroupInput";
import PhoneFormGroupInput from "components/admin/Others/forms/components/phoneFormGroupInput";
import SelectFormGroupInput from "components/admin/Others/forms/components/selectFormGroupInput";
import TextFormGroupInput from "components/admin/Others/forms/components/textFormGroupInput";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

import { useState } from "react";
import loading from "components/admin/Others/process/loading";
import globalSwal from "components/admin/Others/alert/globalSwal";
import { checkMissingData } from "_helpers";
import { apiService } from "_services/api.service";

import { appConstants } from "_constants";
import CustomerModel from "_models/customer.model";

function FormCustomer() {
    const [submitted, setSubmitted] = useState(false);
    const [customer, setCustomer] = useState(new CustomerModel());

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitted(true);

        if (checkMissingData(customer, ["id", "created", "billing", "students", "requests"])) {
            loading(true);

            await apiService.postCustomer(customer.toFormCreate()).then(success => {
                if (success) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(function () {
                        window.history.back();
                    });
                }
                else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            })
        } else {
            Swal.fire({
                icon: appConstants.SWAL_ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    }

    async function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_LASTNAME:
                setCustomer({ ...customer, lastname: value })
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                setCustomer({ ...customer, firstname: value })
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setCustomer({ ...customer, gender: value })
                break;
            case appConstants.FORM_LABEL_MAIL:
                setCustomer({ ...customer, email: value })
                break;
            case appConstants.FORM_LABEL_ADDRESS_NUMBER:
                setCustomer({ ...customer, addresses: [{ ...customer.addresses[0], number: value }] })
                break;
            case appConstants.FORM_LABEL_ADDRESS_STREET:
                setCustomer({ ...customer, addresses: [{ ...customer.addresses[0], street: value }] })
                break;
            case appConstants.FORM_LABEL_ADDRESS_CITY:
                setCustomer({ ...customer, addresses: [{ ...customer.addresses[0], city: value }] })
                break;
            case appConstants.FORM_LABEL_ADDRESS_POSTCODE:
                setCustomer({ ...customer, addresses: [{ ...customer.addresses[0], zipcode: value }] })
                break;
            case appConstants.FORM_LABEL_PHONE:
                setCustomer({ ...customer, phone: value })
                break;
            default:
                break;
        }
    }

    return (
        <form style={{ overflow: "hidden auto" }} onSubmit={onSubmit}>
            <div className="mt-3 d-flex justify-content-between w-100">
                <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={customer?.lastname} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={customer?.firstname} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={customer?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_MAIL} type="email" value={customer?.email} onChange={onChange} submitted={submitted} />
            </div>
            <div className="d-flex justify-content-between w-100">
                <AddressFormGroupInput classname="mx-3 mb-2 d-flex w-100" customer={customer} onChange={onChange} submitted={submitted} formOnly />
            </div>

            <div className="col-6 d-flex justify-content-between w-100 px-0">
                <PhoneFormGroupInput label={appConstants.FORM_LABEL_PHONE} value={customer?.phone} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex mt-5">
                <Button className="h-100 oc-button-empty-blue ml-3 mb-3" style={{ fontSize: "inherit" }} onClick={onSubmit}>
                    CRÉER
                </Button>
            </div>
        </form >
    )
}

export default FormCustomer;