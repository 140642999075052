import React from 'react';

import MenuWithButtons from 'components/admin/Others/menus/menuWithButtons/menuWithButtons';
import ButtonMenu from 'components/admin/Others/buttons/buttonMenu/buttonMenu';
import BillingTab from './tabs/billingTab';
import StatisticsTab from './tabs/statisticsTab';
import SettingsTab from './tabs/settingsTab';

class Tools extends React.Component {

    state = {
        windowHeight: window.innerHeight,
    };

    componentDidMount = async () => {
        window.addEventListener("resize", this.eventListener)

    }

    componentWillUnmount() {
        // remove event listener
        window.removeEventListener('resize', this.eventListener);
    }

    eventListener = () => {
        this.setState({ windowHeight: window.innerHeight });
    }
    
    render() {
        return (
            <div className={"d-flex flex-column pt-xl-5 pl-5 h-100" + (this.state.windowHeight < 800 ? " pb-4" : " pb-5")} style={{ overflow: 'hidden' }}>
                <MenuWithButtons className="w-100 h-100 px-0 d-flex flex-column" style={{ overflow: "hidden" }}>
                    <ButtonMenu className="ml-0 mb-5" onActive={<BillingTab />} >FACTURATION</ButtonMenu>
                    <ButtonMenu className="mb-5" onActive={<StatisticsTab /> } active >STATISTIQUES</ButtonMenu>
                    <ButtonMenu className="mb-5 mr-0" onActive={<SettingsTab />} >PARAMÈTRES</ButtonMenu>
                </MenuWithButtons>
            </div>
        );
    }
}

export default Tools;