import SelectFormGroupInput from "../../../../Others/forms/components/selectFormGroupInput";
import FormSection from "../../../../Others/forms/formSection";
import TextFormGroupInput from "../../../../Others/forms/components/textFormGroupInput";
import LoaderSpinner from "../../../../Others/loader/loader";
import MultiSelectFormGroupInput from "components/admin/Others/forms/components/multiSelectFormGroupInput";
import AdminCRUD from "../components/adminCRUD";

import PropTypes from 'prop-types';

import ButtonLink from "../../../../Others/buttons/buttonLink/buttonLink";

import { appConstants } from "../../../../../../_constants/app.constants";

function MainInfoTab({ student, onChange = () => { return }, onCreate = () => { return }, submitted, options = {} }) {
    if (typeof student === "undefined") {
        return (
            <LoaderSpinner />
        )
    } else if (!student) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }

    return (
        <form style={{ overflow: "hidden auto" }}>

            <FormSection title="Élève :" className="w-100 police-gilroy-font" style={{ fontSize: 110 + "%" }}>
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={student?.lastname} onChange={onChange} submitted={submitted} />
                    <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={student?.firstname} onChange={onChange} submitted={submitted} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={student?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                    <SelectFormGroupInput label={appConstants.FORM_LABEL_CLASS} value={student?.grade} options={options?.grades} onChange={onChange} submitted={submitted} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <AdminCRUD label={appConstants.FORM_LABEL_SCHOOL} value={student?.school} onChange={onChange} onCreate={onCreate} submitted={submitted} options={options} />
                    <TextFormGroupInput label={appConstants.FORM_LABEL_BIRTHDATE} value={student?.birthdate} onChange={onChange} type="date" submitted={submitted} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <MultiSelectFormGroupInput className="w-50 px-3" label={appConstants.FORM_LABEL_PASSIONS} value={student?.hobbies} options={options?.hobbies} onChange={onChange} />
                    <MultiSelectFormGroupInput className="w-50 px-3" label={appConstants.FORM_LABEL_OPTIONS} value={student?.options} options={options?.options} onChange={onChange} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <MultiSelectFormGroupInput className="w-50 px-3" label={appConstants.FORM_LABEL_CHARACTERISTICS} value={student?.characteristics} options={options?.characteristics} onChange={onChange} />
                    <TextFormGroupInput className="w-50 px-3" label={appConstants.FORM_LABEL_MAIL} value={student?.email} onChange={onChange} type="mail" />
                </div>

                <div className="d-flex align-items-center my-3 pl-3" style={{ flexShrink: 0 }}>
                    <p className="mb-0 pl-0 col-2 police-gilroy-font">PARENT :</p>
                    {student?.parent?.id ?
                        <ButtonLink className="btn oc-button-empty-blue px-4 py-1 text-center" link={`/admin-home/profils/customers/${student?.parent?.id}`} firstname={student?.parent?.firstname} lastname={student?.parent?.lastname?.toUpperCase()} />
                        : null
                    }
                </div>
            </FormSection>

        </form>
    );

}

MainInfoTab.propTypes = {
    customer: PropTypes.object,
    options: PropTypes.shape({
        grades: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
        hobbies: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }))
    }),
}

export default MainInfoTab;