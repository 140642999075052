import React, { useEffect, useState } from 'react'
import InlineTable from './inlineTable';

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "sweetalert2/src/sweetalert2.scss";
import "../../tools.scss";

function TableArray({ raws: rawsProp, columns: columnsProps, hideList }) {
    const [raws, setRaws] = useState([]);
    const headerTitleList = columnsProps?.filter(elt => !hideList.includes(elt));
    const firstRaw = raws ? raws?.find((_elt, index) => index === 0) : {};

    useEffect(() => {
        let raw = [];
        for (let i = 0; i < rawsProp?.length; i++) {
            raw.push({
                edit: false,
                ...rawsProp[i]
            })
        }
        setRaws(raw);
    }, [rawsProp]);


    function onDeleteSuccess(rawId) {
        const rawIndex = raws.findIndex(raw => raw.id === rawId);
        let newRaws = raws;
        newRaws?.splice(rawIndex, 1);
        setRaws([...newRaws]);
    }

    function onCreateSuccess(newRaw) {
        let newRaws = raws;
        newRaws.push(newRaw);
        setRaws([...newRaws]);
    }

    return (
        <div className="w-100" style={{ overflow: 'auto' }}>
            <table className="table table-bordered w-100">
                <thead>
                    <tr>
                        {headerTitleList?.map((elt, index) =>
                            <th scope="col" key={index}>{elt?.toUpperCase()}</th>
                        )}
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <InlineTable raw={firstRaw} columns={headerTitleList} onCreateSuccess={onCreateSuccess} empty edit />

                    {raws?.map((raw, _index) =>
                        <InlineTable raw={raw} columns={headerTitleList} onDeleteSuccess={onDeleteSuccess} />
                    )}
                </tbody>
            </table>
        </div>

    )
}

export default TableArray;