import globalSwal from "components/admin/Others/alert/globalSwal";
import MultiSelectFormGroupInput from "components/admin/Others/forms/components/multiSelectFormGroupInput";
import SelectAsyncSearchFormGroupInput from "components/admin/Others/forms/components/selectAsyncSearchFormGroupInput";
import SelectFormGroupInput from "components/admin/Others/forms/components/selectFormGroupInput";
import TextFormGroupInput from "components/admin/Others/forms/components/textFormGroupInput";
import loading from "components/admin/Others/process/loading";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { optionsActions } from "_actions/options.actions";
import { apiConstants, appConstants } from "_constants";
import { checkMissingData } from "_helpers";
import StudentModel from "_models/student.model";
import { apiService } from "_services/api.service";

function FormStudent({ customerId }) {
    const [submitted, setSubmitted] = useState(false);
    const [hobbies, setHobbies] = useState(undefined);
    const [grades, setGrades] = useState(undefined);
    const [student, setCustomer] = useState(new StudentModel());
    const schoolOptions = useSelector(store => store?.options?.schools);
    const dispatch = useDispatch();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setHobbies(await apiService.getSkills(null, apiConstants.API_FILTER_HOBBY));
        setGrades(await apiService.getGrades());
        if (!!!schoolOptions) {
            const schools = await apiService.getSchools();
            dispatch(optionsActions.setOptions({ schools: schools.map(school => school.toOptionModel()) }));
        }
    }

    async function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_LASTNAME:
                setCustomer({ ...student, lastname: value })
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                setCustomer({ ...student, firstname: value })
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setCustomer({ ...student, gender: value })
                break;
            case appConstants.FORM_LABEL_BIRTHDATE:
                setCustomer({ ...student, birthdate: value })
                break;
            case appConstants.FORM_LABEL_SCHOOL:
                setCustomer({ ...student, school: value })
                break;
            case appConstants.FORM_LABEL_STUDENT_LEVEL:
                setCustomer({ ...student, grade: value })
                break;
            case appConstants.FORM_LABEL_PASSIONS:
                setCustomer({ ...student, skills: value })
                break;
            default:
                break;
        }
    }

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitted(true);
        if (checkMissingData(student, ["skills", "email", "id", "hobbies", "parent", "lessons", "extra", "options", "characteristics"])) {
            loading(true);

            await apiService.postStudent(student.toTutorForm(customerId)).then(success => {
                if (success) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(function () {
                        window.history.back();
                    });
                }
                else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            })
        } else {
            Swal.fire({
                icon: appConstants.SWAL_ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    }

    return (
        <form className="col-10 px-0 h-100 d-flex flex-column" style={{ overflow: "hidden auto", flex: "auto" }} onSubmit={onSubmit}>
            <div className="mt-3 d-flex justify-content-between w-100">
                <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={student?.lastname} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={student?.firstname} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={student?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_BIRTHDATE} value={student?.birthdate} onChange={onChange} submitted={submitted} type="date" />
            </div>

            <div className="d-flex justify-content-between w-100">
                <SelectAsyncSearchFormGroupInput value={student?.school} defaultOptions={schoolOptions} label={appConstants.FORM_LABEL_SCHOOL} onChange={onChange} submitted={submitted} onSearch={apiService.getSchoolWithCallback} />
                <SelectFormGroupInput label={appConstants.FORM_LABEL_STUDENT_LEVEL} value={student?.grade} options={grades} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <MultiSelectFormGroupInput className="col-12" label={appConstants.FORM_LABEL_PASSIONS} value={student?.skills} options={hobbies} onChange={onChange} />
            </div>

            <div className="d-flex mt-5 pl-3">
                <Button
                    className="h-100 oc-button-empty-blue"
                    style={{ fontSize: "inherit" }}
                    onClick={onSubmit}
                >
                    CRÉER
                </Button>
            </div>
        </form>
    )
}

export default FormStudent;