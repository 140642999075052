import { optionsConstants } from "_constants/options.constants";

export const optionsActions = {
    setOptions,
};

function setOptions(options) {
    return {
        type: optionsConstants.SET_OPTIONS,
        value: options,
    }
}