function FormSection({ children, title, className, style }) {
    return (
        <div className="w-100 m-0 mb-4">
            <div className="px-3 mb-4">
                <h2 className={className ? className : "oc-title-font oc-list-deco w-100"} style={style}>
                    {title}
                </h2>
            </div>
            {children}
        </div>

    )
}

export default FormSection