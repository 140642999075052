import { apiConstants } from "_constants";
import AddressModel from "./address.model";
import FileModel from "./file.model";
import UserModel from "./user.model";

export default class TutorModel extends UserModel {
    /**
     * @param {number} userId
     * @param {string} lastname
     * @param {string} firstname
     * @param {string} gender
     * @param {string} email
     * @param {AddressModel} address
     * @param {string} birthdate - Date of format : YYYY-MM-DD
     * @param {Array<OptionModel>} subjects
     * @param {Array<OptionModel>} hobbies
     * @param {string} phone
     * @param {string} nationality
     * @param {string} notes
     * @param {FileModel} avatar
     * @param {FileModel} identityProof
     * @param {FileModel} bacMarks
     * @param {FileModel} schoolProof
     * @param {Array<>} requests
     * @param {string} created - Date of format dd/mm/yyyy
     * @param {string} siret
     * @param transports
     * @param characteristics
     */
    constructor(userId, lastname, firstname, gender, email, address, birthdate, subjects, hobbies, phone, nationality, notes, stripeConnectAccountId, avatar, identityProof, bacMarks, schoolProof, requests, created, siret, transports = [], characteristics = []) {
        super(userId, lastname, firstname, email);

        this.address = address ? address : new AddressModel(null, "", "", "", "");
        this.gender = gender ? gender : "";
        this.phone = phone ? phone : "";
        this.birthdate = birthdate ? birthdate : undefined;
        this.subjects = subjects ? subjects : undefined;
        this.hobbies = hobbies ? hobbies : undefined;
        this.nationality = nationality ? nationality : "";
        this.notes = notes ? notes : "";
        this.avatar = avatar ? avatar : new FileModel(null, null, apiConstants.FILES.AVATAR.fileType, null);
        this.identityProof = identityProof ? identityProof : new FileModel(null, null, apiConstants.FILES.ID.fileType, null);
        this.bacMarks = bacMarks ? bacMarks : new FileModel(null, null, apiConstants.FILES.BAC_MARKS.fileType, null);
        this.schoolProof = schoolProof ? schoolProof : new FileModel(null, null, apiConstants.FILES.SCHOOL_PROOF.fileType, null);
        this.requests = requests ? requests : undefined;
        this.created = created ? created : undefined;
        this.siret = siret ? siret : undefined;
        this.transports = transports ? transports : [];
        this.characteristics = characteristics ? characteristics : [];
        this.stripeConnectAccountId = stripeConnectAccountId ? stripeConnectAccountId : undefined;

        /**
         * @returns object to send thougth API.
         */
        this.toFormCreate = function () {
            const hobbiesProceed = this.hobbies?.map(hobby => { return { skill: hobby.value, level: 1 } });
            const subjectsProceed = this.subjects?.map(subject => { return { skill: subject.value, level: 1 } });
            const skills = [...hobbiesProceed, ...subjectsProceed];

            return {
                ...this.address.toTutorForm(),
                gender: this.gender,
                phone: this.phone,
                birthdate: this.birthdate,
                tutorSkills: skills,
                avatar: this.avatar,
                identityProof: this.identityProof,
                bacMarks: this.bacMarks,
                schoolProof: this.schoolProof,
                nationality: this.nationality,
                notes: this.notes,
                user: {
                    lastname: this.lastname,
                    firstname: this.firstname,
                    email: this.email,
                    role: "tutor"
                },
            }
        }

        /**
         * @returns object to send thougth API.
         */
        this.toFormUpdate = function () {
            const hobbiesProceed = this.hobbies?.map(hobby => { return { skill: hobby.value, level: 1 } });
            const subjectsProceed = this.subjects?.map(subject => { return { skill: subject.value, level: 1 } });
            const skills = [...hobbiesProceed, ...subjectsProceed];

            return {
                tutorSkills: skills,
                ...this.address.toTutorForm(),
                gender: this.gender,
                phone: this.phone[0] !== "+" ? `+${this.phone}` : this.phone,
                siret: this.siret,
                notes: this.notes,
                meansTransport: this.transports.map(elt => `${apiConstants.API_TRANSPORTS}/${elt.value}`),
                supportedCharacteristics: this.characteristics.map(elt => `${apiConstants.API_CHARACTERISTICS}/${elt.value}`),
                user: {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email
                }
            }
        }

        this.extractFiles = function () {
            return {
                avatar: this.avatar,
                schoolProof: this.schoolProof,
                bac: this.bacMarks,
                id: this.identityProof,
            }
        }
    }
}
