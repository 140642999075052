import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/admin/Others/buttons/buttonLink/buttonLink';

class LessonsTab extends React.Component {
    state = {
        lessonsList: this.props.lessonsList,
    };

    render() {
        const windowWidth = window.innerWidth;

        return (
            <div className="w-100 h-100 m-0 pl-3" style={{overflow: "auto"}}>
                <div
                    className="col-xl-10 col px-0 h-100"
                    style={{ overflow: "hidden auto" }}
                >
                    <div
                        className="row m-0 mb-4 sticky-top police-gilroy-font"
                        style={{
                            fontSize: windowWidth < 576 ? 4 + "vw" : 120 + "%",
                            borderBottom: "1px solid var(--purple-tah-color)",
                            backgroundColor: "#ffffff",
                            height: 35 + "px",
                        }}
                    >
                        <div className="col-2 pl-0 mb-2">DATE</div>

                        <div className="col-2 pl-0 pr-5 mb-2 text-center">DURÉE</div>

                        <div className="col-2 pl-0 pr-5 mb-2 text-center">STATUT</div>

                        {/* <div className="col-2 pl-0 pr-5 mb-2 text-center">NOTE</div> */}
                    </div>

                    <div className="row w-100 m-0 mb-0">
                        {this.state.lessonsList?.length > 0 ? (
                            this.state.lessonsList?.map((obj, key) => (
                                <div
                                    className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                                    key={key}
                                >
                                    <div className="col-2 pl-0">{obj.date}</div>
                                    <div className="col-2 pl-0 pr-5 text-center">
                                        {obj.duration}
                                    </div>

                                    <div className="col-2 pl-0 pr-5 text-center">
                                        {obj.state.toUpperCase()}
                                    </div>

                                    {/* <div className="col-2 pl-0 pr-5 text-center">
                                        {obj.grade + "/10"}
                                    </div> */}

                                    <div
                                        className="col pl-2 mb-2 d-flex flex-column align-items-end"
                                        style={{ color: "#3c8bec" }}
                                    >
                                        <ButtonLink link={obj?.id ? "/admin-home/lesson/" + obj.id : null} >i</ButtonLink>

                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="w-100 text-center">NO DATA</div>
                        )}
                    </div>
                </div>
            </div>

        )
    }

}

LessonsTab.propTypes = {
    lessonsList: PropTypes.array,
};

LessonsTab.defaultProps = {
    lessonsList: []
}

export default LessonsTab;
