export default class FileModel {
    constructor(fileName, downloadUrl, fileType, tutorId) {
        this.fileName = fileName;
        this.downloadUrl = downloadUrl;
        this.fileType = fileType;
        this.tutorId = tutorId;
    }
    toFormData(binaryFile) {
        let bodyFormData = new FormData();
        bodyFormData.append("documentFile", binaryFile);
        return bodyFormData;
    }
}