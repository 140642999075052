import React, { useEffect, useState } from "react";

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import AvatarEditor from 'react-avatar-editor';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import PropTypes from 'prop-types';

import DEFAULT_PROFILE_PICTURE from "../../../../images/default-profil-picture.jpeg";
import { apiService } from "_services/api.service";
import loading from "../process/loading";

function Avatar({ file, width, height, editable = true }) {
    const [profilePicture, setProfilePicture] = useState(file);
    const [importedPicture, setImportedPicture] = useState(undefined);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [fileUploaderRef, _setFileUploaderRef] = useState(React.createRef());
    const [active, setActive] = useState(false);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        setProfilePicture(file);
    }, [file]);

    async function onClickAway() {
        setActive(false);
        setImportedPicture(undefined);
    }

    function onLoadPictureFailure(_event) {
        let defaultProfilePicture = profilePicture;
        defaultProfilePicture.downloadUrl = DEFAULT_PROFILE_PICTURE;
        setProfilePicture(defaultProfilePicture);
    }

    async function savePicture(event) {
        event.preventDefault();
        let newURL;
        const importedPicture = event.target.files[0];

        if (importedPicture) {
            loading(true);
            const response = await apiService.updateTutorFile(profilePicture?.tutorId, profilePicture?.fileType, profilePicture.toFormData(importedPicture));
            newURL = response.data.url;
            setImportedPicture(importedPicture);
            setActive(false);
        }

        // Check if newURL is available (contains an URL).
        if (newURL) {
            let newProfilePicture = profilePicture;
            newProfilePicture.downloadUrl = newURL;
        }
        loading(false);
    }

    const profilePicturePos = { x: 0.5, y: 0.5 };
    return (
        <div>
            <div className="mt-4 text-center" style={{ height: 17.5 + "vh" }}>
                <AvatarEditor
                    image={profilePicture?.downloadUrl}
                    width={width ? width : 0.19 * windowHeight}
                    height={height ? height : 0.185 * windowHeight}
                    border={0}
                    color={[74, 37, 170]}
                    scale={1.2}
                    onLoadFailure={onLoadPictureFailure}
                    position={profilePicturePos}
                    style={{ cursor: "default" }}
                />
            </div>
            <h2 className="oc-title-font mt-3">
                ID : {profilePicture?.tutorId ? profilePicture.tutorId : null}
            </h2>
            {editable ?
                <button
                    className="btn w-100 oc-button-empty-blue p-1"
                    style={{
                        flexShrink: 0,
                        border: "solid " + 2 + "px #3c8bec",
                        fontSize:
                            windowWidth > 1200
                                ? 1 + "vw"
                                : windowWidth > 992
                                    ? 1 + "vw"
                                    : windowWidth >= 768
                                        ? 1.3 + "vw"
                                        : 2.2 + "vw",
                        borderColor: "#3c8bec",
                    }}
                    onClick={() => { setActive(true) }}
                >
                    CHANGER LA PHOTO
                </button>
                : null
            }
            {active ?
                <div className="w-100 h-100 d-flex pb-5" style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    background: "rgba(255, 255, 255, 0.6)",
                    zIndex: 9998,
                }}>
                    <ClickAwayListener onClickAway={onClickAway}>
                        <div
                            className="custom-ui pl-5 pb-5 pt-4 pr-4 m-auto d-flex flex-column"
                            style={{
                                border: "solid 3px var(--purple-tah-color)",
                                backgroundColor: "#ffffff",
                                width: 40 + "vw",
                            }}
                        >
                            <IconButton className="p-0 ml-2 align-self-end" onClick={() => { setActive(false) }}>
                                <CloseIcon />
                            </IconButton>

                            <div className="mx-5">
                                <h1 className="police-gilroy-font mb-5 text-center" style={{ fontSize: 150 + "%" }}>
                                    PHOTO DE PROFIL
                                </h1>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        <AvatarEditor
                                            image={importedPicture ? importedPicture : profilePicture?.downloadUrl}
                                            width={150}
                                            height={150}
                                            scale={1.2}
                                            border={15}
                                            color={[74, 37, 170, 0.5]}
                                            position={{ x: 0.5, y: 0.5 }}
                                            className="mr-4"
                                        />
                                    </div>

                                    <div className="d-flex flex-column ml-5 justify-content-center" onClick={() => { fileUploaderRef?.current?.click(); }} style={{ cursor: "pointer" }}
                                    >
                                        <input
                                            accept="image/*"
                                            ref={fileUploaderRef}
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(event) => {
                                                savePicture(event);
                                                /* setImportedPicture(event.target.files[0]);
                                                setActive(false); */
                                            }}
                                        />

                                        <p className="mb-0 d-flex justify-content-center align-items-center empty-box-font py-2 px-3">
                                            Charger
                                            <IconButton className="p-0 ml-2">
                                                <VerticalAlignBottomIcon
                                                    fontSize="small"
                                                    style={{ color: "var(--purple-tah-color)" }}
                                                />
                                            </IconButton>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
                : null}
        </div >
    )
}


Avatar.propTypes = {
    file: PropTypes.any,
};

export default Avatar;
