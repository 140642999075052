import globalSwal from "components/admin/Others/alert/globalSwal";
import AddressFormGroupInput from "components/admin/Others/forms/components/addressFormGroupInput";
import FileFormGroupInput from "components/admin/Others/forms/components/fileFormFroupInput";
import MultiSelectFormGroupInput from "components/admin/Others/forms/components/multiSelectFormGroupInput";
import PhoneFormGroupInput from "components/admin/Others/forms/components/phoneFormGroupInput";
import SelectFormGroupInput from "components/admin/Others/forms/components/selectFormGroupInput";
import TextAreaFormGroupInput from "components/admin/Others/forms/components/textAreaFormGroupInput";
import TextFormGroupInput from "components/admin/Others/forms/components/textFormGroupInput";
import loading from "components/admin/Others/process/loading";

import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

import { apiConstants, appConstants } from "_constants";
import { checkMissingData } from "_helpers";
import TutorModel from "_models/tutor.model";
import { apiService } from "_services/api.service";


function FormTutor() {
    const [submitted, setSubmitted] = useState(false);
    const [subjects, setSubjects] = useState(undefined);
    const [hobbies, setHobbies] = useState(undefined);
    const [tutor, setTutor] = useState(new TutorModel());

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setSubjects(await apiService.getSkills(apiConstants.API_FILTER_LEVEL));
        setHobbies(await apiService.getSkills(null, apiConstants.API_FILTER_HOBBY));
    }

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_LASTNAME:
                setTutor({ ...tutor, lastname: value })
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                setTutor({ ...tutor, firstname: value })
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setTutor({ ...tutor, gender: value })
                break;
            case appConstants.FORM_LABEL_MAIL:
                setTutor({ ...tutor, email: value })
                break;
            case appConstants.FORM_LABEL_ADDRESS_NUMBER:
                setTutor({ ...tutor, address: { ...tutor.address, number: value } })
                break;
            case appConstants.FORM_LABEL_ADDRESS_STREET:
                setTutor({ ...tutor, address: { ...tutor.address, street: value } })
                break;
            case appConstants.FORM_LABEL_ADDRESS_CITY:
                setTutor({ ...tutor, address: { ...tutor.address, city: value } })
                break;
            case appConstants.FORM_LABEL_ADDRESS_POSTCODE:
                setTutor({ ...tutor, address: { ...tutor.address, zipcode: value } })
                break;
            case appConstants.FORM_LABEL_PHONE:
                setTutor({ ...tutor, phone: value })
                break;
            case appConstants.FORM_LABEL_BIRTHDATE:
                setTutor({ ...tutor, birthdate: value })
                break;
            case appConstants.FORM_LABEL_SUBJECTS_TEACHED:
                setTutor({ ...tutor, subjects: value })
                break;
            case appConstants.FORM_LABEL_PASSIONS:
                setTutor({ ...tutor, hobbies: value })
                break;
            case appConstants.FORM_LABEL_NATIONALITIES:
                setTutor({ ...tutor, nationality: value })
                break;
            case appConstants.FORM_LABEL_FILE_PROFILE_PICTURE:
                setTutor({ ...tutor, avatar: value })
                break;
            case appConstants.FORM_LABEL_FILE_ID:
                setTutor({ ...tutor, identityProof: value })
                break;
            case appConstants.FORM_LABEL_NOTES:
                setTutor({ ...tutor, notes: value })
                break;
            case appConstants.FORM_LABEL_FILE_BAC:
                setTutor({ ...tutor, bacMarks: value })
                break;
            case appConstants.FORM_LABEL_FILE_SUP:
                setTutor({ ...tutor, schoolProof: value })
                break;

            default:
                break;
        }
    }

    async function onSubmit(event) {
        event.preventDefault();
        setSubmitted(true);

        if (checkMissingData(tutor, ["avatar", "subjects", "hobbies", "identityProof", "bacMarks", "schoolProof", "notes", "id", "requests", "created", "siret"])) {
            loading(true);

            await apiService.postTutor(tutor.toFormCreate()).then(success => {
                if (success) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(function () {
                        window.history.back();
                    });
                }
                else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            });
        } else {
            Swal.fire({
                icon: appConstants.SWAL_ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    }

    return (
        <form style={{ overflow: "hidden auto" }} onSubmit={onSubmit}>
            <div className="mt-3 d-flex justify-content-between w-100">
                <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={tutor?.lastname} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={tutor?.firstname} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={tutor?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_MAIL} type="email" value={tutor?.email} onChange={onChange} submitted={submitted} />
            </div>
            <div className="d-flex justify-content-between w-100">
                <AddressFormGroupInput classname="mx-3 mb-2 d-flex w-100" customer={tutor} value={tutor?.address} onChange={onChange} submitted={submitted} formOnly />
            </div>

            <div className="d-flex justify-content-between w-100">
                <PhoneFormGroupInput label={appConstants.FORM_LABEL_PHONE} value={tutor?.phone} onChange={onChange} submitted={submitted} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_BIRTHDATE} type="date" value={tutor?.birthdate} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <MultiSelectFormGroupInput className="col-6" label={appConstants.FORM_LABEL_SUBJECTS_TEACHED} value={tutor?.subjects} options={subjects} onChange={onChange} submitted={submitted} />
                <MultiSelectFormGroupInput className="col-6" label={appConstants.FORM_LABEL_PASSIONS} value={tutor?.hobbies} options={hobbies} onChange={onChange} submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <TextFormGroupInput className="pr-3 w-100 mx-3" label={appConstants.FORM_LABEL_NATIONALITIES} value={tutor?.nationality} onChange={onChange} submitted={submitted} />

                <div className="w-100 d-flex justify-content-between pr-3">
                    <FileFormGroupInput className="w-100 pr-3" label={appConstants.FORM_LABEL_FILE_PROFILE_PICTURE} file={tutor?.avatar} onChange={onChange} uploadOnly />
                    <FileFormGroupInput className="w-100 pl-3" label={appConstants.FORM_LABEL_FILE_ID} file={tutor?.identityProof} onChange={onChange} uploadOnly />
                </div>
            </div>

            <div className="d-flex justify-content-between w-100">
                <TextAreaFormGroupInput label={appConstants.FORM_LABEL_NOTES} value={tutor?.notes} className="pr-3 w-100 mx-3" onChange={onChange} />
                <div className="w-100 d-flex justify-content-between pr-3">
                    <FileFormGroupInput className="w-100 pr-3" label={appConstants.FORM_LABEL_FILE_BAC} file={tutor?.bacMarks} onChange={onChange} uploadOnly />
                    <FileFormGroupInput className="w-100 pl-3" label={appConstants.FORM_LABEL_FILE_SUP} file={tutor?.schoolProof} onChange={onChange} uploadOnly />
                </div>
            </div>

            <div className="d-flex mt-5">
                <Button className="h-100 oc-button-empty-blue ml-3 mb-3" style={{ fontSize: "inherit" }} onClick={onSubmit}>
                    CRÉER
                </Button>
            </div>
        </form >
    )
}

export default FormTutor;