import { userConstants } from "../_constants";

let user = JSON.parse(localStorage.getItem("user"));
//let user_expiration = localStorage.getItem('user-expiration');
//user_expiration.setHours(user_expiration.getHours() + 1);
// if(user_expiration < new Date()){
//     localStorage.removeItem('user');
//     localStorage.removeItem('user-expiration');
//     location.reload(true);
// }

const initialState = user
  ? { loggedIn: true, user }
  : { forgetPassword: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        forgetPassword: true,
      };
    case userConstants.LOGOUT:
      return {
        forgetPassword: state.forgetPassword,
      };
    case userConstants.FORGET_PASSWORD:
      return {
        forgetPassword: true,
      };
    case "UPDATE_USER":
      return {
        user: action.user,
      };
    default:
      return state;
  }
}
