import React from 'react'
import PropTypes from 'prop-types'

export class TableOverview extends React.Component {

    render() {
        return(
            <div className={`d-flex flex-column ${this.props.className}`}>
                <div className="card" style={{width: "18rem"}}>
                    <div className="card-body">
                        <h5 className="card-title">{this.props.title?.toUpperCase()}</h5>
                        <p className="card-text"></p>
                        <a href={"/admin-home/outils/"+this.props.title} className="card-link">Voir +</a>
                    </div>
                </div>
            </div>
        )
    }
}

TableOverview.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
}

