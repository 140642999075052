import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Image, Button, Container, Row, Col } from "react-bootstrap";
import TUTEURALHEURE_BLOCMARQUE_BLOCMARQUE_POURFONDVIOLET from "../../../images/TUTEURALHEURE_BLOCMARQUE_BLOCMARQUE_POURFONDVIOLET.png";
import logout from "../../../images/logout.png";
import logout2x from "../../../images/logout@2x.png";
import logout3x from "../../../images/logout@3x.png";
import { userActions } from "../../../_actions";
import { store } from "../../../_helpers";

import LOGO from "../../../images/logo-white.png";

import { confirmAlert } from "react-confirm-alert"; // Import

import "./Navbar.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class NavbarEspace extends Component {
  confirmLogOut = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-5"
            style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
          >
            <h1
              className="police-gilroy-font mb-4 text-center"
              style={{ fontSize: 110 + "%" }}
            >
              Êtes-vous sûr(e) de vouloir vous déconnecter ?
            </h1>

            <div className="d-flex justify-content-between">
              <button
                className="empty-box-font p-3 mr-3 custom-btn-green"
                style={{
                  color: "#1fb23b",
                  borderColor: "#1fb23b",
                  fontSize: 90 + "%",
                }}
                onClick={() => {
                  onClose();
                  this.logOut();
                }}
              >
                CONFIRMER
              </button>
              <button
                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                style={{
                  color: "#ea1616",
                  borderColor: "#ea1616",
                  fontSize: 90 + "%",
                }}
                onClick={onClose}
              >
                ANNULER
              </button>
            </div>
          </div>
        );
      },
    });
  };

  logOut = () => {
    store.dispatch(userActions.logout());
    window.location.reload();
  };

  render() {
    return (
      <Navbar className="navbar-header navbar-expand-lg sticky-top justify-content-between">
        <Container fluid className="h-100">
          <Row className="w-100 m-0 h-100">
            <Col className="col-4 h-100">
              <Link to="/admin-home" className="d-flex h-100 py-4">
                <Image
                  src={LOGO}
                  className="ml-4 d-block"
                  alt="Eddmon"
                />
              </Link>
             
            </Col>
            <Col className="col-8 px-0">
              <div className="d-flex justify-content-between h-100 mr-5">
                <div className="align-self-center">
                  <Link
                    to={"/admin-home/tableau-de-bord"}
                    className="navbar-link mr-5"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={
                        "navbar-link" +
                        (this.props.onglet === "tableaudebord"
                          ? " navbar-link-active"
                          : "")
                      }
                    >
                      Tableau de bord
                    </span>
                  </Link>
                  <Link
                    to={"/admin-home/profils"}
                    className="navbar-link mr-5"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={
                        "navbar-link" +
                        (this.props.onglet === "profils"
                          ? " navbar-link-active"
                          : "")
                      }
                    >
                      Profils
                    </span>
                  </Link>
                  <Link
                    to={"/admin-home/gestion"}
                    className="navbar-link mr-5"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={
                        "navbar-link" +
                        (this.props.onglet === "gestion"
                          ? " navbar-link-active"
                          : "")
                      }
                    >
                      Gestion
                    </span>
                  </Link>
                  <Link
                    to={"/admin-home/outils"}
                    className="navbar-link"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={
                        "navbar-link" +
                        (this.props.onglet === "outils"
                          ? " navbar-link-active"
                          : "")
                      }
                    >
                      Outils
                    </span>
                  </Link>
                </div>

                <div className="align-self-center">
                  <Button variant="" className="logout px-0 py-0"></Button>
                </div>

                <div className="align-self-center">
                  <Button
                    variant=""
                    className="logout px-0 py-0"
                    onClick={this.confirmLogOut}
                  >
                    <Image
                      src={logout}
                      srcSet={
                        logout + " 1x," + logout2x + " 2x," + logout3x + " 3x"
                      }
                      className="logout-img"
                      alt="Eddmon"
                    ></Image>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
}

export default NavbarEspace;
