import React from 'react';
import NavbarEspace from './Navbar/Navbar';
import ProfileTutor from './Body/Profiles/Tutor/profileTutor';

class ProfilsTutorsPage extends React.Component {

  render() {

    return (
      <div className="window-size d-flex flex-column">
        <div className="d-block">
          <NavbarEspace onglet="profils"/>
        </div>
        <div className="container-fluid h-100 d-flex px-0" style={{maxHeight: 'calc(100vh - 80px)'}}>
          <div className="row w-100 mx-0">
            <div className="col px-0 pr-xl-5 mh-100">
              <ProfileTutor/>
            </div>
          </div>
        </div>
        </div>

    );
  }
}

export default ProfilsTutorsPage;
