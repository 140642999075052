import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MenuWithButtons from "../../../Others/menus/menuWithButtons/menuWithButtons";
import ButtonMenu from "../../../Others/buttons/buttonMenu/buttonMenu";
import SideMenu from "../components/sideMenu";
import MainInfoTab from "./tabs/mainInfoTab";
import DocumentsTab from "./tabs/documentsTab";
import RequestsTab from "./tabs/requestsTab";
import Swal from "sweetalert2";
import FeedbackTab from "./tabs/feedbackTab";

import { apiService } from "../../../../../_services/api.service";
import { checkMissingData } from "../../../../../_helpers";
import loading from "components/admin/Others/process/loading";
import globalSwal from "components/admin/Others/alert/globalSwal";

import { appConstants } from "../../../../../_constants/app.constants";
import { apiConstants } from "_constants";
import {loadStripe} from "@stripe/stripe-js";
import {confirmAlert} from "react-confirm-alert";
import {useHistory} from "react-router-dom";

function ProfileTutor(_props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tutor, setTutor] = useState(undefined);
    const [options, setOptions] = useState({ subjects: undefined, hobbies: undefined });
    const [phoneFormat, setPhoneFormat] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [feedbacks, setFeedbacks] = useState(undefined);
    const [averageGrade, setAverageGrade] = useState(null);
    const [load, setLoad] = useState(true);
    const [stripe, setStripe] = useState(undefined);
    let history = useHistory();

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const tutorId = window.location.pathname.match(/\d+/)[0];
        let tutorResponse = await apiService.getTutorById(tutorId);
        setTutor(tutorResponse);

        if (tutorResponse === apiConstants.ERROR || tutorResponse === null) {
            history.push('/admin-home/profils');
            return;
        }
        const subjects = await apiService.getSkills(1, null);
        const hobbies = await apiService.getSkills(null, 1);
        const characteristics = await apiService.getCharacteristics();
        const transports = await apiService.getTransports();
        const feedbacks = await apiService.getTutorsFeedBacks(tutorId);
        const averageGrade = await apiService.getTutorsAverageScore(tutorId);
        setFeedbacks(feedbacks);
        setAverageGrade(averageGrade);
        setOptions({ subjects: subjects, hobbies: hobbies, transports: transports, characteristics: characteristics });
        setLoad(false);
        if (!stripe) {
            const newStripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            setStripe(newStripe);
        }
    }

    function onChange(value, label, valid) {
        switch (label) {
            case appConstants.FORM_LABEL_MAIL:
                setTutor({ ...tutor, email: value });
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                setTutor({ ...tutor, firstname: value });
                break;
            case appConstants.FORM_LABEL_LASTNAME:
                setTutor({ ...tutor, lastname: value });
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setTutor({ ...tutor, gender: value });
                break;
            case appConstants.FORM_LABEL_PHONE:
                setTutor({ ...tutor, phone: value });
                setPhoneFormat(valid);
                break;
            case appConstants.FORM_LABEL_ADDRESS_NUMBER:
                setTutor({ ...tutor, address: { ...tutor.address, number: value } });
                break;
            case appConstants.FORM_LABEL_ADDRESS_STREET:
                setTutor({ ...tutor, address: { ...tutor.address, street: value } });
                break;
            case appConstants.FORM_LABEL_ADDRESS_CITY:
                setTutor({ ...tutor, address: { ...tutor.address, city: value } });
                break;
            case appConstants.FORM_LABEL_ADDRESS_POSTCODE:
                setTutor({ ...tutor, address: { ...tutor.address, zipcode: value } });
                break;
            case appConstants.FORM_LABEL_SIRET:
                setTutor({ ...tutor, siret: value });
                break;
            case appConstants.FORM_LABEL_FILE_BAC:
                setTutor({ ...tutor, bacMarks: value });
                break;
            case appConstants.FORM_LABEL_FILE_ID:
                setTutor({ ...tutor, identityProof: value });
                break;
            case appConstants.FORM_LABEL_FILE_SCHOOL_PROOF:
                setTutor({ ...tutor, schoolProof: value });
                break;
            case appConstants.FORM_LABEL_SUBJECTS:
                setTutor({ ...tutor, subjects: value });
                break;
            case appConstants.FORM_LABEL_PASSIONS:
                setTutor({ ...tutor, hobbies: value });
                break;
            case appConstants.FORM_LABEL_MOBILITY:
                setTutor({ ...tutor, transports: value });
                break;
            case appConstants.FORM_LABEL_CHARACTERISTICS:
                setTutor({ ...tutor, characteristics: value });
                break;
            case appConstants.FORM_LABEL_COMMENT:
                setTutor({ ...tutor, notes: value });
                break;
            default:
                break;
        }
    }

    async function onSave(event) {
        event.preventDefault();
        setSubmitted(true);

        // Check if all data are provided.
        if (checkMissingData(tutor, ["files", "siret", "hobbies", "requests", "birthdate", "nationality", "notes", "extra", "transports", "characteristics", "stripeConnectAccountId"]) && (typeof phoneFormat !== "undefined" ? phoneFormat : true)) {
            loading(true);
            let responseToken = null;
            if (tutor?.stripeConnectAccountId) {
                responseToken = await apiService.refreshStripeConnectAccount(tutor, stripe)

                if (responseToken?.error) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: responseToken?.message,
                    });

                    return;
                }
            }

            await apiService.patchTutorById(tutor?.id, tutor.toFormUpdate(), responseToken?.token)
                .then(result => {
                    if (result) {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_SUCCESS,
                            title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                });
        } else {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                text: "",
                icon: appConstants.SWAL_ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    }

    async function handleRemoveTutor() {
        loading(true);
        await apiService.deleteTutorById(tutor.id).then(response => {
            if (response === apiConstants.SUCCESS) {
                globalSwal({
                    icon: appConstants.SWAL_ICON_SUCCESS,
                    title: appConstants.DEFAULT_SWAL_TITLE_DELETE_SUCCESS,
                    text: appConstants.SWAL_MESSAGE_REDIRECTION_PROFILS,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                }).then(function () {
                    history.push('/admin-home/profils');
                });
            } else if (response?.status === 409) { // Display message error when delete is not available because of business conditions
                globalSwal({
                    icon: appConstants.SWAL_ICON_WARNING,
                    title: appConstants.DEFAULT_SWAL_TITLE_REMOVE_TUTOR_WARNING,
                    text: response?.message,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            } else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.SWAL_MESSAGE_DELETE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        })
    }

    function onConfirmDelete() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div
                        className="custom-ui p-5"
                        style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
                    >
                        <h1
                            className="police-gilroy-font mb-4 text-center"
                            style={{ fontSize: 110 + "%" }}
                        >
                            Voulez-vous supprimer ce tuteur ?<br/>
                            Attention cette action va terminer l'ensemble des demandes associées à ce tuteur.
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                    cursor: "pointer",
                                }}
                                onClick={async () => {
                                    onClose();
                                    await handleRemoveTutor();
                                }}
                            >
                                CONFIRMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    return (
        <div className="py-5 pl-4 h-100 d-flex">
            <SideMenu file={tutor?.avatar} onSave={onSave} loading={load} onConfirmDelete={onConfirmDelete} />

            <MenuWithButtons className="w-100 h-100 px-3 d-flex flex-column" style={{ overflow: "hidden" }}>
                <ButtonMenu className="ml-3 mb-5" onActive={<MainInfoTab tutor={tutor} onChange={onChange} submitted={submitted} options={options} />}  >INFOS PRINCIPALES</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<DocumentsTab files={tutor === apiConstants.ERROR ? apiConstants.ERROR : tutor?.extractFiles()} onChange={onChange} submitted={submitted} />} >DOCUMENTS</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<RequestsTab requests={tutor?.requests} />} >DEMANDES</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<FeedbackTab feedbacks={feedbacks} averageGrade={averageGrade} />} >AVIS</ButtonMenu>
            </MenuWithButtons>

            <div className={"col px-0 h-100 pt-1" + (appConstants.SCREEN_WIDTH_EXTRA_LARGE > windowWidth ? " pr-3" : "")}>
                <Button variant="" style={{ fontSize: "inherit" }} className={"oc-button-empty2"} onClick={() => { window.history.back(); }}>
                    RETOUR
                </Button>
            </div>

        </div>
    );
}

export default ProfileTutor;
