import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import FileModel from "../../../../../_models/file.model";

import { appConstants } from "../../../../../_constants";

function SideMenu({ id }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    return (
        <div
            className="pl-0 pr-5 d-flex flex-column"
            style={{
                fontSize: 75 + "%",
                flex: "0 0 12.5%",
                maxWidth: "12.5%" /* = col 1.5 */,
            }}
        >
            <p className="police-gilroy-font mb-1" style={{ fontSize: "140%" }}>{`ID : ${id ? id : appConstants.DEFAULT_NO_ID}`}</p>
            <div className="py-1 mr-1 text-center filled-box-font police-gilroy-font" style={{ color: "#ffffff" }} >
                LEÇON
            </div>
        </div>

    )
}

SideMenu.propTypes = {
    file: PropTypes.instanceOf(FileModel),
}

export default SideMenu;