import LoaderSpinner from 'components/admin/Others/loader/loader';
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userConstants } from '../_constants';
import { store } from '../_helpers';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [data, setData] = useState({
        allow: false,
        redirect: "/login",
    });

    // Used to allow redirection.
    useEffect(() => {
        if (rest.path === data?.redirect) {
            setData({ allow: true, redirect: false })
        }
    }, [rest.path, data.redirect]);
    useEffect(() => {
        const worker = async () => {
            let storeData = store.getState();
            let user = storeData.authentication.user;

            if (user != null) { //if the user is save in local storage
                if (user.role === rest.role) { //if the user role is fine
                    setData({ allow: true });
                }
                else if (user.role !== userConstants.ROLE_ADMIN) {
                    setData({ allow: true, redirect: "/admin-home/tableau-de-bord" });
                }
            } else {
                setData({
                    allow: true,
                    redirect: "/login",
                })
            }
        };
        worker();
    }, [rest.path, rest.role]);

    if (!data.allow) {
        return (<LoaderSpinner />)
    }

    return (
        <Route {...rest} render={props => (
            data?.redirect
                ? <Redirect to={{ pathname: data?.redirect, state: { from: props.location } }} />
                : <Component {...props} />
        )} />
    )
}