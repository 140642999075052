import { bddConstants } from "_constants";
import { TableOverview } from "../components/tableOverview";

function SettingsTab() {
    return (
        <div className="row w-100 h-100 m-0" style={{ overflow: "hidden auto" }}>
            {bddConstants.TAB_LIST.map(
                (tabTitle, index) => {
                    return (
                        <TableOverview title={tabTitle} className="ml-0 my-1 mr-3" key={index}/>
                    )
                }
            )}
        </div>
    )
}

export default SettingsTab;