import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PropTypes from 'prop-types';

import './subNavDropdown.css';
import { colorsConstants } from "_constants";

function SubNavDropdown(props) {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, _setWindowWidth] = useState(window.innerWidth);
    const [active, setActive] = useState(false);

    const eventListener = () => {
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        setActive(props.active);
    }, [props.active]);

    return (
        <div className={"w-100 mx-0 " + (windowHeight < 700 ? " mb-0" : props.className ? props.className : active ? " mb-sm-4 " : " mb-sm-5 ") + (active ? " d-flex flex-column h-100" : "")} style={{ overflow: "hidden", flexShrink: active ? null : 0 }}>
            <div className="px-0 sticky-top dropdown" style={{ backgroundColor: "white" }}>
                <div className="oc-list-deco d-flex justify-content-between align-items-center pr-2" onClick={() => { props.onClick(); setActive(!active) }} style={{ borderColor: props.color ? props.color : null }} >
                    <span className="oc-title-font" style={{ fontSize: windowWidth > 992 ? 23 + 'px' : windowWidth > 576 ? 20 + 'px' : 4.5 + 'vw', color: props.color ? props.color : null }}>{props.title}</span>
                    <Button variant="" className="oc-button-list p-0">
                        <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} color={props.color ? props.color : colorsConstants.PURPLE} className="oc-icon" size="1x" />
                    </Button>
                </div>
            </div>

            {active ? props.children : null}
        </div>
    )
}


SubNavDropdown.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

SubNavDropdown.defaultProps = {
    onClick: () => { }
}

export default SubNavDropdown;