import { userConstants } from "../_constants";
import jwt_decode from "jwt-decode";
import { appConstants } from "../_constants/app.constants";
import moment from 'moment';

export function phoneNumberDispatch(phoneNum) {
    if (phoneNum === undefined || phoneNum === null) {
        return null
    }
    let finalPhoneNum = "", i;
    if (phoneNum[0] === "+") { // For phone number registered in format +33 6 XX XX XX XX
        finalPhoneNum = "+" + phoneNum[1] + phoneNum[2] + " " + phoneNum[3]; // New format : +33 6 XX XX XX XX
        i = 4;
    } else if (phoneNum[0] === "0") { // For phone number registered in format 06 XX XX XX XX
        finalPhoneNum = "+33 ";
        i = 1;
    } else i = 0;

    for (i; i < phoneNum.length; i++) {
        if (i % 2 === 0 && i !== 0) {
            finalPhoneNum += " ";
        }
        finalPhoneNum += phoneNum[i];
    }

    if (finalPhoneNum[0] !== "+") {
        finalPhoneNum = `+${finalPhoneNum}`;
    }
    return finalPhoneNum;
}

export function phoneNumberConvert(phoneNum) {
    if (!phoneNum) {
        return "";
    }
    if (phoneNum[0] === "+") {
        return phoneNum.replace("+", "");
    } else if (phoneNum[0] === "0") { // For phone number registered in format 0X XX XX XX XX
        return ("33" + phoneNum.substr(1))
    }
    else return phoneNum;
}

/**
 * Takes an string address and returns each element of the address in an array as follow: [number, street, city, zipcode]
 * @param {object} data = {address: "", city: "", zipcode= ""}
 * @returns 
 */
export function extractAddress(data) {
    //Extract individual data of an address : output = [fullNumber, street address]
    let address = data.address, regexNumber = /\b(0*(?:[1-9][0-9]{0,3}|1000))\b/;
    if (!address) {
        return ["", ""]
    }
    let number = address.match(regexNumber) ? address.match(regexNumber)[0] : "";
    let matching = address.match(/\b[Bb][Ii][Ss]\b|[Tt][Ee][Rr]\b|[Qq][Uu][Aa][Tt][Ee][Rr]\b/); // Search for bis/ter/quater
    let infoNumber = matching ? matching[0] : "";
    let regexCity = new RegExp(data.city, "gi");
    let regexPostcode = new RegExp(data.zipcode, "gi");
    let fullNumber = number + " " + infoNumber;

    address = address.replace(regexCity, "");
    address = address.replace(regexPostcode, "");
    if (number) {
        address = address.replace(fullNumber, "");
    } else {
        fullNumber = "";
    }
    return [fullNumber.trim(), address.trim()]
}

/**
 * Convert a object address ({number: string, street: string, city: string, zipcode: string}) into a string.
 * @param {object} address 
 * @returns address stringify.
 */
export function flatAddress(address, format = "") {
    let blackList = ["id"];
    let restrictedList = [];
    if (!address) {
        return;
    }
    switch (format) {
        case "NS":
            restrictedList.push("number", "street");
            break;

        default:
            break;
    }
    let result = "";
    for (const [key, value] of Object.entries(address)) {
        if (value && key in blackList === false && !!restrictedList.length ? key in restrictedList : true && typeof value !== "function") {
            result = result.concat(" ", value);
        }
    }
    return result;
}

/**
 * Convert date from yyyy/mm/dd to dd/mm/yyyy
 * @param {date} date Date to convert (yyyy/mm/ddThh:mm:ss || yyyy-mm-ddThh:mm:ss)
 * @returns converted date if succeed, otherwise returns null.
 */
export function dateDispatch(date) {
    if (!date) {
        return null;
    }

    let regex = /^[1-9][0-9]\d{2}(-)\d{2}(-)\d{2}/i;
    let finalDate = "", year = "", month = "", day = "";
    let match = date.match(regex);

    if (match) {
        for (var i = 0; i < match[0].length; i++) {
            if (i < 4) {
                year += match[0][i];
            }
            else if (i < 7 && i > 4) {
                month += match[0][i];
            }
            else if (i > 7) {
                day += match[0][i];
            }
        }

        finalDate = day + "/" + month + "/" + year

    }
    else {
        finalDate = null;
    }
    return finalDate;
}

/**
 * Check if datee provided match yyyy-mm-ddThh:mm:ss.
 * @param {string} date 
 * @returns converted date if succeed, otherwise returns null.
 */
export function dateCheck(date) {
    if (!moment(date, moment.ISO_8601, true).isValid) {
        return null;
    }

    return date.substring(0, 10);
}

/**
 * Convert date from dd/mm/yyyy to yyyy-MM-dd format.
 * @param {String} date Date on dd/mm/yyyy format
 */
export function dateDispatchInput(date) {
    if (!date) return null;

    let regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/i;
    let match = date.match(regex);
    if (match) {
        let day = date.split("/")[0];
        let month = date.split("/")[1];
        let year = date.split("/")[2];
        return `${year}-${month}-${day}`;
    } else return date;
}

/**
 * Remove undefined keys from the form provided.
 * @param {object} form 
 * @returns form without undefined key
 */
export function deleteUndefinedKeys(form) {
    if (typeof form === "object") {
        for (const key in form) {
            if (Object.hasOwnProperty.call(form, key)) {
                const element = form[key];
                if (typeof element === "undefined") {
                    delete form[key];
                } else {
                    try {
                        if (element?.includes("undefined")) {
                            delete form[key];
                        }
                    } catch (_error) {
                        continue;
                    }
                }
            }
        }
    }
    return form;
}

/**
 * Check if the object passed in parameter has as least one key which its value is null
 * @param  {Object} object Object to check
 * @param  {Array[String]} fieldToIgnore Array of string with key to ignore
 * @return {[type]} Returns 1 if no item is missing otherwise returns 0
 */
export function checkMissingData(object, fieldToIgnore) {
    // Check if all data wanted are registered
    for (const key in object) {
        if ((fieldToIgnore && fieldToIgnore.includes(key)) || typeof object[key] === "function") {// Check if current field needs to be ignored or not
            continue;
        }
        if (typeof object[key] === "undefined" || object[key]?.length === 0) { // Check if object is empty
            console.log("Missing data:", key, "in object :", object);
            return 0; // Stop loop if data is missing
        }
        if (typeof object[key] === 'object') {
            if (!checkMissingData(object[key], fieldToIgnore)) {
                return 0;
            }
        }
    }
    return 1;
}

export function dispoStringArrayToArray(arrayInput, daysList, hoursList) {
    let arrayOutput = {};

    for (var i = 0; i < arrayInput.length; i++) {
        // Get day
        const indexDay = daysList.indexOf(arrayInput[i].day) + 1;
        if (indexDay > 0) {
            // Create new empty object for availibilities : ["i+1": {}]
            arrayOutput[indexDay.toString()] = {};
            for (var j = 0; j < arrayInput[i].hourRange.length; j++) {
                const indexHour = (hoursList.indexOf(arrayInput[i].hourRange[j]) + 1).toString();
                arrayOutput[indexDay][indexHour] = "on";
            }
        }
    }

    return arrayOutput
}

/**
 * Convert array of availabilities from Webapp into an object of availabilities readable for backend.
 * @param {array} availabilities - [{day: number, hourRange: number}]
 * @returns object - {1: "on"}
 */
export function availabilitiesFormatter(availabilities) {
    let arrayOutput = {};

    for (let i = 0; i < availabilities?.length; i++) {
        arrayOutput[availabilities[i]?.day] = {};
        arrayOutput[availabilities[i]?.day][availabilities[i]?.hourRange] = "on";
    }

    return arrayOutput;
}

/**
 * Convert backend availabilities format into front availabilities format.
 * @param {object} hourRangeObject - {1: "on"}
 * @returns array of availabilities
 */
export function hourRangeFormatter(hourRangeObject) {
    if (!hourRangeObject) {
        return []
    }
    let hourRangeOutput = [];
    for (const hourNum in hourRangeObject) {
        hourRangeOutput.push(appConstants.LESSONS_HOURS_RANGE_OPTIONS[hourNum - 1].label);
    }
    return hourRangeOutput;

}

export function typeOfData(data) {
    //console.log("DATA INPUT:", data)
    let type = typeof data
    const regex = /\d+-{1}\d+-{1}\d+T{1}\d+:{1}\d+:{1}\d+.\d+:{1}\d+/ // XXXX-XX-XXTXX:XX:XX+XX:XX -> date format
    if (type === "string") { // Check if string
        if (!isNaN(data) && data !== "") { // Check if it is a integer / float
            if (data.toString().indexOf(".") !== -1) { // Check if it is float
                return "float"
            } else return "integer"
        } else if (data.match(regex)) {
            return "date"
        } else return type
    } else return type
}


/*** Extract data hold by the JWT token. Return an object with all decoded data.
 * @argument JWTToken Token to extract data.
 * @returns Decoded data if token exist, otherwise returns undefined.
 */
export function extractJWTToken(tokens) {
    let user = tokens ? jwt_decode(tokens.token) : undefined;
    const role = user?.roles?.includes(userConstants.ROLE_TUTOR) ? userConstants.ROLE_TUTOR : user?.roles?.includes(userConstants.ROLE_CUSTOMER) ? userConstants.ROLE_CUSTOMER : user?.roles?.includes(userConstants.ROLE_ADMIN) ? userConstants.ROLE_ADMIN : undefined;
    if (user) {
        // Delete "roles" and "exp" and replace it by "role" and "date_of_token".
        delete Object.assign(user, { ["role"]: role })["roles"];
        delete Object.assign(user, { ["date_of_token"]: user.exp })["exp"];
    }
    return user;
}

/**
 * Calculate gap (in days) between course start date and current date.
 * @param {object} request 
 * @returns an array : [gap, color].
 */
export function dateGapColors(request) {
    if (!request) {
        return;
    }
    let todayDate = new Date();
    let courseDate = new Date(request.date.courseStart); //Date format yyyy/mm/dd

    const diffTime = courseDate - todayDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (request.state === "enabled") {
        if (diffDays >= 7) {
            return [diffDays, "rgb(31, 178, 59)"]; // GREEN
        } else if (diffDays < 7 && diffDays >= 4) {
            return [diffDays, "rgb(229, 174, 70)"]; // ORANGE
        } else if (diffDays < 4) {
            return [diffDays, "rgb(222, 44, 44)"]; // RED
        }
    } else {
        return [diffDays, "rgb(40, 40, 40)"]; // GRAY
    }
};

/**
 * Calculate gap (in days) between course start date and current date.
 * @param {object} student 
 * @param {string} type 
 * @returns an string corresponding to the appropriate color.
 */
export function dateGapCourse(student, type) {
    if (!student.startCourseDate) {
        return null
    }
    let todayDate = new Date();
    let courseDate = new Date(
        student.startCourseDate.split("/")[2] +
        "-" +
        student.startCourseDate.split("/")[1] +
        "-" +
        student.startCourseDate.split("/")[0]
    ); //Date format yyyy/mm/dd

    const diffTime = courseDate - todayDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (student.state === "En recherche") {
        if (diffDays >= 7) {
            if (type === "border") {
                return "#39aa25";
            }
            return "rgba(31, 178, 59, 0.7)";
        } else if (diffDays < 7 && diffDays >= 4) {
            if (type === "border") {
                return "rgba(229, 174, 70, 0.45)";
            }
            return "rgba(229, 174, 70, 0.7)";
        } else if (diffDays < 4) {
            if (type === "border") {
                return "#ea0404";
            }
            return "rgba(222, 44, 44, 0.7)";
        }
    } else {
        if (type === "border") {
            return "#282828";
        }
        return "rgba(40, 40, 40, 0.7)";
    }
};

/**
 * Convert a date from XXXX-XX-XXTXX:XX:XX+XX:XX format and returns stringify date (YYYY-MM-DD).
 * @param {string} date 
 * @returns date to YYYY-MM-DD format.
 */
export function dateConvertor(date) {
    if (!date) {
        return null;
    }
    if (moment(date).isValid()) {
        return date.substring(0, 10);
    }
}

/**
 * Convert numerical hour into hours | minutes time.
 * @param {number} time
 * @example 1.25 will be proceed to get 1h15
 */
export function hoursConvertor(time) {
    if (!time) {
        return appConstants.DEFAULT_NO_TIME;
    }

    let decimalTime = parseFloat(time);

    if (decimalTime) {
        const hours = Math.trunc(decimalTime);
        let minutes = Math.floor((decimalTime - hours) * 60);
        if (minutes === 0) {
            minutes = "00";
        } else if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        return `${hours}h${minutes}`;
    } else {
        return appConstants.DEFAULT_NO_TIME;
    }
}

/**
 * Generate duration list for selector (format {value: X, label: ""}).
 * @param {number} start time for first option (in minutes).
 * @param {number} end time for last option (in minutes).
 * @param {number} interval time interval between each option (in minutes).
 * @returns a list of duration's options.
 */
export function durationListGenerator(start, end, interval) {
    let list = [];

    for (let counter = start; counter <= end; counter += interval) {
        const label = `${hoursConvertor(counter / 60)}`;
        list.push({ value: counter, label: label });
    }

    return list;
}

export function checkZero(data) {
    if (data?.length == 1) {
        data = "0" + data;
    }
    return data;
}

export function requestStateConvertor(request) {
    if (request?.state === "enabled" && !!request?.tutor?.id) {
        return "available";
    } else return request?.state;
}