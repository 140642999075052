import LoaderSpinner from "components/admin/Others/loader/loader";
import { useEffect, useState } from "react";
import { Crosshair, FlexibleXYPlot, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, YAxis } from "react-vis";
import { appConstants, colorsConstants } from "_constants";
import { apiService } from "_services/api.service";

import 'react-vis/dist/style.css';

function StatisticsTab({ hideLessons = false }) {
    const [loading, setLoading] = useState(true);
    const [lessonsAndIncomesXY, setLessonsAndIncomesXY] = useState({ lessons: undefined, incomes: undefined });
    const [currentTargetXY, setCurrentTargetXY] = useState("null");

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const stats = await apiService.getStatistics();
        setLessonsAndIncomesXY(stats);
        setLoading(false);
    }

    const tickFormat = (tick) => {
        const maxIncomesValue = Math.max(...lessonsAndIncomesXY?.incomes?.map(item => item.y)); // Get max value from sales data
        const maxLessonsValue = Math.max(...lessonsAndIncomesXY?.lessons?.map(item => item.y)); // Get max value from prices data
        const factor = maxIncomesValue / maxLessonsValue; // Calculate factor to convert sales tick to price tick
        return Math.round(tick / factor); // Return result as a integer
    }

    if (!loading && !lessonsAndIncomesXY.lessons) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }

    return (
        <div className="w-100 h-100 px-0" style={{ overflow: "hidden auto" }}>
            {loading ?
                <LoaderSpinner />
                :
                <FlexibleXYPlot margin={{ left: 50, right: 100, bottom: 50 }} xType="ordinal">
                   
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis tickLabelAngle={-45} />

                    {hideLessons ? null :
                        <YAxis orientation="left" title="Nbr. leçons" tickTotal={10} tickFormat={tickFormat} style={{ title: { fontFamily: 'Rota', color: 'var(--purple-tah-color)' } }} />
                    }
                    {hideLessons ? null :
                        <VerticalBarSeries data={lessonsAndIncomesXY?.lessons} yDomain={[0, Math.max(...lessonsAndIncomesXY?.lessons.map(item => item.y))]} color="var(--purple-tah-color)" onNearestX={setCurrentTargetXY} />
                    }
                    <YAxis orientation="right" title="Revenus" tickTotal={10} style={{ title: { fontFamily: 'Rota' } }} />
                    <VerticalBarSeries color={colorsConstants.HEX_LIGHT_BLUE} data={lessonsAndIncomesXY?.incomes} onNearestX={hideLessons ? setCurrentTargetXY : null } />

                    {currentTargetXY === "null" ?
                        null
                        : <Crosshair values={[currentTargetXY]}>
                            <div style={{ color: 'black', fontFamily: 'Rota', width: 100 + 'px' }}>
                                { hideLessons ? null : <p>Nbr. leçons: {Math.trunc([currentTargetXY][0].y)}</p>}
                                <p>Revenus: {parseFloat(lessonsAndIncomesXY?.incomes?.find(elt => elt.x === currentTargetXY.x)?.y)?.toFixed(2)}€</p>
                            </div>
                        </Crosshair>
                    }
                </FlexibleXYPlot>
            }
        </div>
    )
}

export default StatisticsTab;
