import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import PageNavigator from "../Others/pagination/components/pageNavigator";
import LoaderSpinner from "../Others/loader/loader";
import ButtonLink from "../Others/buttons/buttonLink/buttonLink";

import { appConstants } from "../../../_constants";
import { apiService } from "../../../_services/api.service";

registerLocale("fr", fr);

const DATA_UNAVAILABLE_MESSAGE = "No data";

class Profils extends React.Component {

    state = {
        onglet: "tutor",
        tutorPageActive: 1,
        consumerPageActive: 1,
        studentPageActive: 1,
        tutorPageTot: undefined,
        consumerPageTot: undefined,
        studentPageTot: undefined,
        windowWidth: window.innerWidth,
        tutorData: null,
        consumerData: null,
        studentData: null,
        searchInput: "",
        isSubmit: false,
        timer: undefined,
    };

    componentDidMount = () => {
        window.addEventListener("resize", this.eventListener);

        this.setState({
            loading: true
        }, () => {
            Promise.all([this.updateTutors(1), this.updateCustomers(1), this.updateStudents(1)])
                .then(() => {
                    this.setState({
                        loading: false
                    })
                })
        })
    };

    componentWillUnmount() {
        // remove event listener
        window.removeEventListener('resize', this.eventListener);
    }

    eventListener = () => {
        this.setState({ screenWidth: window.innerWidth });
    }

    updateTutors = async (page, search) => {
        const tutor = await apiService.getTutorsByPage(page, search);
        this.setState({
            tutorData: tutor?.data,
            tutorPageTot: tutor?.totalPage,
            tutorPageActive: page,
        });
    }

    updateCustomers = async (page, search) => {
        const consumer = await apiService.getConsumersByPage(page, search);
        this.setState({
            consumerData: consumer?.data,
            consumerPageTot: consumer?.totalPage,
            consumerPageActive: page,
        });
    }

    updateStudents = async (page, search) => {
        const student = await apiService.getStudentsByPage(page, search);
        this.setState({
            studentData: student?.data,
            studentPageTot: student?.totalPage,
            studentPageActive: page,
        });
    }

    handlePage = (activePage) => {
        let apiCallFunction, pageActiveVarName;

        switch (this.state.onglet) {
            case "tutor":
                pageActiveVarName = "tutorPageActive";
                apiCallFunction = apiService.getTutorsByPage;
                break;
            case "consumer":
                pageActiveVarName = "consumerPageActive";
                apiCallFunction = apiService.getConsumersByPage;
                break;
            case "student":
                pageActiveVarName = "studentPageActive";
                apiCallFunction = apiService.getStudentsByPage;
                break;
            default:
                break;
        }

        // Get new data
        this.setState({
            loading: true
        }, () => {
            apiCallFunction(activePage, this.state.searchInput).then(response => {
                let dataVarName, totalPageVarName;
                switch (this.state.onglet) {
                    case "tutor":
                        dataVarName = "tutorData";
                        totalPageVarName = "tutorPageTot";
                        break;
                    case "consumer":
                        dataVarName = "consumerData";
                        totalPageVarName = "consumerPageTot";
                        break;
                    case "student":
                        dataVarName = "studentData";
                        totalPageVarName = "studentPageTot";
                        break;
                    default:
                        break;
                }

                this.setState({
                    [dataVarName]: response?.data,
                    [totalPageVarName]: response?.totalPage,
                    [pageActiveVarName]: activePage,
                    loading: false,
                })
            })
        })

    };

    onChangeSearching = async (event) => {
        event.preventDefault();
        const newSearchInput = event.target.value
        const { timer } = this.state;
        let newTimer;

        this.setState({
            searchInput: newSearchInput,
        });

        switch (this.state.onglet) {
            case "tutor":
                // Wait the word has been written before searching (avoid 1 API call per character).
                clearTimeout(timer);
                newTimer = setTimeout(() => {
                    this.updateTutors(1, newSearchInput);
                }, timer ? appConstants.DEFAULT_TYPING_MAX_TIMEOUT : 0);
                this.setState({ timer: newTimer });
                break;
            case "consumer":
                // Wait the word has been written before searching (avoid 1 API call per character).
                clearTimeout(timer);
                newTimer = setTimeout(() => {
                    this.updateCustomers(1, newSearchInput);
                }, timer ? appConstants.DEFAULT_TYPING_MAX_TIMEOUT : 0);
                this.setState({ timer: newTimer });
                break;
            case "student":
                // Wait the word has been written before searching (avoid 1 API call per character).
                clearTimeout(timer);
                newTimer = setTimeout(() => {
                    this.updateStudents(1, newSearchInput);
                }, timer ? appConstants.DEFAULT_TYPING_MAX_TIMEOUT : 0);
                this.setState({ timer: newTimer });
                break;
            default:
                break;
        }
    }

    arrayData(onglet) {
        const { searchInput } = this.state;
        let dataArray = this.state[onglet + "Data"];
        let page, pageTotal;

        switch (onglet) {
            case "tutor":
                page = this.state.tutorPageActive;
                pageTotal = this.state.tutorPageTot;
                break;
            case "consumer":
                page = this.state.consumerPageActive;
                pageTotal = this.state.consumerPageTot;
                break;
            case "student":
                page = this.state.studentPageActive;
                pageTotal = this.state.studentPageTot;
                break;
            default:
        }

        return (
            <div className="row w-100 h-100 m-0">
                <div className="col-12 px-0 h-100 d-flex flex-column">
                    <div
                        className="form-group d-flex align-self-end align-items-center"
                        style={{ flexShrink: 0 }}
                    >
                        <label className="m-0 mr-3 police-gilroy-font">RECHERCHE</label>
                        <input
                            type="text"
                            className="form-control empty-box-font"
                            style={{ boxShadow: "none" }}
                            value={searchInput}
                            onChange={this.onChangeSearching}
                        />
                    </div>

                    <div
                        className="row m-0 mb-4 police-gilroy-font"
                        style={{
                            fontSize: this.state.screenWidth < 576 ? 4 + "vw" : 120 + "%",
                            borderBottom: "1px solid var(--purple-tah-color)",
                            backgroundColor: "#ffffff",
                            height: 35 + "px",
                        }}
                    >
                        <div className="col-1 pl-0 mb-2">ID</div>
                        <div className="col-1 pl-0 mb-2">
                            {onglet === "student" ? "CLASSE" : "CIVILITÉ"}
                        </div>

                        <div className="col-2 pl-2 mb-2">NOM</div>

                        {onglet !== "student" ? (
                            <div className="col-2 pl-5 mb-2">NUMÉRO</div>
                        ) : null}

                        {onglet !== "student" ? (
                            <div className="col-3 pl-2 mb-2">MAIL</div>
                        ) : null}

                        {onglet !== "student" ? (
                            <div className="col pl-2 mb-2">CONNEXION</div>
                        ) : null}
                    </div>

                    <div
                        className="row w-100 m-0 mb-0 h-100 d-block"
                        style={{ overflow: "hidden auto" }}
                    >
                        {this.state.loading ?
                            <LoaderSpinner /> :
                            dataArray?.length > 0 ? (
                                dataArray.map((elt, key) => (
                                    <div
                                        className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                                        key={key}
                                    >
                                        <div className="col-1 pl-0">{elt.id}</div>
                                        <div className="col-1 pl-0">
                                            {onglet === "student" ? elt.level ? elt.level : DATA_UNAVAILABLE_MESSAGE : elt.civility ? elt.civility : DATA_UNAVAILABLE_MESSAGE}
                                        </div>

                                        <div className={"pl-2 pr-0 " + (onglet === "student" ? "col-3" : "col-2")}>
                                            <div
                                                className="input-font px-2 w-100 d-flex justify-content-center"
                                                style={{ borderWidth: 1 + "px" }}
                                            >
                                                <div
                                                    className="text-nowrap align-self-center police-gilroy-font scrollbar-hidden"
                                                    style={{ overflow: "auto hidden" }}
                                                >
                                                    {elt.firstname && elt.lastname ? elt.firstname + " " + elt.lastname : DATA_UNAVAILABLE_MESSAGE}
                                                </div>
                                            </div>
                                        </div>

                                        {onglet !== "student" ? (
                                            <div className="col-2 pl-5">{elt.phone ? elt.phone : DATA_UNAVAILABLE_MESSAGE}</div>
                                        ) : null}
                                        {onglet !== "student" ? (
                                            <div className="col-3 pl-2">{elt.mail ? elt.mail : DATA_UNAVAILABLE_MESSAGE}</div>
                                        ) : null}
                                        {onglet !== "student" ? (
                                            <div className="col-2 pl-2">{elt.lastConnexion ? elt.lastConnexion : appConstants.DEFAULT_NO_DATE}</div>
                                        ) : null}
                                        <div
                                            className={"col pr-0 mb-2 d-flex flex-column align-items-end"}
                                            style={{ color: "#3c8bec" }}
                                        >
                                            <ButtonLink link={onglet === "tutor" ? "/admin-home/profils/tutors/" + elt.id : onglet === "consumer" ? "/admin-home/profils/customers/" + elt.id : "/admin-home/profils/students/" + elt.id} >i</ButtonLink>

                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="w-100 text-center">DATA NOT FOUND</div>
                            )}
                    </div>
                    <PageNavigator defaultActivePage={page} onChange={activePage => { this.handlePage(activePage) }} pageTotal={pageTotal} />
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="pt-xl-5 pb-5 pl-5 h-100 d-flex flex-column">
                <div className="w-100 d-flex">
                    <div className="col-6 w-100 px-0">
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{
                                fontSize:
                                    this.state.screenWidth < 768 && this.state.screenWidth > 576
                                        ? 2 + "vw"
                                        : this.state.screenWidth < 576
                                            ? 2.5 + "vw"
                                            : "",
                            }}
                        >
                            <Button
                                variant=""
                                className={
                                    "mr-3 w-100 oc-button-empty px-sm-3 px-1 " +
                                    (this.state.onglet === "tutor" ? "oc-button-clicked" : "")
                                }
                                style={{ fontSize: "inherit" }}
                                onClick={() => {
                                    this.updateTutors(1);
                                    this.setState({
                                        onglet: "tutor",
                                        searchInput: "",
                                    });
                                }}
                            >
                                TUTEURS
                            </Button>
                            <Button
                                variant=""
                                className={
                                    "mr-3 w-100 oc-button-empty px-sm-3 px-1 " +
                                    (this.state.onglet === "consumer" ? "oc-button-clicked" : "")
                                }
                                style={{ fontSize: "inherit" }}
                                onClick={() => {
                                    this.updateCustomers(1);
                                    this.setState({
                                        onglet: "consumer",
                                        searchInput: ""
                                    });
                                }}
                            >
                                CLIENTS
                            </Button>
                            <Button
                                variant=""
                                className={
                                    "w-100 oc-button-empty px-sm-3 px-1 " +
                                    (this.state.onglet === "student" ? "oc-button-clicked" : "")
                                }
                                style={{ fontSize: "inherit" }}
                                onClick={() => {
                                    this.updateStudents(1);
                                    this.setState({
                                        onglet: "student",
                                        searchInput: ""
                                    });
                                }}
                            >
                                ÉLÈVES
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="h-100" style={{ overflow: "hidden" }}>
                    {
                        this.arrayData(this.state.onglet)
                    }
                </div>

                <div>
                    <Link to={"/create-consumer-account"}>
                        <Button
                            variant=""
                            className="mr-5 h-100 oc-button-empty-blue px-sm-3 px-1"
                            style={{ fontSize: "inherit" }}
                        >
                            CRÉER UN COMPTE CLIENT
                        </Button>
                    </Link>
                    <Link to={"/create-tutor-account"}>
                        <Button
                            variant=""
                            className="h-100 oc-button-empty-blue px-sm-3 px-1"
                            style={{ fontSize: "inherit" }}
                        >
                            CRÉER UN COMPTE TUTEUR
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Profils;
