import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from '@material-ui/icons/Publish';

import { appConstants } from "../../../../../_constants/app.constants";

import PropTypes from 'prop-types';
import FileModel from "../../../../../_models/file.model";
import { apiService } from "../../../../../_services/api.service";


function FileFormGroupInput({ onChange, label, file, className, downloadOnly = false, uploadOnly = false }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [inputRef, _setInputRef] = useState(React.createRef());

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    function handleOnClick(event) {
        event.preventDefault();
        inputRef?.current?.click();
    }

    async function handleOnChangeFile(event) {
        let newURL;
        const newFile = new FileModel(event?.target?.files[0]?.name, null, file?.fileType, file?.tutorId);

        if (!uploadOnly) {
            const response = await apiService.updateTutorFile(file?.tutorId, file?.fileType, file.toFormData(event.target.files[0]));
            newURL = response.data.url;
            newFile.downloadUrl = newURL;
        }

        onChange(newFile, label);
    }

    function onClickIcon(event) {
        if (uploadOnly) {
            handleOnClick(event);
        }
    }

    return (
        <div className={className ? `form-group ${className}` : "form-group col-7 "}>
            <label className="text-input-font" >
                {label}
            </label>

            <div className="d-flex align-items-center w-100">
                <div
                    className="filled-box-font police-gte-font d-flex flex-row justify-content-between align-items-center px-sm-3 px-1 w-100"
                    style={{
                        color: "#ffffff", fontSize: windowWidth < 576 ? 3 + "vw" : "", height: 35 + "px",
                    }}
                >
                    <span className="scrollbar-hidden" style={{ overflow: "auto hidden" }}>
                        {file?.downloadUrl ? file?.fileName ? file?.fileName : appConstants.DEFAULT_NO_FILE : appConstants.DEFAULT_NO_FILE}
                    </span>
                    <IconButton className="p-0 ml-sm-2 ml-3 h-100" onClick={onClickIcon}>
                        {uploadOnly ?
                            <PublishIcon className="align-middle" fontSize="small" style={{ color: "white" }} />
                            :
                            <a href={file?.downloadUrl ? file?.downloadUrl : undefined} download target="_blank" rel="noreferrer">
                                <GetAppIcon className="align-middle" fontSize="small" style={{ color: "white" }} />
                            </a>}
                    </IconButton>
                </div>

                {!downloadOnly ?
                    <input type="file" ref={inputRef} style={{ display: "none" }} onChange={handleOnChangeFile} />
                    : null
                }

                {!downloadOnly && !uploadOnly ?
                    <button className="btn oc-button-empty-blue px-3 py-2 ml-3" onClick={handleOnClick} style={{ fontSize: windowWidth > appConstants.SCREEN_WIDTH_EXTRA_LARGE ? 1 + "vw" : windowWidth > appConstants.SCREEN_WIDTH_LARGE ? 1 + "vw" : windowWidth >= appConstants.SCREEN_WIDTH_MEDIUM ? 1.3 + "vw" : 2 + "vw", borderColor: "#3c8bec", }} >
                        MODIFIER
                    </button>
                    : null
                }
            </div>

        </div>
    )
}


FileFormGroupInput.propTypes = {
    file: PropTypes.instanceOf(FileModel),
    label: PropTypes.string,
    submitted: PropTypes.bool,
}

FileFormGroupInput.defaultProps = {
    onChange: () => { return }
}

export default FileFormGroupInput;