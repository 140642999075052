import InputTableFormGroup from "components/admin/Others/forms/components/inputTableFormGroup";
import { IconButton } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';

import { typeOfData } from "_helpers";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { apiService } from "_services/api.service";
import { appConstants, apiConstants } from "_constants";
import globalSwal from "components/admin/Others/alert/globalSwal";

function InlineTable({ raw: rawProps, columns, index, onCreateSuccess = () => { return }, onDeleteSuccess = () => { return }, empty = false }) {
    const [raw, setRaw] = useState(rawProps ? rawProps : {});
    const [newRaw, setNewRaw] = useState({});

    useEffect(() => {
        setRaw(rawProps ? rawProps : {});
    }, [rawProps]);

    function handleEditClick(event) {
        event.preventDefault();
        setRaw({ ...raw, edit: !raw?.edit });
    }

    function handleDeleteClick(event) {
        event.preventDefault();
        confirmRemove();
    }

    async function removeRaw() {
        await apiService.delete_(raw["@id"]).then(success => {
            if (success) {
                onDeleteSuccess(raw?.id);
            } else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.SWAL_MESSAGE_DELETE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        })
    }

    function confirmRemove() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-ui p-5" style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}>
                        <h1 className="police-gilroy-font mb-4 text-center" style={{ fontSize: 110 + "%" }}>
                            Veuillez confimer votre choix ?
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                }}
                                onClick={(_event) => {
                                    onClose();
                                    removeRaw();
                                }}
                            >
                                CONFIRMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                )
            },
        })
    }

    function onChange(value, fieldName) {
        // Only callback change on empty new raw.
        if (empty) {
            setNewRaw({ ...newRaw, [fieldName]: value });
        }
    }

    async function onCreate(event) {
        event.preventDefault();
        const url = raw["@id"]?.replace(/\d+/, "")?.slice(0, -1);
        const form = {}
        for (const key in newRaw) {
            let rawKey = key
            if (key.includes(" ID")) {
                rawKey = key.split(" ID")[0]
                form[rawKey] = `${apiConstants[`API_${rawKey.toLocaleUpperCase()}S`]}/${raw[key]}`
            }
            rawKey = rawKey.replace(" ", "")
            form[rawKey] = newRaw[key]
        }
        await apiService.post(url, form).then(response => {
            if (response) {
                onCreateSuccess(response);
            } else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        });

    }

    return (
        <tr key={index}>
            {columns?.map((fieldName, index) => {
                return (
                    <td className="align-middle" key={index} >
                        <InputTableFormGroup value={raw[fieldName]} type={typeOfData(raw[fieldName])} fieldName={fieldName} urlContext={raw["@id"]} edit={fieldName !== "id" && (raw?.edit || empty)} empty={empty} onChange={onChange} />
                    </td>
                )
            })}
            <td className="align-middle">
                {empty ?
                    <button type="button" className="btn btn-primary" onClick={onCreate}>Ajouter</button>
                    :
                    <div className="d-flex justify-content-between">
                        <IconButton className="p-0 align-middle" onClick={event => handleEditClick(event)}>
                            <EditIcon style={{ color: "var(--purple-tah-color)" }} />
                        </IconButton>
                        <IconButton className="p-0 ml-2" onClick={event => handleDeleteClick(event)}>
                            <DeleteIcon style={{ color: "#d0392c" }} />
                        </IconButton>
                    </div>}
            </td>
        </tr>
    )
}

export default InlineTable;
