import { apiConstants } from "_constants";
import AddressModel from "./address.model";
import UserModel from "./user.model";

export default class CustomerModel extends UserModel {
    /**
     * @param {number} userId
     * @param {string} lastname
     * @param {string} firstname
     * @param {string} gender
     * @param {string} email
     * @param {AddressModel} addresses
     * @param {string} phone
     * @param {string} created - Date of format dd/mm/yyyy
     * @param {*} billing
     * @param {*} students
     * @param {*} requests
     */
    constructor(userId, lastname, firstname, gender, email, addresses, phone, created, billing, students, requests) {
        super(userId, lastname, firstname, email);
        this.gender = gender ? gender : undefined;
        this.addresses = addresses ? addresses : [new AddressModel()];
        this.phone = phone ? phone : undefined;
        this.created = created ? created : undefined;
        this.billing = billing ? billing : undefined;
        this.students = students ? students : undefined;
        this.requests = requests ? requests : undefined;

        /**
         * @returns object to send thougth API for creation.
         */
        this.toFormCreate = function () {
            return {
                addresses: [this.addresses[0].toCustomerForm()],
                gender: this.gender,
                phone: this.phone,
                user: {
                    lastname: this.lastname,
                    firstname: this.firstname,
                    email: this.email,
                    role: "customer"
                },
            }
        }

        /**
         * @returns object to send thougth API for update.
         */
        this.toFormUpdate = function () {
            return {
                gender: this.gender,
                phone: this.phone[0] !== "+" ? "+" + this.phone : this.phone,
                billingAddress: `${apiConstants.API_ADDRESSES}/${this.billing?.address?.id}`,
                user: {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email
                }
            }
        }
    }
}
