import OptionModel from "./option.model";
import { apiConstants } from "../_constants";

import { isBoolean } from "lodash";

export default class RequestModel {
    /**
     * @param {OptionModel} consumer 
     * @param {OptionModel} student 
     * @param {OptionModel} tutor 
     * @param {OptionModel} address 
     * @param {Array<OptionModel>} skillsId 
     */
    constructor(consumer, student, tutor, address, skillsId, notifyTutor, online) {
        this.consumer = consumer ? consumer : new OptionModel();
        this.student = student ? student : new OptionModel();
        this.tutor = tutor ? tutor : new OptionModel();
        this.address = address ? address : new OptionModel();
        this.skillsId = skillsId ? skillsId : undefined;
        this.notifyTutor = isBoolean(notifyTutor) ? notifyTutor : false;
        this.online = isBoolean(online) ? online : false;

        /**
         * @returns object to send thougth API.
         */
        this.toForm = function () {
            return {
                customer: `${apiConstants.API_CUSTOMERS}/${this.consumer.value}`,
                student: `${apiConstants.API_STUDENTS}/${this.student.value}`,
                address: `${apiConstants.API_ADDRESSES}/${this?.address.value}`,
                requestSkills: this.skillsId.map(skill => { return {skill: `${apiConstants.API_SKILLS}/${skill.value}`} }),
                notifyTutor: this.notifyTutor,
                online: this.online,
            }
        }
    }
}