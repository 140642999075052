import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import AddressFormGroupInput from "./components/addressFormGroupInput";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert";
import { apiService } from "../../../../_services/api.service";
import globalSwal from "../alert/globalSwal";
import loading from "../process/loading";

import { appConstants } from "../../../../_constants/app.constants";
import AddressModel from "_models/address.model";

function FormAddress({ addresses: addressesProps, customer, onDelete, onSubmitAddress }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [addAddress, setAddAddress] = useState(false);
    const [addresses, setAddresses] = useState(addressesProps);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        setAddresses(addressesProps);
    }, [addresses, addressesProps]);

    function handleOnClick(event) {
        event.preventDefault();
        setAddAddress(!addAddress);
    }

    async function confirmDelete(id) {
        async function deleteAddress(id) {
            loading(true);
            await apiService.deleteAddressById(id).then(response => {
                if (!response.error) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_DELETE_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(_ => {
                        const addressesUpdated = addresses.splice(addresses.indexOf(address => address?.id === id), 1);
                        setAddresses(addressesUpdated);
                        onDelete(addressesUpdated);
                    });
                } else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: response.message || appConstants.SWAL_MESSAGE_DELETE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            })
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div
                        className="custom-ui p-5"
                        style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
                    >
                        <h1
                            className="police-gilroy-font mb-4 text-center"
                            style={{ fontSize: 110 + "%" }}
                        >
                            Êtes-vous sûr(e) de vouloir supprimer cette adresse ?
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                }}
                                onClick={async () => { onClose(); deleteAddress(id); }}
                            >
                                SUPPRIMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                );
            },
        });

    }

    return (
        <div className="mt-3 px-3 text-input-font">
            <div
                className="row police-gilroy-font mb-3 mx-0"
                style={{
                    fontSize: windowWidth < 576 ? 4 + "vw" : 120 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-2 mb-2 pl-0">N°</div>
                <div className="col-4 mb-2">VOIE</div>
                <div className="col-3 mb-2">VILLE</div>
                <div className="col-2 mb-2">C.P</div>
            </div>


            {addresses?.length > 0 ?
                addresses?.map((address, key) => {
                    return (
                        <div
                            className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                            key={key}
                        >
                            <div className="col-2 pl-0">{address?.number}</div>

                            <div className="col-4 pl-2 pr-3">{address?.street}</div>

                            <div className="col-3 pl-3 pr-4">{address?.city}</div>

                            <div className="col-2 pl-2">{address?.zipcode}</div>

                            <div className="col-1 px-0 pr-1 d-flex justify-content-end">
                                <Button
                                    variant=""
                                    className="oc-button2 align-self-center py-2 px-3"
                                    style={{
                                        float: "right",
                                        paddingLeft: "15px",
                                        paddingRight: "15px",
                                    }}
                                    onClick={() => {
                                        confirmDelete(address?.id);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="oc-icon2"
                                        size="1x"
                                    />
                                </Button>
                            </div>
                        </div>)

                })
                : !addAddress ?
                    <div className="w-100 text-center">{appConstants.DEFAULT_NO_ADDRESS}</div>
                    : null
            }

            {addAddress ?
                <AddressFormGroupInput customer={customer} onSubmit={onSubmitAddress} address={new AddressModel()} />
                : null
            }

            <button
                className="btn oc-button-empty-blue px-3 py-2"
                style={{
                    fontSize:
                        windowWidth > appConstants.SCREEN_WIDTH_EXTRA_LARGE
                            ? 1 + "vw"
                            : windowWidth > appConstants.SCREEN_WIDTH_MEDIUM
                                ? 1 + "vw"
                                : windowWidth >= appConstants.SCREEN_WIDTH_SMALL
                                    ? 1.3 + "vw"
                                    : 2.2 + "vw",
                    borderColor: "#3c8bec",
                }}
                onClick={handleOnClick}
            >
                {addAddress ? "-" : "+"}
            </button>
        </div>

    )
}

FormAddress.defaultProps = {
    onDelete: () => { return }
}

export default FormAddress;
