import React, { useEffect, useState } from "react";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../_constants/app.constants";

function MenuWithButtons({ children, className, style }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeTab, setActiveTab] = useState(children?.length > 0 ? children[0]?.props?.children : children.props?.children);
    const [component, setComponent] = useState(children[0]?.props?.onActive);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        // To check if user change tab before complete loading.
        const indexOfActiveTabInChildren = children ? children?.length > 0 ? children.findIndex(tab => tab.props.children === activeTab) : null : undefined;

        if (indexOfActiveTabInChildren === null) {
            setComponent(children?.props?.onActive);
        } else if (indexOfActiveTabInChildren >= 0) {
            setComponent(children[indexOfActiveTabInChildren]?.props?.onActive);
        }
    }, [children, activeTab])


    function onClickOnButton(_event, children, component) {
        setActiveTab(children);
        setComponent(component);
    }

    function renderChildren() {
        return React.Children.map(children, child => {
            return React.cloneElement(child, {
                onClick: onClickOnButton,
                active: child?.props?.children === activeTab,
            })
        })
    }

    return (
        <div className={className + " pt-1"} style={style}>
            <div
                className={
                    "w-100 d-flex" +
                    (windowWidth < appConstants.SCREEN_WIDTH_EXTRA_LARGE ? " justify-content-between" : "")
                }
                style={{
                    fontSize:
                        windowWidth < appConstants.SCREEN_WIDTH_MEDIUM && windowWidth > appConstants.SCREEN_WIDTH_SMALL
                            ? 2 + "vw"
                            : windowWidth < appConstants.SCREEN_WIDTH_SMALL
                                ? 2.5 + "vw"
                                : "",
                }}
            >
                {renderChildren()}
            </div>
            {component}
        </div>

    )
}


MenuWithButtons.ropTypes = {
    children: PropTypes.element.isRequired,
}

MenuWithButtons.defaultProps = {
    children: [],
}

export default MenuWithButtons;