import React from 'react';
import PropTypes from 'prop-types';
import { handlePage } from "../functions";

class PageNavigator extends React.Component {
    state = {
        page: 1, // Set up at page 1
    }

    handlePage(mouv, page) {
        let targetedPage = handlePage(mouv, page, this.props.pageTotal)
        this.setState({
            page: targetedPage,
        })

        if (typeof this.props.onChange !== "undefined") {
            this.props.onChange(targetedPage)
        }
    }
    render() {
        let page = typeof this.props.defaultActivePage !== "undefined" && this.props.defaultActivePage > 0 ? this.props.defaultActivePage : this.state.page
        const pageTotal = this.props.pageTotal
        if (pageTotal < 2 || typeof pageTotal == "undefined") {
            return null;
        }
        
        return (
            <nav className={this.props.className ? `${this.props.className} mt-3` : "align-self-end mt-3"}>
                <ul className="pagination">
                    <li className="page-item">
                        <a
                            className="page-link"
                            href="#"
                            aria-label="Previous"
                            onClick={() => {
                                this.handlePage(-1, page);
                            }}
                        >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                    <li className={"page-item" + (page === 1 ? " active" : "")}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={() => {
                                this.handlePage(-1, page);
                            }}
                        >
                            {page === 1 ? page : page === pageTotal ? pageTotal > 2 ? page - 2 : page - 1 : page - 1}
                        </a>
                    </li>
                    <li
                        className={
                            "page-item" + (page > 1 ? page < pageTotal ? " active" : pageTotal < 3 ? " active" : "" : "")
                        }
                    >
                        <a
                            className="page-link"
                            href="#"
                            onClick={() => {
                                this.handlePage(0, page);
                            }}
                        >
                            {page === 1 ? page + 1 : page === pageTotal ? pageTotal > 2 ? page - 1 : page : page}
                        </a>
                    </li>
                    {pageTotal > 2 ? <li className={"page-item" + (page === pageTotal ? " active" : "")}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={() => {
                                this.handlePage(+1, page);
                            }}
                        >
                            {page === 1 ? page + 2 : page === pageTotal ? page : page + 1}
                        </a>
                    </li> : null}
                    <li className="page-item">
                        <a
                            className="page-link"
                            href="#"
                            aria-label="Next"
                            onClick={() => {
                                this.handlePage(page === 1 ? 0 : +1, page);
                            }}
                        >
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}

PageNavigator.propTypes = {
    pageTotal: PropTypes.number.isRequired,
    defaultActivePage: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
}

PageNavigator.defaultProps = {
    pageTotal: 0,
}

export default PageNavigator;