import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { userActions } from "../../../_actions";

import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import Header from "./header";
import join from "../../../images/join.png";
import join2x from "../../../images/join@2x.png";
import join3x from "../../../images/join@3x.png";
import "./login.css";

function LoginPage() {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const { username, password } = inputs;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (username && password) {
      dispatch(await userActions.login(username, password));
    }
  }

  function formConnexion(username, password) {
    return (
      <Form className="d-flex flex-column justify-content-center m-auto h-100" style={{maxWidth: 600}}>
        <Form.Group controlId="email">
          < Form.Label className="connexion-label-input" > Email</Form.Label >
          <Form.Control
            className="connexion-form-input"
            type="email"
            name="username"
            placeholder="prenom.nom@domaine.fr"
            value={username}
            onChange={handleChange}
            style={{ height: 40 + "px" }}
          ></Form.Control>
        </Form.Group >
        <Form.Group controlId="password" className="mt-4">
          <Form.Label className="connexion-label-input">
            Mot de passe
          </Form.Label>
          <Form.Control
            className="connexion-form-input"
            type="password"
            name="password"
            placeholder="Entrer votre mot de passe"
            value={password}
            onChange={handleChange}
            style={{ height: 40 + "px" }}
          ></Form.Control>
        </Form.Group>
        <div className="text-center">
          <Button
            variant=""
            className="connexion-button mt-4 mb-3 px-4 py-2"
            type="submit"
            onClick={handleSubmit}
          >
            SE CONNECTER
            <Image
              src={join}
              srcSet={join + " 1x," + join2x + " 2x," + join3x + " 3x"}
              className="ml-2 connexion-button-img"
              alt="Eddmon"
            ></Image>
          </Button>
        </div>
      </Form >
    );
  }

  return (
    <div className="window-size">
      <Header ></Header>
      <Container fluid className="connexion-container nopadding">
        <Row className="connexion-container nopadding">
          <Col className="align-self-center">
            <h1 className="connexion-title pb-4 text-center">CONNEXION</h1>
            {formConnexion(username, password)}
            <Container>
              <Row className="text-center">
                <Col className="col-12">
                  <a
                    href="https://www.tuteuralheure.fr/devis-express/"
                    className="connexion-register"
                  >
                    Non inscrit, je souhaite prendre des cours ?
                  </a>{" "}
                  {/* À changer avec les bons urls*/}
                </Col>
                <Col className="col-12">
                  <a
                    href="https://www.tuteuralheure.fr/inscription-tuteur/"
                    className="connexion-register"
                  >
                    Non inscrit, je souhaite devenir tuteur ?
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;
