import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MenuWithButtons from "../../../Others/menus/menuWithButtons/menuWithButtons";
import ButtonMenu from "../../../Others/buttons/buttonMenu/buttonMenu";
import MainInfoTab from "./tabs/mainInfoTab";
import LessonsTab from "./tabs/lessonsTab";
import Swal from "sweetalert2";
import SideMenu from "../../Lesson/components/sideMenu";
import StudentReportModal from "../../../Modals/studentReportModal"

import { confirmAlert } from "react-confirm-alert";
import { checkMissingData, } from "../../../../../_helpers";
import { apiService } from "../../../../../_services/api.service";
import loading from "../../../Others/process/loading";
import globalSwal from "../../..//Others/alert/globalSwal";
import { withRouter } from "react-router-dom";

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { appConstants } from "../../../../../_constants/app.constants";
import { apiConstants } from "../../../../../_constants";

import StudentModel from "_models/student.model";

function ProfileStudent({ ...props }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [student, setStudent] = useState();
    const [studentReportModal, setStudentReportModal] = useState(false);
    const [options, setOptions] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        if (!window.location.pathname.match(/\d+/)) {
            return;
        }
        // Get student data from API
        const student = await apiService.getStudentById(window.location.pathname.match(/\d+/)[0]);
        // GET grades title list
        const schools = await apiService.getSchools();
        const schoolTypes = await apiService.getSchoolTypes();
        const grades = await apiService.getGrades();
        const hobbies = await apiService.getSkills(null, apiConstants.API_FILTER_HOBBY);
        const options = await apiService.getSkills(1, 0);
        const characteristics = await apiService.getCharacteristics();

        setStudent(student);
        setOptions({
            schools: schools.map(school => school.toOptionModel()),
            types: schoolTypes,
            hobbies: hobbies,
            options: options,
            grades: grades,
            characteristics: characteristics,
        });
    }

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_FIRSTNAME:
                setStudent({ ...student, ["firstname"]: value });
                break;
            case appConstants.FORM_LABEL_LASTNAME:
                setStudent({ ...student, ["lastname"]: value });
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setStudent({ ...student, ["gender"]: value });
                break;
            case appConstants.FORM_LABEL_CLASS:
                setStudent({ ...student, ["grade"]: value });
                break;
            case appConstants.FORM_LABEL_SCHOOL:
                setStudent({ ...student, ["school"]: value?.extra });
                break;
            case appConstants.FORM_LABEL_BIRTHDATE:
                setStudent({ ...student, ["birthdate"]: value });
                break;
            case appConstants.FORM_LABEL_PASSIONS:
                setStudent({ ...student, ["hobbies"]: value });
                break;
            case appConstants.FORM_LABEL_MAIL:
                setStudent({ ...student, ["email"]: value });
                break;
            case appConstants.FORM_LABEL_OPTIONS:
                setStudent({ ...student, ["options"]: value });
                break;
            case appConstants.FORM_LABEL_CHARACTERISTICS:
                setStudent({ ...student, ["characteristics"]: value });
                break;
            default:
                break;
        }
    }

    function onCreate(newSchool) {
        setStudent(new StudentModel().buildFromInstance({
            ...student,
            school: newSchool,
        }))
    }

    async function onSave(event) {
        event.preventDefault();
        setSubmitted(true);

        // Check if all data are provided.
        if (checkMissingData(student, ["lessons", "notes", "parent", "hobbies", "email", "skills", "skills", "options", "extra", "characteristics"])) {
            loading(true);

            await apiService.patchStudentById(student.id, student.toFormUpdate())
                .then(result => {
                    if (result) {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_SUCCESS,
                            title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                })

        } else {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                icon: appConstants.SWAL_ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    }

    async function handleRemoveStudent() {
        loading((true));
        await apiService.deleteStudentById((student.id)).then(response => {
            if (response === apiConstants.SUCCESS) {
                globalSwal({
                    icon: appConstants.SWAL_ICON_SUCCESS,
                    title: appConstants.DEFAULT_SWAL_TITLE_DELETE_SUCCESS,
                    text: appConstants.SWAL_MESSAGE_REDIRECTION_PROFILS,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                }).then(function () {
                    props.history.push("/admin-home/profils");
                });
            } else if (response?.status === 409) { // Display message error when delete is not available because of business conditions
                globalSwal({
                    icon: appConstants.SWAL_ICON_WARNING,
                    title: appConstants.DEFAULT_SWAL_TITLE_REMOVE_STUDENT_WARNING,
                    text: response?.message,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
            else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.SWAL_MESSAGE_DELETE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        })
    }

    function confirmRemove() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div
                        className="custom-ui p-5"
                        style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
                    >
                        <h1
                            className="police-gilroy-font mb-4 text-center"
                            style={{ fontSize: 110 + "%" }}
                        >
                            Voulez-vous supprimer ce compte élève ?
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                }}
                                onClick={async () => {
                                    onClose();
                                    await handleRemoveStudent();
                                }}
                            >
                                CONFIRMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    return (
        <div className="py-5 pl-4 pr-5 h-100 d-flex flex-column">
            <StudentReportModal open={studentReportModal} lessons={student?.lessons} onClose={_event => setStudentReportModal(false)} />

            <div className="d-flex h-100" style={{ overflow: "hidden" }}>
                <SideMenu id={student?.id} />

                <MenuWithButtons className="w-100 h-100 px-3 d-flex flex-column" style={{ overflow: "hidden" }}>
                    <ButtonMenu className="ml-3 mb-5" onActive={<MainInfoTab student={student} options={options} onChange={onChange} onCreate={onCreate} submitted={submitted} />}  >INFOS PRINCIPALES</ButtonMenu>
                    <ButtonMenu className="mb-5" onActive={<LessonsTab lessons={student === apiConstants.ERROR ? apiConstants.ERROR : student?.lessons} onChange={onChange} submitted={submitted} />} >LEÇONS</ButtonMenu>
                </MenuWithButtons>

                <div className={"col px-0 h-100 pt-1" + (appConstants.SCREEN_WIDTH_EXTRA_LARGE > windowWidth ? " pr-3" : "")}>
                    <Button variant="" style={{ fontSize: "inherit" }} className={"oc-button-empty2"} onClick={() => { window.history.back(); }}>
                        RETOUR
                    </Button>
                </div>
            </div>


            <div className="d-flex mt-3">
                <div className="d-flex" style={{ maxWidth: 25 + "%", fontSize: 75 + "%" }}>
                    <Button variant="" className="w-100 h-100 oc-button-empty-blue px-2 mr-3 " style={{ fontSize: "inherit" }} onClick={onSave}>
                        SAUVEGARDER
                    </Button>
                    <Button variant="" className="w-100 h-100 empty-box-font oc-button-empty-blue custom-btn-red px-2" style={{ fontSize: "inherit", color: "#ea1616", borderColor: "#ea1616", }} onClick={() => { confirmRemove(); }}>
                        SUPPRIMER
                    </Button>
                </div>
                <Button variant="" className="h-100 oc-button-empty-blue px-2 mr-3 ml-auto" style={{ fontSize: "inherit" }} onClick={_event => setStudentReportModal(true)}>
                    <div className="d-flex align-items-center">
                        <span className="ml-1">Suivi</span>
                        <DescriptionOutlinedIcon />
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default withRouter(ProfileStudent);
