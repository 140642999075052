import { useEffect, useState } from "react";
import Avatar from "../../../Others/avatar/avatar";

import PropTypes from 'prop-types';
import FileModel from "../../../../../_models/file.model";
import {Button} from "react-bootstrap";

function SideMenu({ file, onSave, pictureOptions, loading = false, editable = true, customer = false, onConfirmDelete = null, id }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    return (
        <div className="col-2 pr-3 h-100 d-flex flex-column">
            {customer ?
                <p className="oc-title-font text-nowrap">ID: {id}</p>
                :
                <Avatar file={file} height={pictureOptions?.height} width={pictureOptions?.width} editable={editable} />
            }
            <div className="h-100 d-flex align-items-end">
                <button
                    className="btn oc-button-empty-blue w-100"
                    style={{
                        fontSize: "inherit",
                        borderColor: "#3c8bec",
                    }}
                    disabled={loading}
                    onClick={onSave}
                >
                    SAUVEGARDER
                </button>
                {onConfirmDelete !== null ?
                    <Button
                        variant=""
                        className=" empty-box-font oc-button-empty-blue custom-btn-red px-2 ml-3"
                        style={{
                            fontSize: "inherit",
                            color: "#ea1616",
                            borderColor: "#ea1616",
                        }}
                        onClick={onConfirmDelete}
                    >
                        SUPPRIMER
                    </Button> : null}
            </div>
        </div>

    )
}

SideMenu.propTypes = {
    file: PropTypes.instanceOf(FileModel),
}

export default SideMenu;
