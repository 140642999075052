import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { appConstants } from '_constants';


function TextAreaFormGroupInput({ onChange, label, value, className, classNameText, placeholder, disabled = false, hideLabel = false, dynamicHeight = false, minLength, minHeight, required = false, submitted = false, invalid = false, invalidFeedBackMessage = appConstants.DEFAULT_INVALID_FEEDBACK }) {
    const [inputValue, setInputValue] = useState(value);
    const [inputHeight, setInputHeight] = useState(undefined);
    const inputRef = useRef(null)
    const spacer = 4;

    useEffect(() => {
        setInputValue(value || "");
        if (dynamicHeight) {
            setInputHeight(inputRef.current?.scrollHeight + spacer)
        }
    }, [value]);

    useEffect(() => {
        setInputHeight(inputRef.current?.scrollHeight + spacer)
    }, [inputRef]);

    function handleOnChange(event) {
        setInputValue(event.target.value);
        onChange(event.target.value, label);
    }

    return (
        <div className={className ? `form-group ${className}` : "form-group w-100 mx-3 "}>
            {!hideLabel ?
                <label>
                    {label}
                </label>
                : null
            }
            <textarea
                ref={inputRef}
                className={`input-font form-control ${classNameText} ${(submitted && !inputValue) || invalid ? "is-invalid " : ""}`}
                onChange={handleOnChange}
                style={{ minHeight: minHeight ? minHeight : value ? "100px" : "55px", height: `${inputHeight}px` }}
                placeholder={placeholder}
                value={inputValue}
                disabled={disabled}
                minLength={minLength}
            />
            {required ? <div className="invalid-feedback">{invalidFeedBackMessage}</div> : null}
        </div>
    )
}

TextAreaFormGroupInput.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    submitted: PropTypes.bool,
    hideLabel: PropTypes.bool,
}

TextAreaFormGroupInput.defaultProps = {
    onChange: () => { return }
}

export default TextAreaFormGroupInput;