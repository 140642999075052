import React from 'react';
import TableManager from './Body/Tools/components/tableManager';
import NavbarEspace from './Navbar/Navbar';

class OutilsTabPage extends React.Component {

    render() {

        return (
            <div className="window-size d-flex flex-column">
                <div className="d-block">
                    <NavbarEspace onglet="outils" />
                </div>
                <div className="container-fluid h-100 d-flex px-0" style={{ overflow: "auto", maxHeight: 'calc(100vh - 80px)' }}>
                    <div className="row h-100 mx-0 w-100">
                        <div className="col px-0 mx-xl-5 h-100">
                            <TableManager tableName={this.props.tabName} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default OutilsTabPage;

