import { useEffect, useState } from "react";
import TextFormGroupInput from "./textFormGroupInput";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import PropTypes from 'prop-types';
import { apiService } from "../../../../../_services/api.service";
import { checkMissingData } from "../../../../../_helpers";

import AddressModel from "../../../../../_models/address.model";

import { apiConstants, appConstants } from "../../../../../_constants";
import loading from "../../process/loading";
import globalSwal from "../../alert/globalSwal";

function AddressFormGroupInput({ address: addressProps, onSubmit, customer, classname, onChange, submitted: submittedProps = false, formOnly = false, disabled = false, index }) {
    const [address, setAddress] = useState(addressProps);
    const [submitted, setSubmitted] = useState(submittedProps);

    useEffect(() => {
        if (addressProps !== address) {
            setAddress(addressProps);
        }
    }, [addressProps]);

    useEffect(() => {
        setSubmitted(submittedProps);
    }, [submittedProps]);

    function handleOnChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_ADDRESS_NUMBER:
                setAddress({ ...address, number: value });
                break;
            case appConstants.FORM_LABEL_ADDRESS_STREET:
                setAddress({ ...address, street: value });
                break;
            case appConstants.FORM_LABEL_ADDRESS_CITY:
                setAddress({ ...address, city: value });
                break;
            case appConstants.FORM_LABEL_ADDRESS_POSTCODE:
                setAddress({ ...address, zipcode: value });
                break;
            default:
                break;
        }
        onChange(value, label);
    }

    async function handleOnSubmit(event) {
        event.preventDefault();
        setSubmitted(true);
        if (checkMissingData(address, ["id"]) && checkMissingData(customer)) {
            const form = {
                number: address?.number,
                street: address?.street,
                city: address?.city,
                zipcode: address?.zipcode,
                customer: `${apiConstants.API_CUSTOMERS}/${customer?.id}`,
                firstname: customer?.firstname,
                lastname: customer?.lastname,
            };

            const newAddress = address?.toObject();
            loading(true);

            await apiService.postAddress(form).then(success => {
                if (success) {
                    newAddress.id = parseInt(success.data["@id"].match(/\d+/)[0]);
                    setSubmitted(false);
                    onSubmit(new AddressModel().build(newAddress))
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_ADD_ADDRESS_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    }).then(_ => {
                        //setAddress(address.clear());
                    })
                } else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            });
            loading(false);
            setSubmitted(false);
        }
    }

    return (
        <div className={classname ? classname : "row mx-0"} index={index}>
            <div className="col-2 pt-2 pl-0">
                <TextFormGroupInput className="mx-0" labelClassName="text-nowrap" label={appConstants.FORM_LABEL_ADDRESS_NUMBER} value={address?.number} onChange={handleOnChange} submitted={submitted} hideLabel={!formOnly} disabled={disabled} />
            </div>

            <div className="col-4 pt-2">
                <TextFormGroupInput className="mx-0" labelClassName="text-nowrap" label={appConstants.FORM_LABEL_ADDRESS_STREET} value={address?.street} onChange={handleOnChange} submitted={submitted} hideLabel={!formOnly} disabled={disabled} />

            </div>

            <div className={formOnly ? "col-4 pt-2" : "col-3 pt-2"}>
                <TextFormGroupInput className="mx-0" labelClassName="text-nowrap" label={appConstants.FORM_LABEL_ADDRESS_CITY} value={address?.city} onChange={handleOnChange} submitted={submitted} hideLabel={!formOnly} disabled={disabled} />
            </div>

            <div className={formOnly ? "col-2 pt-2 pr-0" : "col-2 pt-2 "}>
                <TextFormGroupInput className="mx-0" labelClassName="text-nowrap" label={appConstants.FORM_LABEL_ADDRESS_POSTCODE} value={address?.zipcode} onChange={handleOnChange} submitted={submitted} hideLabel={!formOnly} disabled={disabled} />

            </div>

            {!formOnly ?
                <div className="col-1 pb-5 d-flex justify-content-end pr-1">
                    <Button
                        variant=""
                        className="btn oc-button3 align-self-center"
                        style={{
                            float: "right",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                        }}
                        onClick={handleOnSubmit}
                        type="submit"
                    >
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="oc-icon2"
                            size="1x"
                        />
                    </Button>
                </div>
                :
                null
            }
        </div>

    )
}


AddressFormGroupInput.propTypes = {
    address: PropTypes.instanceOf(AddressModel),
    value: PropTypes.any,
    submitted: PropTypes.bool,
    onSubmit: PropTypes.func,
}

AddressFormGroupInput.defaultProps = {
    onSubmit: () => { return },
    onChange: () => { return }
}

export default AddressFormGroupInput;