import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalSwal from "components/admin/Others/alert/globalSwal";
import ButtonAction from "components/admin/Others/buttons/buttonAction/buttonAction";
import SelectFormGroupInput from "components/admin/Others/forms/components/selectFormGroupInput";
import loading from "components/admin/Others/process/loading";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Swal from "sweetalert2";
import { apiConstants, appConstants, colorsConstants } from "_constants";
import { apiService } from "_services/api.service";

function SubjectArray({ subjects, subjectsRequest, requestId, onChange = () => { return }, submitted }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [newSubject, setNewSubject] = useState(undefined);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    async function onAddSubject() {
        if (newSubject) {
            if (subjectsRequest.find(subject => subject?.value?.skill_id == newSubject)) {
                Swal.fire({
                    title: appConstants.DEFAULT_SWAL_TITLE_SUBJECT_ALREADY_ADDED,
                    icon: appConstants.SWAL_ICON_INFO,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            } else {
                const form = {
                    request: `${apiConstants.API_REQUESTS}/${requestId}`,
                    skill: `${apiConstants.API_SKILLS}/${newSubject}`,
                };

                loading(true);
                await apiService.postRequestSkills(form).then(requestSkillId => {
                    if (requestSkillId) {
                        const subjectsRequestUpdated = subjectsRequest;
                        subjectsRequestUpdated.push({ value: { requestSkillsId: requestSkillId, skill_id: Number(newSubject) }, label: subjects.find(subject => subject.value == newSubject)?.label });
                        onChange(subjectsRequestUpdated, appConstants.FORM_LABEL_SUBJECTS);
                        globalSwal({
                            icon: appConstants.SWAL_ICON_SUCCESS,
                            title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                    else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                })
            }
        }
    }

    async function removeSubject(id) {
        //Remove skill from database.  
        await apiService.deleteRequestSkillById(id).then(success => {
            if (success) {
                let newSubjectsRequest = subjectsRequest;
                newSubjectsRequest.splice(subjectsRequest.findIndex(subject => subject?.value?.requestSkillsId == id), 1);

                onChange(newSubjectsRequest, appConstants.FORM_LABEL_SUBJECTS);
                globalSwal({
                    icon: appConstants.SWAL_ICON_SUCCESS,
                    title: appConstants.DEFAULT_SWAL_TITLE_DELETE_SUCCESS,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
            else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        });

    }

    function confirmRemove(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-ui p-5" style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}>
                        <h1 className="police-gilroy-font mb-4 text-center" style={{ fontSize: 110 + "%" }}>
                            Veuillez confimer votre choix ?
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                }}
                                onClick={() => {
                                    onClose();
                                    removeSubject(id);
                                }}
                            >
                                CONFIRMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    function onChangeSubject(value, _label) {
        setNewSubject(value);
    }

    return (
        <div className="h-100 m-0 pl-0" style={{ flex: "0" }}>
            <p className="w-100 mb-3 police-gilroy-font" style={{ fontSize: 110 + "%" }} >
                MATIÈRE(S) DE LA DEMANDE
            </p>

            <div className="w-100 px-0 d-flex flex-column">
                {/* <div
                    className="row w-100 m-0 police-gilroy-font"
                    style={{
                        fontSize: windowWidth < 576 ? 4 + "vw" : 120 + "%",
                        borderBottom: "1px solid var(--purple-tah-color)",
                        height: windowWidth < 576 ? 35 + "px" : 44 + "px",
                    }}
                >
                    <div className="col-4 pl-0 mb-2">Matières</div>
                </div> */}

                <div className="row w-100 mb-4 mx-0" style={{ color: "#212529" }}>
                    <div className={"col-9 pl-0 pr-4 scrollbar-hidden " + (submitted && subjectsRequest?.length < 1 ? "mt-1" : "my-auto")}>
                        <SelectFormGroupInput className="ml-0 mb-0" value={null} options={subjects} onChange={onChangeSubject} invalidFeedBackMessage={appConstants.INVALID_FEEDBACK_ADD_SKILLS_MIN_1} hideLabel submitted={submitted && subjectsRequest?.length < 1}/>
                    </div>

                    <div className="col-3 pr-0 d-flex justify-content-end">
                        <ButtonAction onClick={onAddSubject} className="px-3 py-2 align-self-start scrollbar-hidden ml-3" style={{ width: "fit-content !important", }} color={colorsConstants.LIGHT_BLUE}>
                            AJOUTER
                        </ButtonAction>
                    </div>

                    <div className="row d-block h-100 mx-0 w-100 mt-3" style={{ overflow: "hidden auto" }}>
                        {subjectsRequest?.map((obj, key) => {
                            return (
                                <div key={key} className="d-flex justify-content-between w-100 mx-0 mb-2">
                                    <div className="pl-1 pr-4 my-auto">
                                        {subjects?.find(
                                            (elt) => elt?.value === obj?.value?.skill_id
                                        )
                                            ? subjects?.find(
                                                (elt) => elt?.value === obj?.value?.skill_id
                                            ).label
                                            : null}
                                    </div>
                                    <Button
                                        variant=""
                                        className="oc-button2 align-self-start"
                                        style={{
                                            float: "right",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                        }}
                                        onClick={() => { confirmRemove(obj?.value?.requestSkillsId); }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className="oc-icon2"
                                            size="1x"
                                        />
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubjectArray;
