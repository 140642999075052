import Swal from 'sweetalert2';
import MultiSelectFormGroupInput from 'components/admin/Others/forms/components/multiSelectFormGroupInput';
import SelectAsyncSearchFormGroupInput from 'components/admin/Others/forms/components/selectAsyncSearchFormGroupInput';
import { ToggleFormGroupInput } from 'components/admin/Others/forms/components/toggleFormGroupInput';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import globalSwal from 'components/admin/Others/alert/globalSwal';
import loading from 'components/admin/Others/process/loading';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MixinSwal } from '../../../Others/alert/mixinSwal';

import { apiConstants, appConstants, buttonConstants, lessonConstants, placeHolderConstants } from '_constants';
import { apiService } from '_services/api.service';

import RequestModel from '_models/request.model';
import ErrorModel from '_models/error.model';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import TextFormGroupInput from 'components/admin/Others/forms/components/textFormGroupInput';
import SelectFormGroupInput from 'components/admin/Others/forms/components/selectFormGroupInput';
import TextAreaFormGroupInput from 'components/admin/Others/forms/components/textAreaFormGroupInput';
import FormModel from '_models/form.model';
import { requestConstants } from '_constants/request.constants';
import ButtonAction from 'components/admin/Others/buttons/buttonAction/buttonAction';

import SendIcon from '@material-ui/icons/Send';

class Form extends FormModel {
    constructor(student, customer, tutor, availability, beginAt, endAt, comment, duration, lessonPerWeek, address, subjects, privateNotes = "", isOnline = false, notifyTutor = false, preferences = 'none') {
        super();
        this.customer = customer;
        this.student = student;
        this.tutor = tutor;
        this.availability = availability;
        this.beginAt = beginAt;
        this.endAt = endAt;
        this.comment = comment;
        this.duration = duration;
        this.lessonPerWeek = lessonPerWeek;
        this.address = address;
        this.subjects = subjects;
        this.isOnline = isOnline;
        this.privateNotes = privateNotes;
        this.preferences = preferences;
    }

    toForm() {
        return {
            customer: `${apiConstants.API_CUSTOMERS}/${this.customer.value}`,
            student: `${apiConstants.API_STUDENTS}/${this.student.value}`,
            address: `${apiConstants.API_ADDRESSES}/${this?.address.value}`,
            requestSkills: this.subjects?.map(skill => { return { skill: `${apiConstants.API_SKILLS}/${skill.value}` } }),
            beginAt: this.beginAt,
            endAt: this.endAt,
            lessonsDuration: (this.duration / 60).toString(),
            lessonsPerWeek: parseInt(this.lessonPerWeek),
            availability: this.availability,
            notes: this.comment,
            online: this.isOnline,
            privateNotes: this.privateNotes,
            preferences: this.preferences
        }
    }

    completed() {
        return Object.keys(this).every(key => {
            if (["tutor", "extra", "isOnline", "comment"].includes(key)) {
                return true;
            } else if(key === "subjects") {
                return this[key].length > 0;
            } else {
                return this[key] != undefined;
            }
        })
    }
}

function FormRequest({ ...props }) {
    const { customerId } = useParams();
    const [customers, setCustomers] = useState(undefined);
    const [tutors, setTutors] = useState(undefined);
    const [students, setStudents] = useState(undefined);
    const [addresses, setAddresses] = useState(undefined);
    const [subjects, setSubjects] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [request, setRequest] = useState(new RequestModel());

    const [form, setForm] = useState(new Form());
    const minDate = new Date((new Date()).getTime() + (0 * 86400000)).toISOString().split('T')[0];
    const date = new Date();
    const year = date.getFullYear();
    let maxDate = new Date(year, 6, 2).toISOString().split('T')[0];

    if (minDate >= maxDate)
        maxDate = new Date(year + 1, 6, 2).toISOString().split('T')[0];

    useEffect(() => {
        init();
    }, []);

    async function init() {
        let customers = await apiService.getCustomers();

        if (customerId) {
            let defaultCustomer = customers?.find(elt => parseInt(elt.value) === parseInt(customerId));

            if (typeof defaultCustomer === "undefined") {
                defaultCustomer = await apiService.getCustomerOptionById(customerId);
            }
            await onChange(defaultCustomer, appConstants.FORM_LABEL_CUSTOMER)
                .then(async () => {
                    // Sort the customers array in order to have defaultCustomer to top position.
                    customers = customers?.filter(item => item !== defaultCustomer);
                    customers?.unshift(defaultCustomer);
                })
                .catch(_error => {
                    defaultCustomer = undefined;
                });

            setRequest({ ...request, consumer: defaultCustomer });
        }else {
            setStudents(await apiService.getStudents());
            setAddresses(await apiService.getAddresses());
        }

        setCustomers(customers);
        setTutors(await apiService.getTutors());
        setSubjects(await apiService.getSkills(1));
        setForm(new Form().buildFromInstance({ ...form, endAt: maxDate }));
    };

    async function onChange(value, label) {
        let response;
        if (typeof value !== "undefined") {
            switch (label) {
                case appConstants.FORM_LABEL_CUSTOMER:
                    if (typeof value?.value !== "undefined") {
                        response = await apiService.getStudentsAndAddressesLinkedToCustomerById(value.value);
                        if (response) {
                            //setRequest({ ...request, consumer: value });
                            setForm(new Form().buildFromInstance({ ...form, customer: value }));
                            setStudents(response?.students);
                            setAddresses(response?.addresses);
                        } else {
                            throw new Error();
                        }
                    } else init();
                    break;

                case appConstants.FORM_LABEL_STUDENT:
                    if (typeof value?.value !== "undefined") {
                        //setRequest({ ...request, student: value });
                        setForm(new Form().buildFromInstance({ ...form, student: value }));
                        response = await apiService.getAddressesAndCustomerLinkedToStudentById(value.value);
                        setCustomers(response?.customer);
                        setAddresses(response?.addresses);
                    } else init();
                    break;

                case appConstants.FORM_LABEL_TUTOR_OPTIONAL:
                    if (typeof value?.value !== "undefined") {
                        //setRequest({ ...request, tutor: value });
                        setForm(new Form().buildFromInstance({ ...form, tutor: value }));
                    } else init();
                    break;

                case appConstants.FORM_LABEL_ADDRESS:
                    if (typeof value?.value !== "undefined") {
                        setForm(new Form().buildFromInstance({ ...form, address: value }));
                        //setRequest({ ...request, address: value });
                        response = await apiService.getStudentsAndCustomerLinkedToAddressById(value.value);
                        setCustomers(response?.customer);
                        setStudents(response?.students);
                    } else init();
                    break;

                case appConstants.FORM_LABEL_SUBJECTS:
                    //setRequest({ ...request, skillsId: value });
                    setForm(new Form().buildFromInstance({ ...form, subjects: value }));
                    break;

                case appConstants.FORM_LABEL_AVAILABILITIES:
                    setForm(new Form().buildFromInstance({ ...form, availability: value }));
                break;

                case appConstants.FORM_LABEL_STARTING_DATE:
                    setForm(new Form().buildFromInstance({ ...form, beginAt: value }));
                    break;

                case appConstants.FORM_LABEL_ENDING_DATE:
                    setForm(new Form().buildFromInstance({ ...form, endAt: value }));
                    break;

                case appConstants.FORM_LABEL_GENDER_PREFERENCE:
                    setForm(new Form().buildFromInstance({ ...form, preferences: value }));
                    break;

                case appConstants.FORM_LABEL_COMMENT:
                    setForm(new Form().buildFromInstance({ ...form, comment: value }));
                    break;

                case appConstants.FORM_LABEL_COURSE_DURATION_2:
                    setForm(new Form().buildFromInstance({ ...form, duration: value }));
                    break;

                case appConstants.FORM_LABEL_NBR_LESSONS:
                    setForm(new Form().buildFromInstance({ ...form, lessonPerWeek: value }));
                    break;

                case appConstants.FORM_LABEL_COURSE_TYPE:
                    setForm(new Form().buildFromInstance({ ...form, isOnline: value }));
                    break;

                case appConstants.FORM_LABEL_PRIVATE_NOTES:
                    setForm(new Form().buildFromInstance({ ...form, privateNotes: value }));
                    break;

                default:
                    break;
            }
        } else init();
    }

    async function onSave() {
        setSubmitted(true);
        if (form.completed()) {
            loading(true);
            await apiService.postRequest(form.toForm())
                .then(async response => {
                        if (!(response instanceof ErrorModel)) {
                        // If request successfully created, add tutor 'candidature' if tutor has been added.
                        if (request.tutor.value) {
                            const applicationForm = {
                                request: `${apiConstants.API_REQUESTS}/${response.id}`,
                                tutor: `${apiConstants.API_TUTORS}/${form.tutor.value}`,
                            }
                            await apiService.postApplication(applicationForm)
                                .then(success => {
                                    if (success) {
                                        MixinSwal({
                                            icon: appConstants.SWAL_ICON_SUCCESS,
                                            title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                                            timer: 1000,
                                        }).then(function () {
                                            props.history.push(`/admin-home/formulaire-demande/${response?.id}`);
                                        });
                                    } else {
                                        globalSwal({
                                            icon: appConstants.SWAL_ICON_WARNING,
                                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                                            text: appConstants.DEFAULT_SWAL_MESSAGE_POST_APPLICATION_WARNING,
                                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                                        }).then(function () {
                                            props.history.push(`/admin-home/formulaire-demande/${response?.id}`);
                                        });;
                                    }
                                })

                        } else {
                            MixinSwal({
                                icon: appConstants.SWAL_ICON_SUCCESS,
                                title: appConstants.DEFAULT_SWAL_TITLE_POST_SUCCESS,
                                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                                timer: 1000,
                            }).then(function () {
                                props.history.push(`/admin-home/formulaire-demande/${response?.id}`);
                            });
                        }
                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            text: response.message || appConstants.DEFAULT_SWAL_MESSAGE_POST_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                });
            setSubmitted(false);
        } else {
            Swal.fire({
                icon: appConstants.SWAL_ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }

    }

    return (
        <form className="container w-100 px-5 pb-5 d-flex flex-column" style={{ overflow: "hidden auto" }}>
            <SelectAsyncSearchFormGroupInput className="pl-0" value={form.consumer} defaultOptions={customers} label={appConstants.FORM_LABEL_CUSTOMER} onChange={onChange} onSearch={apiService.getCustomerWithCallback} submitted={submitted} required/>
            <SelectAsyncSearchFormGroupInput className="pl-0" value={form.student} defaultOptions={students} label={appConstants.FORM_LABEL_STUDENT} onChange={onChange} submitted={submitted} required/>
            <SelectAsyncSearchFormGroupInput className="pl-0" value={form.tutor} defaultOptions={tutors} label={appConstants.FORM_LABEL_TUTOR_OPTIONAL} onChange={onChange} onSearch={apiService.getTutorsWithCallback} submitted={submitted} />
            <SelectAsyncSearchFormGroupInput className="pl-0" value={form.address} defaultOptions={addresses} label={appConstants.FORM_LABEL_ADDRESS} onChange={onChange} onSearch={apiService.getAddressWithCallback} submitted={submitted} required/>
            <MultiSelectFormGroupInput className="w-100 ml-0" label={appConstants.FORM_LABEL_SUBJECTS} options={subjects} onChange={onChange} invalidFeedBackMessage={appConstants.INVALID_FEEDBACK_SELECT_SKILLS_MIN_1} submitted={submitted} />

            <TextFormGroupInput className="mx-0" type="date" min={minDate} label={appConstants.FORM_LABEL_STARTING_DATE} value={form.beginAt} onChange={onChange} submitted={submitted} required />
            <TextFormGroupInput className="mx-0" type="date" min={form?.beginAt?.length > 0 ? new Date(new Date(form.beginAt).setDate(new Date(form.beginAt).getDate() + 1)).toISOString().split('T')[0] : null} label={appConstants.FORM_LABEL_ENDING_DATE} value={maxDate} onChange={onChange} submitted={submitted} required />
            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_COURSE_DURATION_2} value={form.duration} onChange={onChange} options={lessonConstants.DURATIONS_LIST()} submitted={submitted} />
            <SelectFormGroupInput className="mx-0" type="number" label={appConstants.FORM_LABEL_NBR_LESSONS} value={form.lessonPerWeek} onChange={onChange} options={requestConstants.NBR_LESSONS_OPTIONS} submitted={submitted}/>

            <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_GENDER_PREFERENCE} value={form.preferences} options={appConstants.DEFAULT_GENDER_PREFERENCE_OPTIONS} onChange={onChange} submitted={submitted} required />
            <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_AVAILABILITIES} value={form.availability} onChange={onChange} submitted={submitted} placeholder={placeHolderConstants.AVAILABILITIES} required />

            <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_COMMENT} value={form.comment} onChange={onChange} placeholder={placeHolderConstants.FILL_COMMENTARY} submitted={submitted} />
            <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_PRIVATE_NOTES} value={form.privateNotes} onChange={onChange} placeholder={placeHolderConstants.FILL_PRIVATE_NOTES} submitted={submitted} />

            <div className="d-flex justify-content-between align-items-top mt-4">
                <ToggleFormGroupInput className="d-flex align-items-center justify-content-between w-100 mr-5" labelClassname="mb-2" label={appConstants.FORM_LABEL_COURSE_TYPE} value={form.isOnline} onChange={onChange} />
            </div>

            <ButtonAction className="mx-0 mt-5 align-self-center oc-button-empty-blue" onClick={onSave} style={{ fontSize: "15px" }} >
                {buttonConstants.FORM_SUBMIT_REPORT}
                <SendIcon className="align-middle ml-2" />
            </ButtonAction>
        </form>
    )
}

export default withRouter(FormRequest);
