import { useEffect, useState } from "react";
import ButtonLink from "../../../../Others/buttons/buttonLink/buttonLink";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../../_constants/app.constants";
import LoaderSpinner from "../../../../Others/loader/loader";

function LessonsTab({ lessons, _onSearch }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", _ => {
            setWindowWidth(window.innerWidth);
        })
    }, [windowWidth]);

    if (typeof lessons === "undefined") {
        return (<LoaderSpinner />)
    }else if (!lessons) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }

    return (
        <div className="h-100 d-flex flex-column px-3" style={{overflow: "hidden auto"}}>


            {/* <div className="pb-4 px-0 d-flex align-items-center mb-3 mt-2">
                <label className="col-xl-1 col-md-2 col px-0 m-0 mr-3 police-gilroy-font">
                    {appConstants.FORM_LABEL_SEARCH}
                </label>
                <TextFormGroupInput className="col-3 mb-0 px-0" inputClassName="empty-box-font" onChange={onSearch} hideLabel />
            </div> */}

            <div
                className="row m-0 police-gilroy-font"
                style={{
                    fontSize: windowWidth < appConstants.SCREEN_WIDTH_SMALL ? 4 + "vw" : 120 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-1 pl-0 mb-2">ID</div>
                <div className="col-2 pl-0 mb-2">DATE</div>
                <div className="col-4 pl-2 mb-2">DEMANDE</div>
                <div className="col-2 pl-5 mb-2">DURÉE</div>
                <div className="col-2 pl-2 mb-2">STATUT</div>
                {/* <div className="col-2 pl-2 mb-2">NOTE</div> */}
            </div>

            <div
                className="row w-100 mx-0 mt-3 h-100 d-block"
                style={{ overflow: "hidden auto" }}
            >
                {lessons?.length === 0 ?
                    <div className="w-100 text-center text-input-font mt-3">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
                    :
                    
                    lessons?.map((elt, key) => (
                        <div
                            className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                            key={key}
                        >
                            <div className="col-1 pl-0">{elt.id}</div>
                            <div className="col-2 pl-0">{elt.created}</div>

                            <div className="col-4 pl-0">
                                <ButtonLink link={`/admin-home/formulaire-demande/${elt.requestId}`} firstname={elt.consumer?.fullname} lastname={`/${elt.tutor.fullname}`} />
                            </div>
                            <div className="col-2 pl-5">{elt.duration}</div>
                            <div className="col-2 pl-2">{elt.status}</div>
                            {/* <div className="col-2 pl-2">{elt.score}</div> */}
                            <div className="col-1 pr-0 mb-2 d-flex flex-column align-items-end" style={{ color: "#3c8bec" }}>
                                <ButtonLink className="btn oc-button-empty-blue px-3 py-2" link={"/admin-home/lesson/" + elt.id}>i</ButtonLink>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

LessonsTab.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.any),
}

export default LessonsTab;
