import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';

import { appConstants } from "../../../../../_constants/app.constants";

import OptionModel from '_models/option.model';

const EMPTY_VALUE = new OptionModel();

function SelectAsyncSearchFormGroupInput({ value: valueProps = EMPTY_VALUE, onChange = async () => { return }, label, defaultOptions: defaultOptionsProps, onSearch = () => { return }, style, className = "", inputClassName = "", labelClassName = "", invalidFeedBackMessage = appConstants.DEFAULT_SELECT_INVALID_FEEDBACK, submitted = false, disabled = false, hideLabel = false, required = false }) {
    const [value, setValue] = useState(valueProps);
    const [defaultOptions, setDefaultOptions] = useState([]);

    useEffect(() => {
        setDefaultOptions(defaultOptionsProps);
    }, [defaultOptionsProps]);

    useEffect(() => {
        if (value.value !== valueProps.value || value.label !== valueProps.label) {
            setValue(valueProps);
        }
    }, [valueProps]);

    async function handleOnChange(event) {
        // When user deletes value.
        if (event === null) {
            setValue(EMPTY_VALUE);
            await onChange(EMPTY_VALUE, label);
        } else {
            setValue(event);
            await onChange(event, label);
        }
    }

    return (
        <div className={className ? `form-group ${className}` : "form-group w-100 mx-3 "} style={style}>
            {!hideLabel ?
                <label className={labelClassName ? labelClassName : "text-input-font"}>
                    {label}
                </label>
                : null
            }
            <AsyncSelect
                isClearable
                styles={{
                    control: (provided) => ({
                        ...provided,
                        height: '35px',
                        border: 'solid 2px var(--purple-tah-color) !important',
                        borderRadius: '0',
                        backgroundColor: '#e6f0ff',
                    })
                }}
                value={value}
                disabled={typeof defaultOptions === "undefined" || disabled}
                className={inputClassName}
                onChange={handleOnChange}
                defaultOptions={defaultOptions}
                isLoading={typeof defaultOptions === "undefined"}
                loadOptions={onSearch}
            />
            {submitted && required && typeof value?.value === "undefined" ? <div className="invalid-feedback-custom">{invalidFeedBackMessage}</div> : null}
        </div>
    )
}

export default SelectAsyncSearchFormGroupInput;
