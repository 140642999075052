export const apiConstants = {
  //API_URL: 'https://www.dev.tuteuralheure.fr'
  API_URL: 'http://localhost:8000',

  /***** ROUTES *****/
  USER_CREATE_PASSWORD: "/user/recover_password",
  USER_CHANGE_PASSWORD: "/user/change_password",
  API_LOGIN: "/login_check",
  API_USERS: "/api/users",
  API_CUSTOMERS: "/api/customers",
  API_STUDENTS: "/api/students",
  API_TUTORS: "/api/tutors",
  API_GRADES: "/api/grades",
  API_SKILLS: "/api/skills",
  API_TUTORS_SKILLS : "/api/tutor_skills",
  API_PRICES: "/api/prices",
  API_FILES: "/api/files",
  API_REQUESTS: "/api/requests",
  API_APPLICATIONS: "/api/applications",
  API_DISLIKES: "/api/dislikes",
  API_LESSONS: "/api/lessons",
  API_ADDRESSES: "/api/addresses",
  API_CREATE_FILE: "/api/files",
  API_UPDATE_FILE: "/api/files/patch",
  API_CREATE_SETUP_INTENT: "/api/stripe/customer/create_setup_intent",
  API_CREATE_STRIPE_CONNECT_ACCOUNT: "api/stripe/tutor/create_connect_account",
  API_STRIPE_CUSTOMER: "/api/stripe/customer",
  API_STRIPE_TUTOR: "/api/stripe/tutor",
  API_CREATE_STRIPE_ACCOUNT_LINK: "/api/stripe/tutor/get_account_link",
  API_REFRESH_STRIPE_ACCOUNT_LINK: "/api/stripe/tutor/refresh_account_link",
  API_INVOICES: "/api/invoices",
  API_CREATE_SETUP_INTENT_CONNECT: "/api/stripe/tutor/create_setup_intent",
  API_CREATE_STRIPE_BANK_ACCOUNT: "/api/stripe/tutor/create_bank_account",
  API_REQUEST_SKILLS: "/api/request_skills",
  API_ADMIN_STATS: "/admin/stats",
  API_SCHOOLS: "/api/schools",
  API_SCHOOL_TYPES: "/api/school_types",
  API_TRANSPORTS: "/api/transports",
  API_CHARACTERISTICS: "/api/characteristics",
  API_REPORT: "/api/customers/report",

  /***** FILTERS *****/
  API_FILTER_LEVEL: 1,
  API_FILTER_HOBBY: 1,

  /***** API RETURN STATE *****/
  SUCCESS: true,
  ERROR: false,

  /***** TUTOR'S FILE TYPES *****/
  FILES: {
    AVATAR: { fileType: "avatar", defaultFileName: "Avatar" },
    SCHOOL_PROOF: { fileType: "schoolProof", defaultFileName: "Certificat de scolarité" },
    BUSINESS_PROOF: { fileType: "businessProof", defaultFileName: "Preuve d'auto-entreprise" },
    ID: { fileType: "identityProof", defaultFileName: "Pièce d'identité" },
    BAC_MARKS: { fileType: "bacMarks", defaultFileName: "Relevé du bac" },
  },

}

