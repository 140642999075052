import axios from "axios";
import { store } from "../_helpers/store.js";
import { userService } from ".";
import Swal from "sweetalert2";
import { appConstants } from "_constants/app.constants.js";
import { userActions } from "_actions/user.actions.js";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
//request interceptor to add the auth token header to requests
api.interceptors.request.use(
  function (config) {
    // Edit response config
    //on regarde depuis combien de temps le user et le token existe
    var storeData = store.getState();

    if (storeData.authentication.hasOwnProperty("user")) {
      //on regarde si on a un user d'enregistré
      if (storeData.authentication.user.hasOwnProperty("date_of_token")) {
        //on regarde si on a une date pour le token
        var now = new Date().getTime();
        var start = storeData.authentication.user.date_of_token;
        var time = now - start;
        if (time > 3500000) {
          //3500000
          //la durée de vie du token est bientot épuisé ou épuisé on va le refresh
          const tempInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
          });
          return tempInstance
            .get(
              "/token/refresh?refresh_token=" +
              storeData.authentication.user.refreshToken,
              {},
              {
                headers: {
                  Authorization: null,
                },
              }
            )
            .then((resp) => {
              var user = storeData.authentication.user;
              user.refreshToken = resp.data.refresh_token;
              user.token = resp.data.token;
              user.date_of_token = now;
              store.dispatch({ type: "UPDATE_USER", user: user });
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + resp.data.token;
              config.headers.Authorization = "Bearer " + resp.data.token;
              return config;
            })
            .catch((error) => {
              if (error?.message === "Network Error") {
                return config;
              }else{
                userService.logout();
              }

              return config;
            });
          //si refresh pas possible alors on dit a l'user que la session est expirée
          //si refresh posssible on enregistre et on continue
        } else {
          // la durée du vie du token est encore bonne
          var AUTH_HEADER = "Bearer " + storeData.authentication.user.token;

          config.headers.Authorization = AUTH_HEADER;
          return config;
        }
      } else {
        return config;
      }
    } else {
      return config;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    if (401 === error?.response?.status) {
      Swal.fire({
        icon: appConstants.SWAL_ICON_ERROR,
        title: appConstants.DEFAULT_SWAL_TITLE_SESSION_EXPIRED,
        text: appConstants.DEFAULT_SWAL_MESSAGE_SESSION_EXPIRED,
      }).then(() => {
        window.location = "/login";
      });
    }
    else if (403 === error?.response?.status) {
      store.dispatch(userActions.logout());
      !Swal.isVisible() && Swal.fire({
        icon: appConstants.SWAL_ICON_ERROR,
        title: appConstants.DEFAULT_SWAL_TITLE_ACCESS_DENIED,
        text: error.response.data.message || appConstants.DEFAULT_SWAL_MESSAGE_ACCESS_DENIED,
      }).then(() => {
        window.location = "/login";
      });
    }
    return Promise.reject(error);
  }
);

//response interceptor to refresh token on receiving token expired error

export default api;
