import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MenuWithButtons from "../../../Others/menus/menuWithButtons/menuWithButtons";
import ButtonMenu from "../../../Others/buttons/buttonMenu/buttonMenu";
import MainInfoTab from "./tabs/mainInfoTab";
import BillingTab from "./tabs/billingTab";
import RequestsTab from "./tabs/requestsTab";
import StudentsTab from "./tabs/studentsTab";
import SideMenu from "../components/sideMenu";
import Swal from "sweetalert2";

import { apiService } from "../../../../../_services/api.service";
import loading from "components/admin/Others/process/loading";
import globalSwal from "components/admin/Others/alert/globalSwal";

import { appConstants } from "../../../../../_constants/app.constants";
import { apiConstants } from "_constants";
import {confirmAlert} from "react-confirm-alert";
import {useHistory} from "react-router-dom";

function ProfileCustomer() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [customer, setCustomer] = useState(undefined);
    const [phoneFormat, setPhoneFormat] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const billingAddresses = customer?.billing?.address || customer?.addresses ? [...customer?.addresses] : undefined;
    let history = useHistory();

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setCustomer(await apiService.getCustomerById(window.location.pathname.match(/\d+/)[0]));
    }

    function onChange(value, label, valid) {
        switch (label) {
            case appConstants.FORM_LABEL_MAIL:
                setCustomer({ ...customer, email: value });
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                setCustomer({ ...customer, firstname: value });
                break;
            case appConstants.FORM_LABEL_LASTNAME:
                setCustomer({ ...customer, lastname: value });
                break;
            case appConstants.FORM_LABEL_CIVILITY:
                setCustomer({ ...customer, gender: value });
                break;
            case appConstants.FORM_LABEL_PHONE:
                setCustomer({ ...customer, phone: value });
                setPhoneFormat(valid);
                break;
            case appConstants.FORM_LABEL_BILLING_ADDRESS:
                setCustomer({ ...customer, billing: value });
                break;
            default:
                break;
        }
    }

    async function onSave(event) {
        event.preventDefault();
        setSubmitted(true);

        // Check if all data are provided.
        if (customer?.firstname && customer?.lastname && customer?.gender && customer?.phone && (typeof phoneFormat !== "undefined" ? phoneFormat : true)) {
            loading(true);

            // Patch customer :
            // Update customer's billing address URI, then update this linked URI address that just has been link to customer's billing address.
            await apiService.patchCustomerById(customer.id, customer.toFormUpdate())
                .then(async result => {
                    if (result) {
                        const formAddress = {
                            firstname: customer?.billing?.firstname,
                            lastname: customer?.billing?.lastname,
                        };
                        // Then patch new billing address selected.
                        await apiService.patchAddressById(customer.billing?.address?.id, formAddress).then(success => {
                            if (success) {
                                globalSwal({
                                    icon: appConstants.SWAL_ICON_SUCCESS,
                                    title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                                });
                            } else {
                                globalSwal({
                                    icon: appConstants.SWAL_ICON_ERROR,
                                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                                });
                            }
                        });

                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                })
            setSubmitted(false);
        } else {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                icon: appConstants.SWAL_ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    };

    function onSubmitAddress(newAddress) {
        let newAddresses = customer.addresses;
        newAddresses.push(newAddress);
        setCustomer({ ...customer, addresses: newAddresses });
    }


    async function handleRemoveCustomer() {
        loading(true);
        await apiService.deleteCustomerById(customer.id).then(response => {
            if (response === apiConstants.SUCCESS) {
                globalSwal({
                    icon: appConstants.SWAL_ICON_SUCCESS,
                    title: appConstants.DEFAULT_SWAL_TITLE_DELETE_SUCCESS,
                    text: appConstants.SWAL_MESSAGE_REDIRECTION_PROFILS,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                }).then(function () {
                    history.push('/admin-home/profils');
                });
            } else if (response?.status === 409) { // Display message error when delete is not available because of business conditions
                globalSwal({
                    icon: appConstants.SWAL_ICON_WARNING,
                    title: appConstants.DEFAULT_SWAL_TITLE_REMOVE_CUSTOMER_WARNING,
                    text: response?.message,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            } else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: appConstants.SWAL_MESSAGE_DELETE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        })
    }

    function onConfirmDelete() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div
                        className="custom-ui p-5"
                        style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
                    >
                        <h1
                            className="police-gilroy-font mb-4 text-center"
                            style={{ fontSize: 110 + "%" }}
                        >
                            Voulez-vous supprimer ce client ?<br/>
                            Attention cette action va terminer l'ensemble des demandes associées à ce client.
                        </h1>

                        <div className="d-flex justify-content-between">
                            <button
                                className="empty-box-font p-3 mr-3 custom-btn-green"
                                style={{
                                    color: "#1fb23b",
                                    borderColor: "#1fb23b",
                                    fontSize: 90 + "%",
                                    cursor: "pointer",
                                }}
                                onClick={async () => {
                                    onClose();
                                    await handleRemoveCustomer();
                                }}
                            >
                                CONFIRMER
                            </button>
                            <button
                                className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                                style={{
                                    color: "#ea1616",
                                    borderColor: "#ea1616",
                                    fontSize: 90 + "%",
                                    cursor: "pointer",
                                }}
                                onClick={onClose}
                            >
                                ANNULER
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    return (
        <div className="py-5 pl-4 h-100 d-flex">
            <SideMenu onSave={onSave} id={customer?.id} onConfirmDelete={onConfirmDelete} customer />

            <MenuWithButtons className="w-100 h-100 px-3 d-flex flex-column" style={{ overflow: "hidden" }}>
                <ButtonMenu className="mb-5 ml-3" onActive={<MainInfoTab customer={customer} onChange={onChange} submitted={submitted} onSubmitAddress={onSubmitAddress} />} >INFOS PRINCIPALES</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<BillingTab billingAddresses={billingAddresses} billingAddress={customer?.billing} onChange={onChange} submitted={submitted} />} >FACTURATION</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<RequestsTab requests={customer === apiConstants.ERROR ? apiConstants.ERROR : customer?.requests} customerId={customer === apiConstants.ERROR ? apiConstants.ERROR : customer?.id} />} >DEMANDE(S)</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<StudentsTab students={customer?.students} />} >ÉLÈVE(S)</ButtonMenu>
            </MenuWithButtons>

            <div className={"col px-0 h-100 pt-1 " + (appConstants.SCREEN_WIDTH_EXTRA_LARGE > windowWidth ? " pr-3" : "")}>
                <Button variant="" style={{ fontSize: "inherit" }} className={"oc-button-empty2"} onClick={() => { window.history.back(); }}>
                    RETOUR
                </Button>
            </div>

        </div>
    );
}

export default ProfileCustomer;
