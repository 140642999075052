import React, { useEffect, useState } from 'react';
import { makeStyles, Switch, Tooltip, withStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import { tooltipConstants } from '_constants';



const SwitchCustom = (colorTrue, colorFalse) => withStyles({
    switchBase: {
        color: colorFalse,
        '&$checked': {
            color: colorTrue,
        },
        '&$checked + $track': {
            backgroundColor: colorTrue,
        },
        '&$disabled + $track': {
            backgroundColor: colorFalse,
        }
    },
    checked: {
        '&:hover': {
            backgroundColor: "rgba(74, 37, 170, 0.1) !important",
        }
    },
    track: {
        // Controls default (unchecked) color for the track
        opacity: 0.5,
        backgroundColor: colorFalse,
        "$checked$checked + &": {
            // Controls checked color for the track
            opacity: 0.5,
            backgroundColor: colorTrue,
        }
    }
})(Switch)

const useStyles = makeStyles(() => ({
    tooltip: {
        color: "black",
        backgroundColor: "#ffffff",
    },
}));

const DEFAULT_LEFT_ICON =
    <Tooltip title={tooltipConstants.REPORT_LABEL_HOME} placement="top" >
        <HomeIcon />
    </Tooltip>
const DEFAULT_RIGHT_ICON =
    <Tooltip title={tooltipConstants.REPORT_LABEL_ONLINE} placement="top" >
        <PersonalVideoIcon />
    </Tooltip>

export function ToggleFormGroupInput({ value: valueProps = false, label, className = "form-group ml-4 d-flex align-items-center", style = {}, labelClassname = "", switchClassName = "", leftIcon = DEFAULT_LEFT_ICON, rightIcon = DEFAULT_RIGHT_ICON, hideIcons = false, leftLabelStyle = { paddingTop: "6px", color: "var(--blue-tah-color)" }, rightLabelStyle = { paddingTop: "6px", color: "var(--purple-tah-color)" }, colors = { false: "var(--blue-tah-color)", true: "var(--purple-tah-color)" }, labelHidden = false, disabled: disabledProps = false, onChange: onChangeProps = () => { return }, disabledField = false}) {
    const [value, setValue] = useState(valueProps);
    const [disabled, setDisabled] = useState(disabledProps);
    const classes = useStyles();
    const [SwitchComponent, setSwitchComponent] = useState(SwitchCustom(colors.true, colors.false));

    useEffect(() => {
        setValue(valueProps);
    }, [valueProps]);

    useEffect(() => {
        setDisabled(disabledProps);
    }, [disabledProps]);

    useEffect(() => {
        setSwitchComponent(SwitchCustom(colors.true, colors.false))
    }, []);

    function onChange(event) {
        setValue(event.target.checked)
        onChangeProps(event.target.checked, label);
    }

    return (
        <div className={className} style={style}>
            <label className={`text-input-font m-0 ${labelClassname}`} style={{ visibility: labelHidden ? 'hidden' : 'visible' }}>{label}</label>
            <div className={`d-flex ${switchClassName}`} >

                <label style={leftLabelStyle} hidden={hideIcons} >
                    {leftIcon}
                </label>
                <Tooltip title={disabled ? "Verouillé" : ""} placement="top" classes={classes} style={{ height: "fit-content" }}>
                    <div>

                        <SwitchComponent
                            checked={value}
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            value="active"
                            disabled={disabledField}
                            className={`${disabled ? "disabled" : ""}`}
                        />
                    </div>
                </Tooltip>
                <label style={rightLabelStyle} hidden={hideIcons}>
                    {rightIcon}
                </label>
            </div>
        </div>
    )
}
