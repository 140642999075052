
import { useEffect, useState } from "react";
import ButtonLink from "../../../../Others/buttons/buttonLink/buttonLink";

import { appConstants } from "../../../../../../_constants/app.constants";

function StudentsTab({ students }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);


    if (typeof students === "undefined") {
        //return (<LoaderSpinner />)
    }
    
    return (
        <div className="px-3 h-100 d-flex flex-column" style={{ overflow: "hidden auto" }}>
            <div
                className="row m-0 mb-4 police-gilroy-font"
                style={{
                    fontSize: windowWidth < 576 ? 4 + "vw" : 120 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-2 pl-0 mb-2">ID</div>
                <div className="col-4 pl-2 mb-2">ÉLÈVE</div>
                <div className="col pl-2 mb-2 text-center">CLASSE</div>
            </div>

            <div className="row w-100 m-0 mb-0 d-block text-input-font" style={{ overflow: "hidden auto" }}>
                {students?.length > 0 ? (
                    students.map((elt, key) => (
                        <div
                            className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                            key={key}
                        >
                            <div className="col-2 pl-0">{elt?.id}</div>

                            <div className="col-4 pl-2 pr-5">
                                <ButtonLink className="btn w-100 oc-button-empty-blue px-3 py-2" link={"/admin-home/profils/students/" + elt?.id} firstname={elt?.firstname} lastname={elt?.lastname} />


                            </div>

                            <div className="col pl-2 text-center">{elt?.class}</div>
                        </div>
                    ))
                ) : (
                    <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
                )}
            </div>
            <div className="px-0 mb-2">
                <ButtonLink className="btn oc-button-empty-blue px-3 py-2" link={"/create-student-account/" + window.location.pathname.match(/\d+/)[0]} >+</ButtonLink>
            </div>
        </div>
    )
}

StudentsTab.propTypes = {
    
}

export default StudentsTab;
