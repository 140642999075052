import React from 'react';
import PropTypes from 'prop-types';

import CandidatesArray from '../components/candidatesArray';
import SubNavDropdown from 'components/admin/Others/subNavDropdown/subNavDropdown';

class CandidatesTab extends React.Component {
    state = {
        candidateList: this.props.candidateList,
        dislikes: this.props.dislikes,
        displayPostulant: false,
        displayRejected: false,
    };

    render() {
        const { candidateList, dislikes, displayRejected, displayPostulant, displayDislikes } = this.state;

        return (
            <div className="col-10 w-100 px-0 h-100 d-flex flex-column pl-3" style={{ flexShrink: 1 }}>
                <SubNavDropdown className="mb-3" title="INTERRESSÉ(E)S" onClick={() => this.setState({ displayRejected: false, displayPostulant: !displayPostulant, displayDislikes: false })} active={displayPostulant} >
                    <CandidatesArray list={candidateList} onSubmit={this.props.onSubmit} publication={this.props.publication} applicant />
                </SubNavDropdown>

                <SubNavDropdown className={displayPostulant ? "mb-0" : "mb-3"} title="REJETÉ(E)S" onClick={() => this.setState({ displayRejected: false, displayPostulant: false, displayDislikes: !displayDislikes })} active={displayDislikes} >
                    <CandidatesArray list={candidateList} onSubmit={this.props.onSubmit} publication={this.props.publication} />
                </SubNavDropdown>

                <SubNavDropdown className={displayRejected || displayPostulant ? "mb-0" : "mb-3"} title="PAS INTERRESSÉ(E)S" onClick={() => this.setState({ displayRejected: !displayRejected, displayPostulant: false, displayDislikes: false })} active={displayRejected} >
                    < CandidatesArray list={dislikes} onSubmit={this.props.onSubmit} publication={this.props.publication} />
                </SubNavDropdown>
            </div>
        )
    }
}

CandidatesTab.propTypes = {
    candidateList: PropTypes.array,
};

CandidatesTab.defaultProps = {
    candidateList: []
}

export default CandidatesTab;