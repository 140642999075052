import { extractJWTToken, history } from "../_helpers";
import Swal from "sweetalert2";
import axiosMain from "axios";
import { appConstants } from "../_constants/app.constants";
import { userConstants } from "../_constants";

const axios = axiosMain.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const userService = {
  login,
  logout,
};

function login(username, password) {
  return axios
    .post("/login_check", {
      email: username,
      password: password,
    })
    .then(function (response) {
      const user = response.data.message;
      const { role, date_of_token } = extractJWTToken(user);
      if (role !== userConstants.ROLE_ADMIN) {
        return Swal.fire({
          icon: appConstants.SWAL_ICON_ERROR,
          title: appConstants.SWAL_TITLE_TRY_CONNECTION_NO_ADMIN,
          text: appConstants.SWAL_TRY_CONNECTION_NO_ADMIN,
        })
      } else {
        user["date_of_token"] = date_of_token;
        localStorage.setItem("user", JSON.stringify(user));
        return user;
      }
    })
    .catch(function (error) {
      Swal.fire({
        icon: appConstants.SWAL_ICON_ERROR,
        title: appConstants.DEFAULT_SWAL_TITLE_LOGIN_ERROR,
        text: error.response.data.message || appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
      });
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("date_of_token");
}
