import { useEffect, useState } from "react";
import { apiService } from "_services/api.service";
import FormCustomer from "./customer/formCustomer";
import FormRequest from "./request/formRequest";
import FormStudent from "./student/formStudent";
import FormTutor from "./tutor/formTutor";


function FormsManager({ createType }) {
    const [customer, setCustomer] = useState(undefined);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const eventListener = () => {
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        init();
    }, [])

    async function init(){
        const urlId = window.location.pathname.match(/\d+/);
        if (!urlId) {
            return;
        }
        setCustomer(await apiService.getCustomerById(urlId[0]))
    }

    return (
        <div
            className={"px-5 h-100 d-flex flex-column police-gte-font" + (windowHeight < 800 ? " pb-4 pt-xl-4" : " pb-5 pt-xl-5")}
            style={{ color: "#282828", overflow: "hidden" }}
        >
            <div
                className={"w-100 text-nowrap police-gilroy-font underline-title-font" + (windowHeight < 800 ? " mb-4" : " mb-5")}
                style={{ fontSize: 40 + "px", backgroundColor: "#ffffff" }}
            >
                {"CRÉATION" +
                    (createType === "demand"
                        ? " D'UNE DEMANDE"
                        : createType === "tutor"
                            ? " COMPTE TUTEUR"
                            : createType === "consumer"
                                ? " COMPTE CLIENT"
                                : " ÉLÈVE - ")}
                {createType === "student" &&
                    customer?.firstname ? (
                    <span style={{ color: "var(--blue-tah-color)" }}>
                        {customer?.firstname +
                            " " +
                            customer?.lastname?.toUpperCase()}
                    </span>
                ) : null}
            </div>

            {createType === "demand" ?
                <FormRequest /> :
                createType === "tutor" ?
                    <FormTutor /> :
                    createType === "consumer" ?
                        <FormCustomer />
                        : createType === "student" ?
                            <FormStudent customerId={customer?.id} /> :
                            null
            }
        </div>
    )
}

export default FormsManager;
