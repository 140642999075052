import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import MainInfoTab from "./tabs/mainInfoTab";
import DetailsTab from "./tabs/detailsTab";
import CandidatesTab from "./tabs/candidatesTab";
import LessonsTab from "./tabs/lessonsTab";
import MenuWithButtons from "../../Others/menus/menuWithButtons/menuWithButtons";
import ButtonMenu from "../../Others/buttons/buttonMenu/buttonMenu";
import LoaderSpinner from "../../Others/loader/loader";
import MatchingTab from "./tabs/matchingTab";

import globalSwal from "../../../../components/admin/Others/alert/globalSwal";
import {
    dateDispatch,
    dateDispatchInput,
    dateGapColors,
    deleteUndefinedKeys,
    dateConvertor
} from "../../../../_helpers/data_formating";
import { apiService } from "../../../../_services/api.service";
import globaLoading from "../../../../components/admin/Others/process/loading";
import moment from "moment";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-datepicker/dist/react-datepicker.css";
import "sweetalert2/src/sweetalert2.scss";

import { apiConstants, appConstants, bddConstants } from "../../../../_constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {ToggleFormGroupInput} from "../../Others/forms/components/toggleFormGroupInput";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

function FormDemand() {
    const [request, setRequest] = useState(undefined);
    const [options, setOptions] = useState({});
    const [homePrices, setHomePrices] = useState(undefined);
    const [onlinePrices, setOnlinePrices] = useState(undefined);
    const [submitted, setSubmitted] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const {id: requestId} = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (!!!requestId) {
            return;
        }
        // Get request data from API
        setLoading(true);
        setRequest(await apiService.getRequestById(requestId));
        setHomePrices(await apiService.getPricesWithFilter("home"));
        setOnlinePrices(await apiService.getPricesWithFilter("online"));
        setOptions({
            subjects: await apiService.getSkills(apiConstants.API_FILTER_LEVEL),
        })
        setLoading(false);
    };

    async function handleSave(event) {
        event.preventDefault();
        setSubmitted(true);

        if (request?.date?.courseStart && request?.state && request?.nbrSession && request?.duration && request?.subjects?.length > 0) {

            globaLoading(true);
            const form = deleteUndefinedKeys({
                address: `${apiConstants.API_ADDRESSES}/${request?.address?.id}`,
                beginAt: dateDispatchInput(request?.date?.courseStart),
                endAt: dateDispatchInput(request?.date?.courseEnd),
                selectedTutor: dateDispatchInput(request?.date?.candidatSelected),
                published: dateDispatchInput(request?.date?.publication),
                notes: request?.notes,
                privateNotes: request?.privateNotes,
                preferences: request?.genderPref,
                lessonsPerWeek: parseInt(request?.nbrSession),
                lessonsDuration: request?.duration,
                price: request?.price ? `${apiConstants.API_PRICES}/${request?.price}` : null,
                onlinePrice: request?.onlinePrice ? `${apiConstants.API_PRICES}/${request?.onlinePrice}` : null,
                isGenderMandatory: request?.genderMandat,
                state: request?.state === bddConstants.REQUEST_AVAILABLE_DB ? bddConstants.REQUEST_ENABLED_DB : request?.state,
                homeworkRequired: request?.homeworkRequired,
                availability: request?.availabilities,
                notified: request?.notified,
                nextLessonFree: request?.nextLessonFree,
                allowedAdvancePayments: request?.allowedAdvancePayments,
            });

            await apiService.patchRequestById(request?.id, form).then(response => {
                if (response) {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_UPDATE_SUCCESS,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                } else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN
                    });
                }
            }).catch(error => {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    text: error?.response?.data?.violations[0]?.message || appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                });
            })
            setSubmitted(false);
        } else {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                icon: appConstants.SWAL_ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }
    };

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_AVAILABILITIES:
                setRequest({ ...request, availabilities: value });
                break;
            case appConstants.FORM_LABEL_DATE_CANDIDATE_SELECTED:
                setRequest({ ...request, date: { ...request?.date, candidatSelected: value } });
                break;
            case appConstants.FORM_LABEL_DATE_PUBLICATION:
                setRequest({ ...request, date: { ...request?.date, publication: value } });
                break;
            case appConstants.FORM_LABEL_DATE_COURSE_STARTS:
                setRequest({ ...request, date: { ...request?.date, courseStart: value } });
                break;
            case appConstants.FORM_LABEL_DATE_COURSE_ENDS:
                setRequest({ ...request, date: { ...request?.date, courseEnd: value } });
                break;
            case appConstants.FORM_LABEL_STATE:
                if (value === bddConstants.REQUEST_ENABLED_DB) {
                    setRequest({ ...request, state: value, date: { ...request?.date, publication: dateConvertor(moment().toISOString()) } });
                } else {
                    setRequest({ ...request, state: value });
                }
                break;
            case appConstants.FORM_LABEL_CODE_PROMO:
                setRequest({ ...request, promo: value });
                break;
            case appConstants.FORM_LABEL_ADDRESS:
                setRequest({ ...request, address: request?.customer?.addresses?.find(address => value == address.id) });
                break;
            case appConstants.FORM_LABEL_NBR_LESSONS:
                setRequest({ ...request, nbrSession: value });
                break;
            case appConstants.FORM_LABEL_LESSON_DURATION_OPTIONS:
                setRequest({ ...request, duration: value });
                break;
            case appConstants.FORM_LABEL_PRICE:
                setRequest({ ...request, price: value });
                break;
            case appConstants.FORM_LABEL_ONLINE_PRICE:
                setRequest({ ...request, onlinePrice: value });
                break;
            case appConstants.FORM_LABEL_SUBJECTS:
                setRequest({ ...request, subjects: value });
                break;
            case appConstants.FORM_LABEL_GENDER_PREFERENCE:
                setRequest({ ...request, genderPref: value });
                break;
            case appConstants.FORM_LABEL_GENDER_MANDAT:
                setRequest({ ...request, genderMandat: value });
                break;
            case appConstants.FORM_LABEL_NOTES:
                setRequest({ ...request, notes: value });
                break;
            case appConstants.FORM_LABEL_PRIVATE_NOTES:
                setRequest({ ...request, privateNotes: value });
                break;
            case appConstants.FORM_LABEL_HOMEWORK_REQUIRED:
                setRequest({ ...request, homeworkRequired: value });
                break;
            case appConstants.FORM_LABEL_OPTIONS:
                setRequest({ ...request, options: value });
                break;
            case appConstants.FORM_LABEL_NOTIFY_TUTOR:
                setRequest({ ...request, notified: value });
                break;
            case appConstants.FORM_LABEL_NEXT_LESSON_FREE:
                setRequest({ ...request, nextLessonFree: value });
                break;
            case appConstants.FORM_LABEL_HAVE_ADVANCE_PAYMENTS:
                setRequest({ ...request, allowedAdvancePayments: value });
                break;
            default:
                if (label?.includes(appConstants.FORM_LABEL_DATE_PUBLICATION) && label?.includes(appConstants.FORM_LABEL_DATE_CANDIDATE_SELECTED) && label?.includes(appConstants.FORM_LABEL_STATE)) {
                    setRequest({ ...request, date: { ...request?.date, publication: value.date, candidatSelected: value.date }, state: value.state });
                }
                break;
        }
    }

    if (!loading && !request) {
        return <div className="w-100 text-center mt-5">{appConstants.DEFAULT_NETWORK_ERROR}</div>
    }

    if (loading) {
        return (
            <LoaderSpinner />
        )
    }

    return (
        <div className="pt-5 pb-xl-5 pb-4 pl-5 h-100 d-flex">
            <div
                className="pl-0 pr-5 d-flex flex-column"
                style={{
                    fontSize: 75 + "%",
                    flex: "0 0 12.5%",
                    maxWidth: "12.5%" /* = col 1.5 */,
                }}
            >
                <div className="h-100 pr-1">
                    <p className="police-gilroy-font" style={{ fontSize: "140%" }}>
                        {"ID : " +
                            (request?.id
                                ? request?.id
                                : appConstants.DEFAULT_NO_ID)}
                    </p>
                    <div
                        className="py-1 text-center filled-box-font police-gilroy-font"
                        style={{ color: "#ffffff" }}
                    >
                        {request?.type
                            ? request?.type?.toUpperCase()
                            : appConstants.DEFAULT_NO_REQUEST_TYPE}
                    </div>
                    <div
                        className="py-1 mt-2 text-center police-gilroy-font"
                        style={{
                            border:
                                "solid " +
                                3 +
                                "px " +
                                dateGapColors(request, "border"),
                            backgroundColor:
                                typeof dateGapColors(request, "") !==
                                    "undefined"
                                    ? dateGapColors(request, "")[1]
                                    : "#ced4da",
                            color: "#ffffff",
                        }}
                    >
                        {typeof dateGapColors(request, "") !== "undefined"
                            ? "J " +
                            (Math.sign(
                                dateGapColors(request, "")[0] * -1
                            ) < 0
                                ? dateGapColors(request, "")[0] * -1
                                : "+ " + dateGapColors(request, "")[0] * -1)
                            : "Not found"}
                    </div>
                    <div
                        className="pt-1 mt-2 text-center filled-box-font police-gilroy-font"
                        style={{ color: "#ffffff" }}
                    >
                        {request
                            ? dateDispatch(request?.date?.courseStart)
                            : ""}
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <ToggleFormGroupInput label={appConstants.FORM_LABEL_NOTIFY_TUTOR} className={''} value={request.notified} onChange={onChange}
                                          disabledField={request.notified && request.notifiedAt !== null} rightToolTipTitle="On" leftToolTipTitle="Off"
                                          leftIcon={<CancelIcon style={{color: "var(--red-tah-color)"}} />}
                                          rightIcon={<CheckCircleIcon style={{color: "var(--green-tah-color)"}} />}
                                          colors={{false: "var(--red-tah-color)", true: "var(--green-tah-color)"}} />

                </div>
                <div className="d-flex">
                    <Button
                        variant=""
                        className="w-100 h-100 oc-button-empty-blue px-2"
                        style={{ fontSize: "80%" }}
                        onClick={handleSave}
                    >
                        SAUVEGARDER
                    </Button>
                </div>
            </div>

            <MenuWithButtons className="h-100 w-100 d-flex flex-column" style={{ overflow: "hidden" }}>
                <ButtonMenu className="ml-3 mb-5" onActive={
                    <MainInfoTab
                        onChange={onChange}
                        request={request}
                        submitted={submitted} />} >
                    INFOS PRINCIPALES
                </ButtonMenu>

                <ButtonMenu className="mb-5" onActive={
                    <DetailsTab
                        request={request}
                        onChange={onChange}
                        onlinePrices={onlinePrices}
                        homePrices={homePrices}
                        options={options}
                        submitted={submitted} />} >
                    DÉTAILS
                </ButtonMenu>

                <ButtonMenu className="mb-5" onActive={<CandidatesTab onSubmit={onChange} candidateList={request?.candidates} dislikes={request?.dislikes} publication={request?.date?.publication} />} >CANDIDATS</ButtonMenu>

                <ButtonMenu className="mb-5" onActive={<LessonsTab lessonsList={request?.lessons} />} >LEÇONS</ButtonMenu>
                <ButtonMenu className="mb-5" onActive={<MatchingTab matches={request?.matches} />} >MATCHING</ButtonMenu>

            </MenuWithButtons>
        </div>
    )
}

export default FormDemand;
