export const filesService = {
    downloadCSV,
}

function downloadCSV(csvString, filename) {
    const csvContent = "data:text/csv;charset=utf-8," + csvString
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    link.click();
}