import React, { useEffect, useState } from 'react';
import ButtonAction from 'components/admin/Others/buttons/buttonAction/buttonAction';

import { apiService } from '_services/api.service';
import loading from 'components/admin/Others/process/loading';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { buttonConstants, colorsConstants } from '_constants';

import GetAppIcon from '@material-ui/icons/GetApp';
import LoaderSpinner from 'components/admin/Others/loader/loader';

export default function MatchingTab({ matches }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {id: requestId} = useParams();

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    async function onDownloadCSV() {
        // Not need to await downloading.
        loading(true);
        await apiService.downloadMatchingCSV(requestId, `Matching_demande_${requestId}_${new Date().toISOString().split('T')[0]}`);
        loading(false);
    }

    if (typeof matches === "undefined") {
        return (
            <LoaderSpinner />
        )
    }

    return (
        <div className="w-100 h-100 m-0 pl-3" style={{ overflow: "auto" }}>
            <ButtonAction color={colorsConstants.LIGHT_BLUE} className="d-flex align-items-center mb-4" onClick={onDownloadCSV}>
                {buttonConstants.CSV}
                <GetAppIcon className="ml-1" fontSize="small" />
            </ButtonAction>
            <div
                className="col px-0 h-100"
                style={{ overflow: "hidden auto" }}
            >
                <div
                    className="row m-0 mb-4 sticky-top police-gilroy-font"
                    style={{
                        fontSize: windowWidth < 576 ? 4 + "vw" : 120 + "%",
                        borderBottom: "1px solid var(--purple-tah-color)",
                        backgroundColor: "#ffffff",
                        height: 35 + "px",
                        zIndex: 90,
                    }}
                >
                    <div className="col-2 pl-0 mb-2">NOM / PRÉNOM</div>
                    <div className="col-2 pl-0 pr-5 mb-2 text-center">N° TÉL.</div>
                    <div className="col-2 pl-0 pr-5 mb-2">MAIL</div>
                    <div className="col-2 pl-0 pr-5 mb-2 text-center">% MATCHING</div>
                    <div className="col-2 pl-0 pr-5 mb-2 text-center">TEMPS TRAJET</div>
                    <div className="col-2 pl-0 pr-5 mb-2 text-center">TRANSPORTS</div>

                </div>

                <div className="row w-100 m-0 mb-0">
                    {matches?.length > 0 ? (
                        matches?.map((obj, key) => (
                            <div
                                className="row w-100 m-0 mb-3 police-gte-font align-items-center" style={{height: "35px"}}
                                key={key}
                            >
                                <div className="col-2 pl-0">{obj.tutor.firstname} {obj.tutor.lastname}</div>
                                <div className="col-2 pl-0 pr-5 text-center">{obj.tutor.phone}</div>

                                <div className="col-2 pl-0 pr-5">
                                    {obj.tutor.email}
                                </div>
                                <div className="col-2 pl-0 pr-5 text-center">
                                    {obj.percentMatchSkills}
                                </div>
                                <div className="col-2 pl-0 pr-5 text-center">
                                {obj.transport.duration}mins
                                </div>
                                <div className="col-2 pl-0 pr-5 text-center">
                                    {obj.transport.name}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="w-100 text-center">NO DATA</div>
                    )}
                </div>
            </div>
        </div>
    )
}
