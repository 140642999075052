import React from 'react';
import NavbarEspace from './Navbar/Navbar';
import Gestion from './Body/gestion';

class GestionPage extends React.Component {

  render() {

    return (
      <div className="window-size d-flex flex-column">
        <div className="d-block">
          <NavbarEspace onglet="gestion"/>
        </div>
        <div className="container-fluid h-100 d-flex px-0" style={{maxHeight: 'calc(100vh - 80px)'}}>
          <div className="row h-100 w-100 mx-0">
            <div className="col px-0 mr-xl-5 h-100">
              <Gestion/>
            </div>
          </div>
        </div>
        </div>

    );
  }
}

export default GestionPage;
