import React, { useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';

import { withStyles } from '@material-ui/core/styles';
import { appConstants } from '../../../../../_constants';

const minValue = 1;

const StyledRating = withStyles({
    icon: {
        color: 'var(--tr-blue-tah-color)',
    },
    iconFilled: {
        color: '#ffb400',
    },
    iconHover: {
        color: '#ffb400',
    },
})(Rating);

export default function RatingStarsFormGroupInput({ value: valueProps = 0, label = "", className = "", style, onChange: onChangeProps = () => { return }, hideLabel = false, precision = 1, readOnly = false }) {
    const [value, setValue] = useState(valueProps);

    useEffect(() => {
        if (valueProps !== value) {
        }
        setValue(valueProps);
    }, [valueProps])

    function onChange(_event, value) {
        if (value < minValue) {
            onChangeProps(minValue, label);
            setValue(minValue);
        } else {
            onChangeProps(value, label);
            setValue(value);
        }
    }
    return (
        <div className={"form-group " + (className ? className : "mx-3")} style={style}>
            {!hideLabel ? <label className="d-flex w-100 text-input-font text-nowrap" >
                <span className="mr-2" >{label}</span>
            </label>
            :
            null}
            <>
                <Box className="form-group mb-0" component="fieldset" mb={3} borderColor="transparent">
                    <StyledRating
                        name={label}
                        defaultValue={1}
                        value={value}
                        onChange={onChange}
                        icon={<StarIcon />}
                        readOnly={readOnly}
                        precision={precision}
                    />
                </Box>
            </>
            <div className="invalid-feedback">{appConstants.DEFAULT_INVALID_FEEDBACK}</div>
        </div>

    );
}
