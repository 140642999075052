import React from "react"

import "./blurComponent.scss";

export default function BlurComponent({ children, active = false, className = "h-100" , style={ overflow: "auto" }} ) {
    return (
        <div className="h-100 w-100 blur-container" style={style}>
            <div className="blur-message" hidden={!active} />
            <div className={`${className} ${active ? "blur-page" : ""}`}>
                {children}
            </div>
        </div>
    )
}