import React, { useEffect, useRef, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

import { appConstants } from '../../../../../_constants';

function MultiSelectFormGroupInput({ value: valueProps, options, label, onChange = async () => { return }, className, style = {}, invalidFeedBackMessage = appConstants.DEFAULT_INVALID_FEEDBACK, hideLabel = false, disable, submitted }) {
    const ref = useRef(null);
    const [value, setValue] = useState(valueProps ? valueProps : []);

    useEffect(() => {
        setValue(valueProps ? valueProps : []);
    }, [valueProps]);

    async function handleChange() {
        const newValue = ref?.current?.state?.selectedValues;
        setValue(newValue);
        await onChange(newValue, label);
    }

    return (
        <div className={"form-group " + (className ? className : "w-100 mx-3")} style={style}>
            {!hideLabel ?
                <label className="text-input-font" htmlFor="students">{label}</label>
                :
                null
            }
            <div id="container-arrow" style={{ color: '#282828' }}>
                <Multiselect
                    ref={ref}
                    options={options}
                    selectedValues={value}
                    displayValue="label"
                    isObject
                    avoidHighlightFirstOption
                    placeholder=""
                    onSelect={handleChange}
                    onRemove={handleChange}
                    style={{
                        multiselectContainer: { backgroundColor: '#e6f0ff', opacity: submitted ? 1 : null, border: "solid 2px var(--purple-tah-color)", borderRadius: 0, height: 35 + 'px', maxWidth: 100 + '%',  },
                        searchBox: { border: "none", maxHeight: 100 + '%', paddingBottom: 0, overflow: 'scroll', display: "flex", alignContent: "center", }, chips: { backgroundColor: "var(--purple-tah-color)" }, inputField: { height: 21 + 'px', padding: 0, },
                    }}
                    disable={disable}
                />
                <div className={"invalid-feedback" + (submitted && value?.length < 1 ? "-custom" : "")}>{invalidFeedBackMessage}</div>
            </div>
        </div>
    )
}

MultiSelectFormGroupInput.defaultProps = {
    onChange: () => { return }
}

export default MultiSelectFormGroupInput;
