import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextFormGroupInput from 'components/admin/Others/forms/components/textFormGroupInput';
import SelectFormGroupInput from 'components/admin/Others/forms/components/selectFormGroupInput';
import ButtonLink from 'components/admin/Others/buttons/buttonLink/buttonLink';
import AddressFormGroupInput from 'components/admin/Others/forms/components/addressFormGroupInput';

import { appConstants } from '_constants';
import {ToggleFormGroupInput} from "../../../Others/forms/components/toggleFormGroupInput";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {apiService} from "../../../../../_services/api.service";

function MainInfoTab({ request: requestProps, onChange, submitted = false}) {
    const [request, setRequest] = useState(requestProps);
    const [windowWidth, setWindowWidth] = useState(window.innerHeight);
    const [averageScore, setAverageScore] = useState(null);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        if (requestProps !== request) {
            setRequest(requestProps)
        }
    }, [requestProps, requestProps?.address, request]);

    async function init() {
        if (!!!request)
            return;

        if (request?.tutor?.id)
            setAverageScore(await apiService.getTutorsAverageScore(request?.tutor?.id));
    }

    useEffect( () => {
        init();
    }, [requestProps, request]);

    return (
        <form className="row h-100 w-100 m-0 needs-validation" style={{overflow: "auto"}}>
            <div
                className="col-6 h-100 pl-0 pr-4 pl-3 d-flex flex-column police-gte-font"
                style={{
                    borderRight: "solid 1px var(--purple-tah-color)",
                    color: "#282828",
                    overflow: "hidden auto",
                }}
            >
                <div className="d-flex align-items-center mt-1" >
                    <p className="mb-0 px-0 mr-3 col-2 police-gilroy-font" style={{ fontSize: 120 + "%" }}>
                        CLIENT
                    </p>
                    <ButtonLink link={request?.customer?.id ? "/admin-home/profils/customers/" + request?.customer?.id : null} firstname={request?.customer?.firstname?.toUpperCase()} lastname={request?.customer?.lastname?.toUpperCase()} />
                </div>

                <div className="mt-2 d-flex justify-content-between w-100">
                    <TextFormGroupInput className="mt-1 mx-0 w-100 col-5 px-0" label={appConstants.FORM_LABEL_LASTNAME} value={request?.customer?.lastname} disabled />
                    <TextFormGroupInput className="mt-1 mx-0 w-100 col-5 px-0" label={appConstants.FORM_LABEL_FIRSTNAME} value={request?.customer?.firstname} disabled />
                </div>

                <div className="d-flex align-items-center mt-4">
                    <p className="mb-0 px-0 mr-3 col-2 police-gilroy-font" style={{ fontSize: 120 + "%" }}>
                        ÉLÈVE
                    </p>
                    <ButtonLink link={request?.student?.id ? "/admin-home/profils/students/" + request?.student?.id : null} firstname={request?.student?.firstname?.toUpperCase()} lastname={request?.student?.lastname?.toUpperCase()} />
                </div>

                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput className="mx-0 w-100 col-5 px-0" label={appConstants.FORM_LABEL_LASTNAME} value={request?.student?.lastname} disabled />
                    <TextFormGroupInput className="mx-0 w-100 col-5 px-0" label={appConstants.FORM_LABEL_FIRSTNAME} value={request?.student?.firstname} disabled />
                </div>

                <div className="d-flex align-items-center mb-3 mt-4">
                    <p className="mb-0 pl-0 col-2 police-gilroy-font" style={{ fontSize: 120 + "%" }}>
                        ADRESSE
                    </p>
                </div>
                <AddressFormGroupInput classname=" mb-2 d-flex w-100" address={request?.address} onChange={onChange} formOnly disabled />
                <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_ADDRESS} value={null} options={request?.customer?.addresses?.map(address => { return { value: address.id, label: address.stringify() } })} onChange={onChange} hideLabel />
            </div>

            <div className="col h-100 pl-4 pr-3 d-flex flex-column police-gte-font" style={{ color: "#282828", overflow: "hidden auto" }}>
                <div className="d-flex align-items-center">
                    <p className="mb-0 pl-0 col-2 police-gilroy-font" style={{ fontSize: 120 + "%" }}>
                        CHRONOLOGIE
                    </p>
                    <button // Used to align column horizontally
                        disabled
                        className="btn w-100 oc-button-empty-blue p-1"
                        style={{
                            visibility: "hidden",
                            fontSize:
                                windowWidth > 1200
                                    ? 1 + "vw"
                                    : windowWidth > 992
                                        ? 1 + "vw"
                                        : windowWidth >= 768
                                            ? 1.3 + "vw"
                                            : 2.2 + "vw",
                            borderColor: "#3c8bec",
                            borderWidth: "3px",
                        }}
                    >
                        <p
                            className="w-100 m-0 scrollbar-hidden"
                            style={{ overflow: "auto hidden" }}
                        >
                            HIDDEN
                        </p>
                    </button>
                </div>

                <div className="d-flex justify-content-between w-100">
                    <TextFormGroupInput className="w-100 pr-1" label={appConstants.FORM_LABEL_DATE_CANDIDATE_SELECTED} value={request?.date?.candidatSelected} type="date" disabled />
                    <TextFormGroupInput className="w-100 pl-1" label={appConstants.FORM_LABEL_DATE_PUBLICATION} value={request?.date?.publication} type="date" disabled />
                </div>

                <div className="my-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput className="w-100 pr-1 mb-0" label={appConstants.FORM_LABEL_DATE_COURSE_STARTS} value={request?.date?.courseStart} type="date" onChange={onChange} submitted={submitted} />
                    <TextFormGroupInput className="w-100 pl-1 mb-0" label={appConstants.FORM_LABEL_DATE_COURSE_ENDS} value={request?.date?.courseEnd} type="date" onChange={onChange} />
                </div>

                <div className={"row"}>
                    <div className="col-12 col-md-12">
                        <p className="mb-0 pl-0 police-gilroy-font mb-3 mt-4" style={{ fontSize: 120 + "%" }}>
                            PROPRIÉTÉS
                        </p>
                        <div className="w-100">
                            <SelectFormGroupInput className="mx-0 w-50 pr-1 w-100" label={appConstants.FORM_LABEL_STATE} value={request?.state} options={appConstants.DEFAULT_REQUEST_STATE_OPTIONS} onChange={onChange} submitted={submitted} />
                            <SelectFormGroupInput className="mx-0 w-50 pr-1 w-100" label={appConstants.FORM_LABEL_HOMEWORK_REQUIRED} value={request?.homeworkRequired} options={appConstants.DEFAULT_TRUE_FALSE_OPTIONS} onChange={onChange} submitted={submitted} />
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p className="mb-0 pl-0 police-gilroy-font mb-3 mt-4" style={{ fontSize: 120 + "%" }}>
                            FACTURATION
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ToggleFormGroupInput label={appConstants.FORM_LABEL_NEXT_LESSON_FREE} className={''} value={request.nextLessonFree} onChange={onChange}
                                                      rightToolTipTitle="Off" leftToolTipTitle="Off" hideIcons={true} disabledField={ ((new Date().getDay() === 0 && new Date().getHours() >= 20) || new Date().getDay() === 1 || new Date().getDay() === 2)}
                                                      colors={{false: "lightgrey", true: "var(--drk-blue-tah-color)"}} />
                            </div>
                            <div className="col-12 col-md-6">
                                <ToggleFormGroupInput label={appConstants.FORM_LABEL_HAVE_ADVANCE_PAYMENTS} className={''} value={request.allowedAdvancePayments} /*onChange={onChange}*/
                                                      rightToolTipTitle="Off" leftToolTipTitle="Off" hideIcons={true} disabledField={true}
                                                      colors={{false: "lightgrey", true: "var(--drk-blue-tah-color)"}} />
                                {averageScore > 0 && averageScore < 4 ? <p className="text-danger">Note moyenne de {averageScore} / 5.</p> : (averageScore === 0 ? <p className="text-warning">Aucune note pour le moment.</p> : <p className="text-success">Note moyenne de {averageScore || ""} / 5.</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

MainInfoTab.propTypes = {
    student: PropTypes.any,
    sessionData: PropTypes.any,
    customer: PropTypes.any,
    addressList: PropTypes.any,
    submitted: PropTypes.bool,
    onChange: PropTypes.func
};

MainInfoTab.defaultProps = {
    onChange: () => { return },
}

export default MainInfoTab;
