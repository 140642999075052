import LoaderSpinner from "components/admin/Others/loader/loader";
import BlurComponent from "components/admin/Others/navigation/blurComponent";
import React, { useEffect, useState } from "react";
import RatingStarsFormGroupInput from "../../../../../../components/admin/Others/forms/components/ratingStarsFormGroupInput";
import { appConstants } from "../../../../../../_constants";

export default function FeedbackTab({ feedbacks, averageGrade = 0.0 }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    function onClick(event) {

    }

    if (!Array.isArray(feedbacks) ) {
        return <LoaderSpinner />
    }
    return (
        <BlurComponent className="px-3 h-100 d-flex flex-column" active={feedbacks.length < 1} style={{ overflow: "hidden auto" }}>
            {/** Header of component */}
            <div className="d-flex" >
                <div className="police-gilroy-font mb-3 mr-4" style={{
                    fontSize: windowWidth < appConstants.SCREEN_WIDTH_SMALL ? 4 + "vw" : 120 + "%",
                    backgroundColor: "#ffffff",
                }}>
                    MOYENNE :
                </div>
                <RatingStarsFormGroupInput className="d-flex align-items-center mr-4" value={averageGrade} precision={0.1} hideLabel readOnly />

                <div className="text-input-font" >({averageGrade})</div>
            </div>

            {/** Body of component */}
            <div
                className="row w-100 m-0 mb-0 police-gilroy-font"
                style={{
                    fontSize: windowWidth < appConstants.SCREEN_WIDTH_SMALL ? 4 + "vw" : 120 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-2 pl-0 mb-2">DATE</div>
                <div className="col-4 pl-2 mb-2">COMMENTAIRE</div>
                <div className="col-4 pl-3 mb-2">NOTE</div>
            </div>

            <div className="d-flex flex-column h-100" style={{ overflow: "hidden auto" }}>
                {feedbacks?.map((feedback, index) =>
                    <div className="card tah-card" key={index}>
                        <div className="card-body row align-items-center" onClick={onClick}>
                            <div className="col-2 px-0">
                                {feedback.date}
                            </div>
                            <div className="col-4 px-0">
                                <span className="box-borderless police-gilroy-font">{feedback.comment}</span>
                            </div>
                            <div className="col-4">
                                <RatingStarsFormGroupInput className="d-flex align-items-center mb-0" value={feedback.score} hideLabel readOnly />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </BlurComponent>

    )
}
