import OptionModel from "_models/option.model";

export const requestConstants = {
    ENABLED: "enabled",
    LOCATION: {
        UNKNOWN: "Inconnu",
        HOME: (zipcode) => `${zipcode}`,
        ONLINE: "À distance",
        HOME_ONLINE: (zipcode) => `À distance | ${zipcode}`,
    },
    TYPE: {
        HOME: 0,
        ONLINE: 1,
        BOTH: 2
    },
    NBR_LESSONS_OPTIONS: [1, 2, 3, 4, 5].map(nbr => new OptionModel(nbr, nbr)),
};
