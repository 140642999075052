import DEFAULT_PROFILE_PICTURE from "images/default-profil-picture.jpeg";

export const appConstants = {
    /***** FILTERS *****/
    LEVEL_FILTER: "level_filter",
    SUBJECT_FILTER: "subject_filter",
    FILTER_VALUE_ALL: "",
    FILTER_VALUE_HOME: "home",
    FILTER_VALUE_ONLINE: "online",
    /***** *****/

    /***** SWAL *****/
    // ICONS
    SWAL_ICON_ERROR: "error",
    SWAL_ICON_SUCCESS: "success",
    SWAL_ICON_WARNING: "warning",
    SWAL_ICON_INFO: "info",

    // TITLES
    DEFAULT_SWAL_TITLE_SUCCESS: "C'est fait !",
    DEFAULT_SWAL_TITLE_ERROR: "Une erreur est survenue.",
    DEFAULT_SWAL_TITLE_ACCESS_DENIED: "Accès refusé",
    DEFAULT_SWAL_TITLE_LOGIN_ERROR: "Erreur de connexion.",
    DEFAULT_SWAL_TITLE_EMPTY_FIELDS: "Veuillez remplir tout les champs.",
    SWAL_TITLE_TRY_CONNECTION_NO_ADMIN: "Votre compte n'est pas admin !",
    DEFAULT_SWAL_TITLE_DELETE_SUCCESS: "Suppression réussie !",
    DEFAULT_SWAL_TITLE_UPDATE_SUCCESS: "Mise à jour réussie !",
    DEFAULT_SWAL_TITLE_POST_SUCCESS: "Création réussie !",
    DEFAULT_SWAL_TITLE_SUBJECT_ALREADY_ADDED: "Cette matière a déjà été ajoutée.",
    DEFAULT_SWAL_TITLE_SESSION_EXPIRED: "Session expirée",
    DEFAULT_SWAL_TITLE_REMOVE_STUDENT_WARNING: "Suppression impossible",
    DEFAULT_SWAL_TITLE_REMOVE_TUTOR_WARNING: "Suppression impossible",
    DEFAULT_SWAL_TITLE_REMOVE_CUSTOMER_WARNING: "Suppression impossible",

    // MESSAGES
    DEFAULT_SWAL_MESSAGE_ERROR: "Veuillez réessayer plus tard ou nous contacter via : eddmon@eddmon.fr",
    SWAL_COURSE_DECLARATION_SUCCESS: "Votre déclaration a bien été prise en compte.",
    SWAL_STRIPE_CARD_ADDED_SUCCESS: "La carte bancaire a bien été ajoutée.",
    SWAL_TRY_CONNECTION_NO_ADMIN: "Vous devez vous connecter sur : https://app.tuteuralheure.fr/",
    SWAL_MESSAGE_DELETE_ERROR: "La suppression a échouée, veuillez réessayer plus tard.",
    SWAL_MESSAGE_REDIRECTION_PROFILS: "Vous allez être redirigé(e) vers l'onglet Profils.",
    SWAL_MESSAGE_REDIRECTION_GESTION: "Vous allez être redirigé(e) vers l'onglet Gestion.",
    SWAL_MESSAGE_REDIRECTION_DASHBOARD: "Vous allez être redirigé(e) vers l'onglet Tableau de bord.",
    DEFAULT_SWAL_MESSAGE_ADD_ADDRESS_SUCCESS: "L'adresse a bien été ajoutée.",
    DEFAULT_SWAL_MESSAGE_POST_ERROR: "L'ajout a échoué. Veuillez réessayer.",
    DEFAULT_SWAL_MESSAGE_POST_APPLICATION_WARNING: "La demande a bien été créée mais l'ajout de la candidature a échoué.",
    DEFAULT_SWAL_MESSAGE_ACCESS_DENIED: "Accès refusé. Vous allez être redirigé vers la page de connexion.",
    DEFAULT_SWAL_MESSAGE_SESSION_EXPIRED: "Votre session est expirée, merci de vous reconnecter.",
    SWAL_MESSAGE_COURSE_DECLARATION_SUCCESS: "Votre déclaration a bien été prise en compte.",

    // BUTTONS'S TEXT
    DEFAULT_SWAL_TEXT_BTN: "OK",

    /***** *****/

    /***** FORMS *****/
    // LABELS
    FORM_LABEL_ID: "ID",
    FORM_LABEL_COURSE_ID: "ID du cours",
    FORM_LABEL_FIRSTNAME: "Prénom",
    FORM_LABEL_LASTNAME: "Nom",
    FORM_LABEL_NAME: "Nom",
    FORM_LABEL_CIVILITY: "Civilité",
    FORM_LABEL_ADDRESS_NUMBER: "N°",
    FORM_LABEL_ADDRESS_STREET: "Avenue, rue, etc.",
    FORM_LABEL_ADDRESS_CITY: "Ville",
    FORM_LABEL_ADDRESS_POSTCODE: "Code Post.",
    FORM_LABEL_PHONE: "Téléphone",
    FORM_LABEL_CREATION_DATE: "Date de création",
    FORM_LABEL_MAIL: "Email",
    FORM_LABEL_SIRET: "SIRET",
    FORM_LABEL_FILE_BAC: "Relevé du Baccalauréat",
    FORM_LABEL_FILE_ID: "Pièce d'identité",
    FORM_LABEL_FILE_SCHOOL_PROOF: "Certificat de scolarité",
    FORM_LABEL_FILE_PROFILE_PICTURE: "Photo de profil",
    FORM_LABEL_FILE_SUP: "Justificatif de sup",
    FORM_LABEL_BILLING_ADDRESS: "Adresse de facturation",
    FORM_LABEL_ADDRESS: "Adresse",
    FORM_LABEL_CLASS: "Classe",
    FORM_LABEL_SCHOOL: "École",
    FORM_LABEL_BIRTHDATE: "Date de naissance",
    FORM_LABEL_PASSIONS: "Passion(s)",
    FORM_LABEL_NATIONALITIES: "Nationalité",
    FORM_LABEL_NOTES: "Notes",
    FORM_LABEL_PRIVATE_NOTES: "Notes privées",
    FORM_LABEL_SEARCH: "RECHERCHE",
    FORM_LABEL_LESSON_DURATION: "Durée (heure)",
    FORM_LABEL_LESSON_TYPE: "Status du cours",
    FORM_LABEL_SUBJECTS: "Matières",
    FORM_LABEL_SUBJECTS_TEACHED: "Matière(s) d'enseignement",
    FORM_LABEL_DATE: "Date",
    FORM_LABEL_STATE: "État",
    FORM_LABEL_DECLARATION_DATE: "Date de déclaration",
    FORM_LABEL_TUTOR_BILL: "Facture Tuteur",
    FORM_LABEL_CONSUMER_BILL: "Facture Client",
    FORM_LABEL_DATE_CANDIDATE_SELECTED: "Date sélection candidat",
    FORM_LABEL_DATE_PUBLICATION: "Date de publication",
    FORM_LABEL_DATE_COURSE_STARTS: "Date DÉBUT des cours",
    FORM_LABEL_DATE_COURSE_ENDS: "Date FIN des cours",
    FORM_LABEL_DATE_COURSE: "Date du cours",
    FORM_LABEL_CODE_PROMO: "Code promo",
    FORM_LABEL_NBR_LESSONS: "Nbr de séances / par semaine",
    FORM_LABEL_LESSON_DURATION_OPTIONS: "Durée séance (heures)",
    FORM_LABEL_PRICE: "Tarif",
    FORM_LABEL_ONLINE_PRICE: "Tarif (en ligne)",
    FORM_LABEL_STUDENT_LEVEL: "Niveau de l'élève",
    FORM_LABEL_GENDER_PREFERENCE: "Préférence du tuteur",
    FORM_LABEL_GENDER_MANDAT: "Mandat de genre",
    FORM_LABEL_AVAILABILITIES: "Disponibilités",
    FORM_LABEL_AVAILABILITY_DAY: "Jour",
    FORM_LABEL_AVAILABILITY_HOURS: "Heures",
    FORM_LABEL_CUSTOMER: "Client",
    FORM_LABEL_STUDENT: "Élève",
    FORM_LABEL_TUTOR_OPTIONAL: "Tuteur ( Facultatif )",
    FORM_LABEL_COMMENT: "Commentaires",
    FORM_LABEL_COURSE_DURATION: "Durée du cours",
    FORM_LABEL_COURSE_DURATION_2: "Durée de chaque cours",
    FORM_LABEL_COURSE_TYPE: "Type de cours",
    FORM_LABEL_HOMEWORK_REQUIRED: "Devoirs obligatoires",
    FORM_LABEL_UNDERSTANDING_RATING: "Compréhension",
    FORM_LABEL_SPEED_RATING: "Vitesse",
    FORM_LABEL_MOTIVATION_RATING: "Motivation",
    FORM_LABEL_COURSE_SUMMARY: "Résumé de la séance",
    FORM_LABEL_STARTING_DATE: "Date de début",
    FORM_LABEL_ENDING_DATE: "Date de fin",
    FORM_LABEL_MOBILITY: "Mobilités",
    FORM_LABEL_CHARACTERISTICS: "Caractéristiques",
    FORM_LABEL_OPTIONS: "Options",
    FORM_LABEL_TYPE: "Types",
    FORM_LABEL_COUNTRY: "Pays",
    FORM_LABEL_NOTIFY_TUTOR:"Notifier les tuteurs",
    FORM_LABEL_NEXT_LESSON_FREE: "Prochain cours offert",
    FORM_LABEL_HAVE_ADVANCE_PAYMENTS: "Paiement anticipé (dès le 1er cours)",

    // INPUTS OPTIONS
    EMPTY_OPTION: { value: "", label: "-" },
    WEEKDAYS_OPTIONS: [{ value: 1, label: "LUNDI" }, { value: 2, label: "MARDI" }, { value: 3, label: "MERCR" }, { value: 4, label: "JEUDI" }, { value: 5, label: "VEND" }, { value: 6, label: "SAMEDI" }, { value: 7, label: "DIMAN" }],
    LESSONS_HOURS_RANGE_OPTIONS: [{ value: 1, label: "7h-10h" }, { value: 2, label: "10h-13h" }, { value: 3, label: "13h-16h" }, { value: 4, label: "16h-19h" }, { value: 5, label: "19h-22h" }],
    DEFAULT_GENDER_OPTIONS: [{ value: "female", label: "Fille" }, { value: "male", label: "Garçon" }],
    DEFAULT_LESSONS_DURATION_OPTIONS: [{ value: "0.50", label: "0.50" }, { value: "0.75", label: "0.75" }, { value: "1.00", label: "1.00" }, { value: "1.50", label: "1.50" }, { value: "2.00", label: "2.00" }, { value: "2.50", label: "2.50" }, { value: "3.00", label: "3.00" }],
    DEFAULT_LESSON_PAYMENT_STATUS_OPTIONS: [{ value: true, label: "PAYÉ" }, { value: false, label: "EN ATTENTE DE PAIEMENT" }],
    DEFAULT_LESSON_TYPE_OPTIONS: [{ value: true, label: "En ligne", disabled: false }, { value: false, label: "Domicile", disabled: false }],
    DEFAULT_REQUEST_STATE_OPTIONS: [{ value: "available", label: "En cours", color: "rgba(31, 178, 59, 0.7)" }, { value: "enabled", label: "En recherche", color: "rgba(229, 174, 70, 0.7)" }, { value: "disabled", label: "Inactive", color: "rgba(40, 40, 40, 0.7)" }, { value: "finished", label: "Terminée", color: "rgba(222, 44, 44, 0.7)" }, { value: "pending_fees", label: "En attente de moyen de paiement", color: "" }],
    DEFAULT_GENDER_PREFERENCE_OPTIONS: [{ value: "none", label: "Aucune" }, { value: "female", label: "Madame" }, { value: "male", label: "Monsieur" }],
    DEFAULT_TRUE_FALSE_OPTIONS: [{ value: true, label: "Vrai" }, { value: false, label: "Faux" }],

    // FEEDBACK MESSAGES
    DEFAULT_INVALID_FEEDBACK: "Veuillez remplir ce champ.",
    DEFAULT_SELECT_INVALID_FEEDBACK: "Veuillez sélectionner une option",
    INVALID_FEEDBACK_SELECT_SKILLS_MIN_1: "Veuillez sélectionner au moins 1 matière.",
    INVALID_FEEDBACK_ADD_SKILLS_MIN_1: "Veuillez ajouter au moins 1 matière.",
    INVALID_FEEDBACK_PHONE_FORMAT_ERROR: "Format incorrect.",
    /***** *****/

    /***** DIMENSIONS *****/
    SCREEN_WIDTH_EXTRA_LARGE: 1200,
    SCREEN_WIDTH_LARGE: 992,
    SCREEN_WIDTH_MEDIUM: 768,
    SCREEN_WIDTH_SMALL: 576,
    /***** *****/

    /***** FILES *****/
    DEFAULT_FILE_NAMES: {
        id: "File_ID",
        bac: "File_releve_bac",
        schoolProof: "File_certificat_de_scolarité",
        avatar: "File_avatar",
    },
    DEFAULT_FILE_TYPES: {
        id: "Pièce d'identité",
        bac: "Notes au bac",
        schoolProof: "Preuve de scolarité",
        avatar: "Avatar",
    },
    FILES_TYPE_BY_ID: {
        aeStatus: { id: 9, title: "statut auto-entrepreneur" },
        nova: { id: 10, title: "statut NOVA" },
        chgmtDomain: { id: 11, title: "Changement de domaine" },
        id: { id: 12, title: "Pièce d'identité" },
        avatar: { id: 13, title: "Avatar" },
        bac: { id: 14, title: "Notes au bac" },
        schoolProof: { id: 15, title: "Preuve de scolarité" },
        aeProof: { id: 16, title: "Preuve d'auto-entreprise" },
    },
    /***** *****/


    /***** OTHERS *****/
    DEFAULT_NO_DATE: "--/--/----",
    DEFAULT_NO_DATA: "--",
    DEFAULT_NO_ID: "--",
    DEFAULT_NO_NAME: "?",
    DEFAULT_NO_FILE: "No file",
    DEFAULT_NO_SIRET: "-",
    DEFAULT_NO_ADDRESS: "Aucune addresse trouvée.",
    DEFAULT_NETWORK_ERROR: "Veuillez vérifiez votre connexion internet, vos cables ethernet, le modem et le router.",
    DEFAULT_DATA_NOT_FOUND: "Aucune donnée trouvée.",
    DEFAULT_API_ERROR: "Erreur lors du chargement des données",
    DEFAULT_PAGE_NOT_FOUND: "Page non trouvée.",
    DEFAULT_NO_REQUEST_TYPE: "Type inconnu",
    DEFAULT_DELETE_CONFIRM_MESSAGE: "Êtes vous sur de vouloir supprimer cette leçon ?",
    DEFAULT_COMMENT_REPORT: (firstname, lastname) => { return `Déclaré par ${firstname} ${lastname}.` },
    /***** *****/

    /***** DATABASE */
    DEFAULT_DB_DATE_NULL: "-0001-11-30T00:00:00+00:00",
    /***** *****/

    /***** FILES */
    DEFAULT_PROFILE_PICTURE: DEFAULT_PROFILE_PICTURE,

    /***** TIMEOUT *****/
    DEFAULT_TYPING_MAX_TIMEOUT: 500,
    HOURS_TO_DECLARE_COURSE: 48,
}

/***** COLORS *****/
export const colorsConstants = {
    PURPLE: "var(--purple-tah-color)",
    LIGHT_BLUE: "light-blue",
    BLACK: "black",
    GREEN: "green",
    RED: "red",
    HEX_LIGHT_BLUE: "var(--blue-tah-color)",
}

export const modalConstants = {
    ADVISOR_TITLE: "Mon conseiller",
    STUDENT_REPORT_TITLE: "Suivi des élèves",
    CONFIRM_SCHOOL_DELETE: "Supprimer cette école ?",
    COFIRM_TUTOR_DELETE: "Supprimer ce tuteur ?"
}

export const tooltipConstants = {
    DISABLED_NO_STUDENT_SELECTED: "Aucun élève sélectionné",
    REPORT_LABEL_HOME: "À domicile",
    REPORT_LABEL_ONLINE: "En ligne",
}

export const placeHolderConstants = {
    FILL_WITH_NUMBER: "Entre un chiffre",
    FILL_COMMENTARY: "Exemple : Mon fils a besoin d'un soutien ciblé en mathématiques, il bloque en ce moment sur les fonctions linéaires.",
    FILL_PRIVATE_NOTES: "Ces notes sont à usage internes et ne sont partagées ni aux clients ni aux tuteurs.",
    AVAILABILITIES: "Lundi soir à 18h, mercredi entre 14h et 18h et dimanche de 10h à 12h",
}
