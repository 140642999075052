/**
 * Returns the target page number
 * @param {number} mouv 
 * @param {number} currentPage 
 * @param {number} totalPage 
 * @returns 
 */
export function handlePage(mouv, currentPage, totalPage,){

    if (currentPage === 1) {
      mouv += 1;
    }
    if (currentPage === totalPage && mouv > 0) {
      mouv = 0;
    }else if (currentPage === totalPage && mouv === 0) {
      mouv = -1;
    }

    return (currentPage + mouv)
  }