import { useEffect, useState } from "react";
import ButtonLink from "../../../../Others/buttons/buttonLink/buttonLink";

import { appConstants } from "../../../../../../_constants/app.constants";
import LoaderSpinner from "../../../../Others/loader/loader";

function RequestsTab({ requests }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };
    
    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    if (typeof requests === "undefined") {
        return (<LoaderSpinner />)
    } else if (!requests) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }
    return (
        <div className="px-3 h-100 d-flex flex-column" style={{ overflow: "hidden auto" }}>
            <div
                className="row m-0 mb-4 police-gilroy-font"
                style={{
                    fontSize: windowWidth < appConstants.SCREEN_WIDTH_SMALL ? 4 + "vw" : 120 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-1 pl-0 mb-2">ID</div>
                <div className="col-3 pl-2 mb-2">DEMANDE</div>
                <div className="col-3 pl-3 mb-2">CLIENT</div>
                <div className="col-2 pl-2 mb-2 text-center text-nowrap">NBR. LEÇONS</div>
                <div className="col-2 pl-2 mb-2 text-center">ÉTAT</div>
            </div>

            <div className="row w-100 m-0 mb-0 d-block text-input-font" style={{ overflow: "hidden auto" }}>
                {requests?.length > 0 ? (
                    requests.map((elt, key) => (
                        <div
                            className="row w-100 m-0 mb-3 police-gte-font align-items-center"
                            key={key}
                        >
                            <div className="col-1 pl-0">{elt.id}</div>

                            <div className="col-3 pl-2 pr-3">
                                <ButtonLink link={`/admin-home/formulaire-demande/${elt.id}`} firstname={`${elt.customer?.user?.firstname[0]}.${elt.customer?.user?.lastname}`} lastname={`/ ${elt.student?.firstname[0]}.${elt.student?.lastname}`} />
                            </div>

                            <div className="col-3 pl-3 pr-4">
                                <ButtonLink link={elt.customer?.id ? "/admin-home/profils/customers/" + elt.customer?.id : ""} firstname={elt?.customer?.user?.firstname} lastname={elt?.customer?.user?.lastname} />
                            </div>

                            <div className="col-2 pl-2 text-center">{elt.lessons}</div>
                            <div className="col-2 pl-2 text-center">{elt.state}</div>
                            <div className="col-1 pl-2 pr-0 mb-2 d-flex flex-column align-items-end" style={{ color: "#3c8bec" }} >
                                <ButtonLink className="btn w-100 oc-button-empty-blue px-3 py-2 align-self-center scrollbar-hidden" link={"/admin-home/formulaire-demande/" + elt?.id}>i</ButtonLink>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
                )}
            </div>
        </div>
    );
}

export default RequestsTab;
