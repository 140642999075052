import React, { useEffect, useState } from "react";

import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GetAppIcon from "@material-ui/icons/GetApp";

import { appConstants, buttonConstants, modalConstants } from "../../../_constants";

import TextFormGroupInput from "../Others/forms/components/textFormGroupInput";
import ButtonAction from "../Others/buttons/buttonAction/buttonAction";
import SimpleModal from "./modal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { apiService } from "_services/api.service";

class Form {
    constructor(student, start, end) {
        this.student = student;
        this.start = start;
        this.end = end;
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }

    completed() {
        return Object.keys(this).every(key => this[key] != undefined)
    }

    toForm() {
        return {
            student_id: this.student,
            start: this.start,
            end: this.end,
        }
    }
}

export default function StudentReportModal({ open: openProps = false, onClose: onCloseProps = () => { return }, lessons: lessonsProps = [] }) {
    const [open, setOpen] = useState(openProps);
    const [form, setForm] = useState(new Form());
    const [datesAccepted, setDatesAccepted] = useState({ min: null, max: null });
    const { id: studentId } = useParams();

    useEffect(() => {
        setOpen(openProps)
    }, [openProps])

    useEffect(() => {
        if (form.student === undefined) {
            setForm(new Form().buildFromInstance({ ...form, student: studentId }))
        }
    }, [studentId])

    useEffect(() => {
        if (lessonsProps.length > 0) {
            let dates = lessonsProps.map(lesson => moment(lesson.created, "DD/MM/YYYY"))
            const max = new Date(Math.max.apply(null, dates))
            const min = new Date(Math.min.apply(null, dates))
            setDatesAccepted({ min: min?.toISOString().split('T')[0], max: max?.toISOString().split('T')[0] })
        }
    }, [lessonsProps])

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_STUDENT:
                setForm(new Form().buildFromInstance({ ...form, student: value }));
                break;
            case appConstants.FORM_LABEL_STARTING_DATE:
                setForm(new Form().buildFromInstance({ ...form, start: value }));

                break;
            case appConstants.FORM_LABEL_ENDING_DATE:
                setForm(new Form().buildFromInstance({ ...form, end: value }));

                break;

            default:
                break;
        }
    }

    function onSubmit() {
        if (form.completed()) {
            const fileName = `student_${studentId}_${form.start}_${form.end}`
            apiService.downloadStudentReport(form.toForm(),fileName);
        }
    }

    return (
        <SimpleModal
            open={open}
            onClose={onCloseProps}
        >
            <div className="form d-flex flex-column p-3 pb-4 form-container mb-3 mt-3 col-lg-6 col-md-8 col-sm-4 col-11 mx-auto w-100" style={{ maxHeight: "65vh", overflow: "hidden scroll" }}>
                <div className="d-flex justify-content-center">
                    <AssessmentOutlinedIcon />
                    <h4 className="text-center mb-4 mx-2">{modalConstants.STUDENT_REPORT_TITLE}</h4>
                    <AssessmentOutlinedIcon />
                </div>
                <div className="row w-100 m-0">
                    <div className="col-xl-6 col-12 px-0">
                        <div className="form-group d-flex flex-column" >
                            <TextFormGroupInput className="mx-3" id="date-start" min={datesAccepted.min} max={datesAccepted.max} type="date" label={appConstants.FORM_LABEL_STARTING_DATE} value={form.start} onChange={onChange} disabled={!form.student} />
                        </div>
                    </div>
                    <div className="col-xl-6 col-12 px-0">
                        <div className="form-group d-flex flex-column">
                            <TextFormGroupInput className="mx-3" id="date-end" min={form.start || datesAccepted.min} max={datesAccepted.max} type="date" label={appConstants.FORM_LABEL_ENDING_DATE} value={form.end} onChange={onChange} disabled={!form.student} />
                        </div>
                    </div>
                </div>

                <ButtonAction className="mx-2" onClick={onSubmit} disabled={!form.completed()} >
                    {buttonConstants.DOWNLOAD}
                    <GetAppIcon className="align-middle ml-1" fontSize="small" style={{ color: "color(--purple-tah-color)" }} />
                </ButtonAction>
            </div>
        </SimpleModal>
    )
}