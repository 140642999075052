import FormSection from "../../../../Others/forms/formSection";
import TextFormGroupInput from "../../../../Others/forms/components/textFormGroupInput";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../../_constants/app.constants";

import SelectFormGroupInput from "../../../../Others/forms/components/selectFormGroupInput";
import { useState } from "react";
import LoaderSpinner from "components/admin/Others/loader/loader";

function BillingTab({ billingAddresses, billingAddress, onChange: onChangeProps = () => { return }, submitted }) {
    const [formattedAddress, _setFormatedAddress] = useState(billingAddresses ? billingAddresses?.map(address => { return { value: address?.id, label: address?.stringify() } }) : []);
    const [billingAddressSelected, _setBillingAddressSelected] = useState(billingAddress);

    if (typeof billingAddresses === "undefined") {
        return (<LoaderSpinner />)
    } else if (!billingAddresses) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }

    function onChange(value, label) {
        let newAddress = billingAddressSelected;
        switch (label) {
            case appConstants.FORM_LABEL_LASTNAME:
                newAddress.lastname = value;
                break;
            case appConstants.FORM_LABEL_FIRSTNAME:
                newAddress.firstname = value;
                break;
            case appConstants.FORM_LABEL_BILLING_ADDRESS:
                newAddress.address = billingAddresses?.find(elt => elt?.id == value);
                break;
            default:
                break;
        }
        onChangeProps(newAddress, appConstants.FORM_LABEL_BILLING_ADDRESS);
    }

    return (
        <form style={{ overflow: "hidden auto" }}>

            <FormSection title="INFORMATIONS PERSONNELLES">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={billingAddress?.lastname} onChange={onChange} id={appConstants.BILLING_DATA_ID} />
                    <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={billingAddress?.firstname} onChange={onChange} id={appConstants.BILLING_DATA_ID} />
                </div>
            </FormSection>

            <FormSection title="ADRESSE DE FACTURATION">
                <SelectFormGroupInput className="mx-0 px-3" label={appConstants.FORM_LABEL_BILLING_ADDRESS} value={billingAddress?.address?.id} options={formattedAddress} onChange={onChange} submitted={submitted} hideLabel />
            </FormSection>
        </form>
    )
}

BillingTab.propTypes = {
    billingAddresses: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    onChange: PropTypes.func,
}

BillingTab.defaultProps = {
    onChange: () => { return }
}

export default BillingTab;