import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../../_components";
import DefaultHome from "../admin/defaultHomePage";
import TableauDeBordPage from "../admin/tableauDeBordPage";
import Outils from "../admin/outilsPage";
import Profils from "../admin/profilsPage";
import ProfilsTutorsPage from "../admin/profilsTutorsPage";
import Gestion from "../admin/gestionPage";
import FormDemandPage from "../admin/formDemandPage";
import LessonPage from "../admin/lessonPage";
import LoginPage from "../admin/Login/loginPage";
import ProfilsCustomersPage from "../admin/profilsCustomersPage";
import ProfilsStudentPage from "../admin/profilsStudentPage";
import CreateAccountPage from "../admin/createAccountPage";
import OutilsTabPage from "../admin/outilsTabPage";
import LoaderSpinner from "../admin/Others/loader/loader";
import CourseReportFormPage from "components/admin/courseReportFormPage";

import { extractJWTToken, store } from "_helpers";

import { bddConstants, userConstants } from '../../_constants'

import "./App.css";
import "../admin/Body/body.css";

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <LoaderSpinner absolute global />
                <div className="App-content">
                    <Switch>
                        <Route exact path="/login" component={LoginPage} />
                        <Route
                            path="/redirect_to_app"
                            component={() => {
                                window.location.href = "https://app.tuteuralheure.fr/";
                                return null;
                            }}
                        />
                        <PrivateRoute exact path="/" component={DefaultHome} />
                        <PrivateRoute exact path="/admin-home" component={DefaultHome} />
                        <PrivateRoute
                            exact
                            path="/admin-home/tableau-de-bord"
                            component={TableauDeBordPage}
                        />
                        <PrivateRoute exact path="/admin-home/outils" component={Outils} />
                        <PrivateRoute
                            exact
                            path="/admin-home/profils"
                            component={Profils}
                        />
                        <PrivateRoute
                            path="/admin-home/profils/tutors"
                            component={ProfilsTutorsPage}
                        />
                        <PrivateRoute
                            path="/admin-home/profils/customers"
                            component={ProfilsCustomersPage}
                        />
                        <PrivateRoute
                            path="/admin-home/profils/students/:id"
                            component={ProfilsStudentPage}
                        />
                        <PrivateRoute
                            exact
                            path="/admin-home/gestion"
                            component={Gestion}
                        />
                        <PrivateRoute
                            path="/admin-home/formulaire-demande/:id"
                            component={FormDemandPage}
                        />
                        <PrivateRoute
                            path="/admin-home/lesson"
                            component={LessonPage}
                        />
                        <PrivateRoute
                            path="/create-demand/:customerId?"
                            component={() => <CreateAccountPage createType="demand" />}
                        />
                        <PrivateRoute
                            exact
                            path="/create-tutor-account"
                            component={() => <CreateAccountPage createType="tutor" />}
                        />
                        <PrivateRoute
                            exact
                            path="/create-consumer-account"
                            component={() => <CreateAccountPage createType="consumer" />}
                        />
                        <PrivateRoute
                            path="/create-student-account"
                            component={() => <CreateAccountPage createType="student" />}
                        />
                        <PrivateRoute
                            path="/course-report-form"
                            component={() => <CourseReportFormPage/>}
                        />
                        {bddConstants.TAB_LIST.map(
                            (tabTitle, index) => {
                                return (
                                    <PrivateRoute
                                        path={`/admin-home/outils/${tabTitle}`}
                                        component={() => <OutilsTabPage tabName={tabTitle} />}
                                        key={index}
                                    />
                                )
                            }
                        )}
                        <Route>
                            <Default />
                        </Route>
                    </Switch>
                </div>
            </div>
        );
    }
}

function Default() {
    // Get user from Redux's store.
    var storeData = store.getState();
    var user = extractJWTToken(storeData.authentication.user);
    if (user?.role === userConstants.ROLE_ADMIN) {
        return (
            <Route
                render={(props) => (
                    <Redirect
                        to={{
                            pathname: "/admin-home/tableau-de-bord",
                            state: { from: props.location },
                        }}
                    />
                )}
            />
        );
    } else {
        return (
            <Route
                render={(props) => (
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                )}
            />
        );
    }
}

export default App;
