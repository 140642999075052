import { appConstants } from "_constants";

export function keyToLabel(key) {
    switch (key) {
        case "types":
            return appConstants.FORM_LABEL_TYPE;
        case "address":
            return appConstants.FORM_LABEL_ADDRESS;
        case "name":
            return appConstants.FORM_LABEL_NAME;
        case "country":
            return appConstants.FORM_LABEL_COUNTRY;
        default:
            return key;
    }
}

export function labelToKey(label) {
    switch (label) {
        case appConstants.FORM_LABEL_TYPE:
            return "types";
        case appConstants.FORM_LABEL_ADDRESS:
            return "address";
        case appConstants.FORM_LABEL_NAME:
            return "name";
        case appConstants.FORM_LABEL_COUNTRY:
            return "country";
        case appConstants.FORM_LABEL_ADDRESS_NUMBER:
            return "number";
        case appConstants.FORM_LABEL_ADDRESS_STREET:
            return "street";
        case appConstants.FORM_LABEL_ADDRESS_CITY:
            return "city";
        case appConstants.FORM_LABEL_ADDRESS_POSTCODE:
            return "zipcode";
        default:
            return label;
    }
}