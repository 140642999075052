import { optionsConstants } from "_constants/options.constants";

export function options(state = {}, action) {
    switch (action.type) {
        case optionsConstants.SET_OPTIONS:
            return {
                ...state.options,
                ...action.value,
            }

        default:
            return state;
    }
}
