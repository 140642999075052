import React, { useEffect, useState } from 'react'
import PageNavigator from 'components/admin/Others/pagination/components/pageNavigator';
import LoaderSpinner from 'components/admin/Others/loader/loader';

import globalSwal from 'components/admin/Others/alert/globalSwal';
import { apiService } from '_services/api.service'

import { appConstants } from '_constants';
import TableArray from './tableArray/tableArray';

function TableManager({ tableName }) {
    const [loading, setLoading] = useState(true);
    const [raws, setRaws] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageTotal, setPageTotal] = useState(0);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        const response = await apiService.getTableByName(tableName, 1);
        setRaws(response?.data);
        setColumns(response?.headField);
        setPageTotal(response?.pageTotal);
        setLoading(false);
    }

    async function handlePage(activePage) {
        setLoading(true);
        await apiService.getTableByName(tableName, activePage).then(response => {
            if (response) {
                setRaws(response?.data);
                setColumns(response?.headField);
                setPageTotal(response?.pageTotal);
            } else {
                globalSwal({
                    icon: appConstants.SWAL_ICON_ERROR,
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                });
            }
        })
        setLoading(false);
    }

    if (typeof raws == "undefined" || typeof columns == "undefined") {
        return <p className="text-center pt-5">{appConstants.DEFAULT_API_ERROR}</p>;
    }
    return (
        <div className="d-flex flex-column">
            <PageNavigator className="align-self-start" pageTotal={pageTotal} onChange={handlePage} />

            {loading ?
                <LoaderSpinner absolute />
                :
                <TableArray raws={raws} columns={columns} hideList={["onlineRequests"]} />
            }
        </div>
    )
}

export default TableManager;