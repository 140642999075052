import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import MultiSelectCheckboxesApi from "../Others/filterApi/MultiSelectCheckboxesApi";
import PageNavigator from "../Others/pagination/components/pageNavigator";
import ButtonLink from "../Others/buttons/buttonLink/buttonLink";
import LoaderSpinner from "../Others/loader/loader";

import { registerLocale } from "react-datepicker";
import { dateGapCourse } from "../../../_helpers/data_formating";
import fr from "date-fns/locale/fr";
import { apiService } from "../../../_services/api.service";

import { appConstants, urlConstants } from "../../../_constants";

import ReportProblemIcon from '@material-ui/icons/ReportProblem';

registerLocale("fr", fr);

class Gestion extends React.Component {
    state = {
        onglet: "demand",
        screenWidth: window.innerWidth,
        demandList: [],
        lessonsList: [],
        searchInput: "",
        demandPageActive: 1,
        lessonsPageActive: 1,
        demandPageTot: undefined,
        lessonsPageTot: undefined,
        statusSelectedList: [
            { value: "", label: "Tout" },
            { value: "1", label: "PAYÉ" },
            { value: "0", label: "NON PAYÉ" },
        ],
        statusList: [
            { value: "", label: "Tout" },
            { value: "1", label: "PAYÉ" },
            { value: "0", label: "NON PAYÉ" },
        ],
        stateSelectedList: [
            { value: "", label: "Tout" },
            ...appConstants.DEFAULT_REQUEST_STATE_OPTIONS
        ],
        stateList: [
            { value: "", label: "Tout" },
            ...appConstants.DEFAULT_REQUEST_STATE_OPTIONS
        ],
        timer: undefined,
    };

    componentWillMount = () => {
        window.addEventListener("resize", this.eventListener);

        this.setState({ loading: true },
            () => {
                Promise.all([this.updateRequests(1), this.updateLessons(1)])
                    .then(() => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        );
    };

    componentWillUnmount() {
        // remove event listener
        window.removeEventListener('resize', this.eventListener);
    }

    eventListener = () => {
        this.setState({ screenWidth: window.innerWidth });
    }

    handlePage = (activePage) => {
        let apiCallFunction, pageActiveVarName, stateFilter;

        switch (this.state.onglet) {
            case "demand":
                stateFilter = this.state.stateDemandsFilter;
                pageActiveVarName = "demandPageActive";
                apiCallFunction = this.updateRequests;
                break;
            case "lessons":
                stateFilter = this.state.stateLessonsFilter;
                pageActiveVarName = "lessonsPageActive";
                apiCallFunction = this.updateLessons;
                break;
            default:
                break;
        }

        // Get new data
        this.setState(
            {
                loading: true,
            },
            () => {
                apiCallFunction(activePage, "", stateFilter).then(() => {
                    this.setState({
                        loading: false,
                        [pageActiveVarName]: activePage,
                    });
                });
            }
        );
    };

    updateRequests = async (page, search, state) => {
        const { demandList, demandPageTot } = await apiService.getRequestsWithFilter(page, search, state);
        this.setState({
            demandList: demandList,
            demandPageTot: Math.ceil(demandPageTot),
            demandPageActive: page
        });
    }

    updateLessons = async (page, search, state) => {
        const { lessonsList, lessonsPageTot } = await apiService.getLessonsWithFilter(page, search, state);
        this.setState({
            lessonsList: lessonsList,
            lessonsPageTot: Math.ceil(lessonsPageTot),
            lessonsPageActive: page
        });
    }

    onChangeSearching = async (event) => {
        event.preventDefault();
        const newSearchInput = event.target.value
        const { timer } = this.state;
        let newTimer;

        this.setState({
            searchInput: newSearchInput,
        })

        switch (this.state.onglet) {
            case "demand":
                // Wait than the word has been written before searching (avoid 1 API call per character).
                clearTimeout(timer);
                newTimer = setTimeout(() => {
                    this.updateRequests(1, newSearchInput);
                }, timer ? appConstants.DEFAULT_TYPING_MAX_TIMEOUT : 0);
                this.setState({ timer: newTimer });
                break;
            case "lessons":
                // Wait than the word has been written before searching (avoid 1 API call per character).
                clearTimeout(timer);
                newTimer = setTimeout(() => {
                    this.updateLessons(1, newSearchInput);
                }, timer ? appConstants.DEFAULT_TYPING_MAX_TIMEOUT : 0);
                this.setState({ timer: newTimer });
                break;
            default:
                break;
        }
    }

    onChangeMultiSelect = (optionsSelectedList, optionsList) => {
        let apiFun, page, pageVarName, stateFilterVarName, propertyFilterName;

        if (optionsList === this.state.statusList) {
            pageVarName = "lessonsPageActive";
            apiFun = this.updateLessons;
            page = this.state.lessonsPageActive;
            stateFilterVarName = "stateLessonsFilter";
            propertyFilterName = "isPaidByCustomer";
        } else {
            pageVarName = "demandPageActive";
            apiFun = this.updateRequests;
            page = this.state.demandPageActive;
            stateFilterVarName = "stateDemandsFilter";
            propertyFilterName = "state[]";
        }

        // TODO : finish filter (actually works but not fully op)
        let filterString = String();
        if (optionsSelectedList?.length < optionsList?.length) {
            for (var i = 0; i < optionsSelectedList.length; i++) {
                if (optionsSelectedList[i].value) {
                    switch (optionsSelectedList[i].value) {
                        case "enabled":
                            if (optionsSelectedList.includes(this.state.stateList?.find((elt) => elt?.value === "available"))) {
                                filterString += `&${propertyFilterName}=${optionsSelectedList[i].value}`;
                            } else
                                filterString += `&${propertyFilterName}=enabled&exists[tutor]=0`;
                            break;
                        case "available":
                            if (optionsSelectedList.includes(this.state.stateList?.find((elt) => elt?.value === "enabled"))) {
                                filterString += `&${propertyFilterName}=enabled`;
                            } else
                                filterString += `&${propertyFilterName}=enabled&exists[tutor]=1`;
                            break;
                        default:
                            filterString += `&${propertyFilterName}=${optionsSelectedList[i].value}`;
                            break;
                    }
                }
            }
        }

        this.setState(
            {
                [stateFilterVarName]: filterString,
                [pageVarName]: 1,
            },
            () => {
                apiFun(page, "", filterString);
            }
        );
    };

    arrayData = (onglet) => {
        const { searchInput } = this.state;
        let page, pageTotal, optionsList, optionsSelectedList, dataArray;

        // Page handler for API call
        if (this.state.onglet === "lessons") {
            dataArray = this.state.lessonsList;
            page = this.state.lessonsPageActive;
            pageTotal = this.state.lessonsPageTot;
            optionsList = this.state.statusList;
            optionsSelectedList = this.state.statusSelectedList;
        } else {
            dataArray = this.state.demandList;
            page = this.state.demandPageActive;
            pageTotal = this.state.demandPageTot;
            optionsList = this.state.stateList;
            optionsSelectedList = this.state.stateSelectedList;
        }

        return (
            <div className="row w-100 h-100 m-0">
                <div className="col-12 px-0 h-100 d-flex flex-column">
                    <div className="form-group mb-4 d-flex align-self-end align-items-center">
                        <label className="m-0 mr-3 police-gilroy-font">RECHERCHE</label>
                        <input
                            type="text"
                            className="form-control empty-box-font"
                            style={{ boxShadow: "none" }}
                            value={searchInput}
                            onChange={this.onChangeSearching}
                        />
                    </div>

                    {onglet === "demand" ?
                        <div
                            className="row m-0 mb-4 police-gilroy-font"
                            style={{
                                fontSize: this.state.screenWidth < 576 ? 4 + "vw" : 120 + "%",
                                borderBottom: "1px solid var(--purple-tah-color)",
                                backgroundColor: "#ffffff",
                                height: 35 + "px",
                            }}
                        >
                            <div
                                className="mb-2"
                                style={{
                                    flex: "0 0 4.166666%",
                                    maxWidth: "4.166666%" /* = col .5 */,
                                }}
                            >
                                ID
                            </div>
                            <div className="col-2 pl-0 mb-2">CLIENT</div>
                            <div className="col-2 pl-2 mb-2">ÉLÈVE</div>
                            <form className="col-2 pl-4 mb-2 d-flex">
                                ÉTAT
                                <MultiSelectCheckboxesApi
                                    optionsList={optionsList}
                                    optionsSelectedList={optionsSelectedList}
                                    onChange={(filter) =>
                                        this.onChangeMultiSelect(filter, optionsList)
                                    }
                                />
                            </form>
                            <div className="col-1 pl-4 mb-2">CLASSE</div>
                            <div
                                className="px-3 mb-2 text-center"
                                style={{
                                    flex: "0 0 4.166666%",
                                    maxWidth: "4.166666%" /* = col .5 */,
                                }}
                            >
                                CAND.
                            </div>
                            <div
                                className="pl-5 pr-0 mb-2"
                                style={{ flex: "0 0 12.5%", maxWidth: "12.5%" /* = col 1.5 */ }}
                            >
                                FORMAT
                            </div>
                            <div className="col-1 mb-2">PUBLIÉE</div>
                            <div className="col-1 mb-2 text-nowrap">DÉBUT COURS</div>
                        </div>
                        :
                        <div
                            className="row m-0 mb-4 police-gilroy-font"
                            style={{
                                fontSize: this.state.screenWidth < 576 ? 4 + "vw" : 120 + "%",
                                borderBottom: "1px solid var(--purple-tah-color)",
                                backgroundColor: "#ffffff",
                                height: 35 + "px",
                            }}
                        >
                            <div
                                className="mb-2"
                                style={{
                                    flex: "0 0 4.166666%",
                                    maxWidth: "4.166666%" /* = col .5 */,
                                }}
                            >
                                ID
                            </div>
                            <div className="col-1 mb-2">DATE</div>
                            <div className="col-2 pl-4 mb-2">TUTEUR</div>
                            <div className="col-2 mb-2">CLIENT</div>
                            <div className="col-2 mb-2">ÉLÈVE</div>
                            <div
                                className="col mb-2"
                                style={{ flex: "0 0 12.5%", maxWidth: "10%" /* = col 1.2 */ }}
                            >
                                DURÉE
                            </div>
                            <div
                                className="col mb-2 d-flex"
                                style={{ flex: "0 0 12.5%", maxWidth: "10%" /* = col 1.2 */ }}
                            >
                                STATUT
                                <MultiSelectCheckboxesApi
                                    optionsList={optionsList}
                                    optionsSelectedList={optionsSelectedList}
                                    onChange={(filter) =>
                                        this.onChangeMultiSelect(filter, optionsList)
                                    }
                                />
                            </div>
                            <div className="col mb-2">FORMAT</div>
                        </div>
                    }

                    {onglet === "demand" ? (
                        <div className="row w-100 m-0 mb-0 h-100 d-block" style={{ overflow: "hidden auto" }}>
                            <div className="d-flex flex-column w-100 h-100 m-0 mb-0" style={{ overflow: "hidden auto" }}>
                                {this.state.loading ? (
                                    <LoaderSpinner />
                                ) : typeof dataArray !== "undefined" && dataArray?.length > 0 ? (
                                    dataArray?.map((elt, key) => {
                                        return (
                                            <div className={`row w-100 m-0 mb-3 police-gte-font align-items-center ${elt.sos ? "py-2" : ""}`} key={key} style={{ backgroundColor: elt.sos ? "var(--tr-blue-tah-color)" : null }} >
                                                <div
                                                    style={{
                                                        flex: "0 0 4.166666%",
                                                        maxWidth: "4.166666%" /* = col .5 */,
                                                    }}
                                                >
                                                    {elt?.id}
                                                </div>
                                                <div className="col-2 pl-0">
                                                    <ButtonLink link={elt?.consumer?.id ? "/admin-home/profils/customers/" + elt?.consumer?.id : null} firstname={elt?.consumer?.firstname} lastname={elt?.consumer?.lastname} />
                                                </div>

                                                <div className="col-2 pl-2">
                                                    <ButtonLink link={elt?.student?.id ? "/admin-home/profils/students/" + elt?.student?.id : null} firstname={elt?.student?.firstname} lastname={elt?.student?.lastname} />
                                                </div>

                                                <div className="col-2 pl-4">
                                                    <div
                                                        className="empty-box-font py-1 text-center"
                                                        style={{
                                                            color: "#ffffff",
                                                            fontSize: 90 + "%",
                                                            boxShadow: "none",
                                                            borderWidth: "2px",
                                                            backgroundColor: appConstants.DEFAULT_REQUEST_STATE_OPTIONS.find(state => state.label === elt.state)?.color || "rgba(192, 192, 192, 0.7)",
                                                            borderColor: appConstants.DEFAULT_REQUEST_STATE_OPTIONS.find(state => state.label === elt.state)?.color || "rgba(192, 192, 192, 1)",
                                                        }}
                                                    >
                                                        {elt?.state?.toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="col-1 pl-4">{elt?.level ? elt?.level : appConstants.DEFAULT_NO_DATA}</div>

                                                <div
                                                    className="pl-3 text-center"
                                                    style={{
                                                        flex: "0 0 4.166666%",
                                                        maxWidth: "4.166666%" /* = col .5 */,
                                                    }}
                                                >
                                                    {elt?.nbrCandidat}
                                                </div>

                                                <div
                                                    className="pl-5 pr-4"
                                                    style={{
                                                        flex: "0 0 12.5%",
                                                        maxWidth: "12.5%" /* = col 1.5 */,
                                                    }}
                                                >
                                                    <div
                                                        className="empty-box-font py-1 text-center"
                                                        style={{
                                                            color: "#ffffff",
                                                            boxShadow: "none",
                                                            fontSize: 90 + "%",
                                                            backgroundColor:
                                                                elt?.typeCourse === "En ligne"
                                                                    ? "var(--purple-tah-color)"
                                                                    : "var(--blue-tah-color)",
                                                            borderColor:
                                                                elt?.typeCourse === "En ligne"
                                                                    ? "var(--purple-tah-color)"
                                                                    : "var(--blue-tah-color)",
                                                        }}
                                                    >
                                                        {elt?.typeCourse?.toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="col-1">{elt?.publicationDate ? elt?.publicationDate : appConstants.DEFAULT_NO_DATE}</div>

                                                <div className="col-1">
                                                    <p
                                                        className={"m-0 py-2 text-center " + (elt?.startCourseDate ? "police-gilroy-font" : "")}
                                                        style={{
                                                            color: elt?.startCourseDate ? "#ffffff" : null,
                                                            fontSize: elt?.startCourseDate ? 80 + "%" : null,
                                                            backgroundColor: dateGapCourse(elt),
                                                            border: "solid 2px" + dateGapCourse(elt, "border"),
                                                        }}
                                                    >
                                                        {elt?.startCourseDate ? elt?.startCourseDate : appConstants.DEFAULT_NO_DATE}
                                                    </p>
                                                </div>

                                                <div
                                                    className="px-2 mb-0 d-flex flex-column align-items-end"
                                                    style={{
                                                        color: "#3c8bec",
                                                        flex: "0 0 4.166666%",
                                                        maxWidth: "4.166666%" /* = col .5 */,
                                                    }}
                                                >
                                                    {elt.sos?
                                                        <ButtonLink link={"/admin-home/formulaire-demande/" + elt?.id} className="sos-warning-btn btn oc-button-empty-blue px-3 py-2 align-self-center scrollbar-hidden" style={{width: "50px"}}>
                                                            <ReportProblemIcon className="text-center" style={{color: "var(--white-tah-color)"}}/>
                                                        </ButtonLink>
                                                    :
                                                    <ButtonLink link={"/admin-home/formulaire-demande/" + elt?.id}>i</ButtonLink>
                                                    }

                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="w-100 text-center">DATA NOT FOUND</div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="row w-100 m-0 mb-0 h-100 d-block" style={{ overflow: "hidden auto" }}>

                            <div className="w-100 h-100 m-0 mb-0" style={{ overflow: "hidden auto" }}>
                                {this.state.loading ? (
                                    <LoaderSpinner />
                                ) : typeof dataArray !== "undefined" && dataArray?.length > 0 ? (
                                    dataArray?.map((elt, key) => {
                                        return (
                                            <div className="row w-100 m-0 mb-3 police-gte-font align-items-center" key={key}>
                                                <div style={{ flex: "0 0 4.166666%", maxWidth: "4.166666%" /* = col .5 */, }}>
                                                    {elt?.id}
                                                </div>
                                                <div className="col-1">{elt?.publicationDate}</div>
                                                <div className="col-2 pl-4">
                                                    <ButtonLink link={elt?.tutor?.id ? "/admin-home/profils/tutors/" + elt?.tutor?.id : null} firstname={elt?.tutor?.firstname} lastname={elt?.tutor?.lastname} />
                                                </div>
                                                <div className="col-2 pl-3">
                                                    <ButtonLink link={elt?.consumer?.id ? "/admin-home/profils/customers/" + elt?.consumer?.id : null} firstname={elt?.consumer?.firstname} lastname={elt?.consumer?.lastname} />
                                                </div>
                                                <div className="col-2 pl-3">
                                                    <ButtonLink link={elt?.student?.id ? "/admin-home/profils/students/" + elt?.student?.id : null} firstname={elt?.student?.firstname} lastname={elt?.student?.lastname} />
                                                </div>
                                                <div className="col" style={{ flex: "0 0 12.5%", maxWidth: "10%" /* = col 1.2 */, }}>
                                                    {elt?.duration}
                                                </div>
                                                <div className="col" style={{ flex: "0 0 12.5%", maxWidth: "10%" /* = col 1.2 */, }}>
                                                    {elt?.status}
                                                </div>
                                                <div className="col" style={{ flex: "0 0 12.5%", maxWidth: "9%" /* = col 1.1 */, }}>
                                                    {elt?.type}
                                                </div>
                                                <div
                                                    className="col-1 pr-0 mb-2 d-flex flex-column align-items-end"
                                                    style={{ color: "#3c8bec" }}
                                                >
                                                    <ButtonLink link={elt?.id ? "/admin-home/lesson/" + elt?.id : null}>i</ButtonLink>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="w-100 text-center">DATA NOT FOUND</div>
                                )}
                            </div>
                        </div>
                    )}
                    <PageNavigator
                        defaultActivePage={page}
                        onChange={(activePage) => {
                            this.handlePage(activePage);
                        }}
                        pageTotal={pageTotal}
                    />
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="py-5 pl-5 h-100 d-flex flex-column">
                <div className="w-100 d-flex">
                    <div className="col-4 w-100 px-0">
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{
                                fontSize:
                                    this.state.screenWidth < 768 && this.state.screenWidth > 576
                                        ? 2 + "vw"
                                        : this.state.screenWidth < 576
                                            ? 2.5 + "vw"
                                            : "",
                            }}
                        >
                            <Button
                                variant=""
                                className={
                                    "mr-3 w-100 oc-button-empty px-sm-3 px-1 " +
                                    (this.state.onglet === "demand" ? "oc-button-clicked" : "")
                                }
                                style={{ fontSize: "inherit" }}
                                onClick={() => {
                                    this.updateRequests(1);
                                    this.setState({
                                        onglet: "demand",
                                        searchInput: ""
                                    });
                                }}
                            >
                                DEMANDES
                            </Button>
                            <Button
                                variant=""
                                className={
                                    "mr-3 w-100 oc-button-empty px-sm-3 px-1 " +
                                    (this.state.onglet === "lessons" ? "oc-button-clicked" : "")
                                }
                                style={{ fontSize: "inherit" }}
                                onClick={() => {
                                    this.updateLessons(1);
                                    this.setState({
                                        onglet: "lessons",
                                        searchInput: ""
                                    });
                                }}
                            >
                                LEÇONS
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="h-100" style={{ overflow: "hidden" }}>
                    {this.arrayData(this.state.onglet)}
                </div>


                <div>
                    <Link to={"/create-demand"}>
                        <Button
                            variant=""
                            className="mr-5 h-100 oc-button-empty-blue px-sm-3 px-1"
                            style={{ fontSize: "inherit" }}
                        >
                            CRÉER UNE DEMANDE
                        </Button>
                    </Link>
                    <Link to={urlConstants.COURSE_REPORT_FORM}>
                        <Button
                            variant=""
                            className="h-100 oc-button-empty-blue px-sm-3 px-1"
                            style={{ fontSize: "inherit" }}
                        >
                            DÉCLARER UN COURS
                        </Button>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Gestion;
