import { useEffect, useState } from "react";
import SideMenu from "./components/sideMenu";
import MenuWithButtons from "../../Others/menus/menuWithButtons/menuWithButtons";
import ButtonMenu from "../../Others/buttons/buttonMenu/buttonMenu";
import MainInfoTab from "./tabs/mainInfoTab";
import { apiConstants, appConstants, colorsConstants } from "../../../../_constants";
import { apiService } from "../../../../_services/api.service";
import ButtonAction from "../../Others/buttons/buttonAction/buttonAction";
import Swal from "sweetalert2";
import { checkMissingData } from "../../../../_helpers";

import loading from "../../Others/process/loading";
import globalSwal from "../../Others/alert/globalSwal";
import { Button } from "react-bootstrap";
import alert from "../../Others/alert/alert";
import { withRouter } from "react-router-dom";

function Lesson({ ...props }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [lesson, setLesson] = useState(undefined);
    const [subjects, setSubjects] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [pageNotFound, setPageNotFound] = useState(undefined);
    const [enableDelete, setEnableDelete] = useState(false);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (!window.location.pathname.match(/\d+/)) {
            setPageNotFound(true);
            return;
        } else {
            const lessonById = await apiService.getLessonById(window.location.pathname.match(/\d+/)[0]);
            setSubjects(await apiService.getSkills(apiConstants.API_FILTER_LEVEL));
            setLesson(lessonById);
            setEnableDelete(!lessonById?.isPaidByCustomer);
            setPageNotFound(lessonById ? false : true);
        }
    }


    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_LESSON_DURATION:
                setLesson({ ...lesson, ["duration"]: value });
                break;
            case appConstants.FORM_LABEL_SUBJECTS:
                setLesson({ ...lesson, ["skills"]: value });
                break;
            case appConstants.FORM_LABEL_LESSON_TYPE:
                setLesson({ ...lesson, ["isOnline"]: value });
                break;
            case appConstants.FORM_LABEL_DATE:
                setLesson({ ...lesson, ["date"]: value });
                break;
            case appConstants.FORM_LABEL_STATE:
                setLesson({ ...lesson, ["isPaidByCustomer"]: value });
                break;
            case appConstants.FORM_LABEL_DECLARATION_DATE:
                setLesson({ ...lesson, ["created"]: value });
                break;
            default:
                break;
        }
    }

    async function onSave(event) {
        event.preventDefault();
        setSubmitted(true);
        if (checkMissingData(lesson, ["id", "invoices", "request", "isPaidByCustomer", "isOnline"])) {
            loading(true);
            const form = {
                duration: lesson?.duration,
                skills: lesson?.skills?.map(skill => `${apiConstants.API_SKILLS}/${skill.value}`),
                isOnline: lesson?.isOnline,
                start: lesson?.date,
                isPaidByCustomer: lesson?.isPaidByCustomer,
                created: lesson?.created,
            };

            await apiService.patchLessonById(window.location.pathname.match(/\d+/)[0], form)
                .then(response => {
                    if (response) {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_SUCCESS,
                            title: appConstants.DEFAULT_SWAL_TITLE_UPDATE_SUCCESS,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                })
            setSubmitted(false);
            loading(false);

        } else {
            Swal.fire({
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                icon: appConstants.SWAL_ICON_ERROR,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        }

    }

    async function onConfirmDelete(event) {
        event.preventDefault();
        const onConfirm = async () => {
            loading(true);
            await apiService.deleteLessonById(window.location.pathname.match(/\d+/)[0])
                .then(response => {
                    if (response) {
                        loading(false);
                        props.history.push("/admin-home/tableau-de-bord");
                    } else {
                        globalSwal({
                            icon: appConstants.SWAL_ICON_ERROR,
                            title: appConstants.SWAL_MESSAGE_DELETE_ERROR,
                            confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                        });
                    }
                });
            loading(false);
        }
        alert(appConstants.DEFAULT_DELETE_CONFIRM_MESSAGE, onConfirm);
    }

    if (pageNotFound) {
        return <p className="text-center pt-5">{appConstants.DEFAULT_PAGE_NOT_FOUND}</p>;
    }

    return (
        <div className="pl-5 py-5 h-100 d-flex flex-column">
            <div className="d-flex h-100" style={{ overflow: "hidden" }}>
                <SideMenu id={lesson?.id} />

                <MenuWithButtons className="w-100 h-100 px-3 d-flex flex-column pb-4" style={{ overflow: "hidden" }}>
                    <ButtonMenu className="ml-3 mb-5" onActive={<MainInfoTab lesson={lesson} subjects={subjects} onChange={onChange} submitted={submitted} />}  >INFOS PRINCIPALES</ButtonMenu>
                </MenuWithButtons>

                <div className={"col px-0 h-100 pt-1 pr-1" + (appConstants.SCREEN_WIDTH_EXTRA_LARGE > windowWidth ? " pr-3" : "")}>
                    <ButtonAction className="tah-back-btn" color={colorsConstants.BLACK} onClick={() => { window.history.back(); }} >RETOUR</ButtonAction>
                </div>
            </div>

            {!pageNotFound ?
                <div className="d-flex" style={{ maxWidth: 25 + "%", fontSize: 75 + "%" }}>
                    <Button
                        variant=""
                        className="w-100 h-100 oc-button-empty-blue px-2 mr-3 "
                        style={{ fontSize: "inherit" }}
                        onClick={onSave}
                        disabled={typeof lesson === "undefined"}
                    >
                        SAUVEGARDER
                    </Button>
                    {enableDelete ? <Button
                        variant=""
                        className="w-100 h-100 empty-box-font oc-button-empty-blue custom-btn-red px-2"
                        style={{
                            fontSize: "inherit",
                            color: "#ea1616",
                            borderColor: "#ea1616",
                        }}
                        onClick={onConfirmDelete}
                    >
                        SUPPRIMER
                    </Button>
                        : null}
                </div>
                : null
            }
        </div >

    )
}

export default withRouter(Lesson);