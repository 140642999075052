import { confirmAlert } from "react-confirm-alert";

function alert(message, onConfirm = () => { }, onReject = () => { }) {
    return confirmAlert({
        message: message,
        onConfirm: onConfirm,
        onReject: onReject,
        customUI: ({ onClose }) => {
            return (
                <div
                    className="custom-ui p-5"
                    style={{ border: "solid 3px var(--purple-tah-color)", backgroundColor: "#ffffff" }}
                >
                    <h1
                        className="police-gilroy-font mb-4 text-center"
                        style={{ fontSize: 110 + "%" }}
                    >
                        {message}
                    </h1>

                    <div className="d-flex justify-content-between">
                        <button
                            className="empty-box-font p-3 mr-3 custom-btn-green"
                            style={{
                                color: "#1fb23b",
                                borderColor: "#1fb23b",
                                fontSize: 90 + "%",
                            }}
                            onClick={(event) => {
                                onClose();
                                onConfirm(event);
                            }}
                        >
                            CONFIRMER
                        </button>
                        <button
                            className="empty-box-font p-3 px-4 ml-3 custom-btn-red"
                            style={{
                                color: "#ea1616",
                                borderColor: "#ea1616",
                                fontSize: 90 + "%",
                            }}
                            onClick={(event) => {
                                onClose(event);
                                onReject(event);
                            }}
                        >
                            ANNULER
                        </button>
                    </div>
                </div>
            );
        },
    });
}

export default alert;
