export default class UserModel {
    /**
     * @param {number} id 
     * @param {string} lastname 
     * @param {string} firstname 
     * @param {string} email 
     */
    constructor(id, lastname, firstname, email) {
        this.id = id ? id : undefined;
        this.lastname = lastname ? lastname : undefined;
        this.firstname = firstname ? firstname : undefined;
        this.email = email ? email : undefined;
        
        this.extractUser = function () {
            return {
                id: this.id,
                lastname: this.lastname,
                firstname: this.firstname,
                email: this.email,
            }
        }
    }

        
    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }
}