import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from ".";
import { history } from "../_helpers";

export const userActions = {
  login,
  forgetPassword,
  logout,
  register,
  getAll,
  delete: _delete,
};

async function login(username, password) {
  return async (dispatch) => {
    dispatch(request({ username }));

    await userService.login(username, password).then(
      (user) => {
        if (user) {
          history.push("/");
          window.location.reload();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(setForgetPassword(true));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
  function setForgetPassword(data) {
    return { type: userConstants.FORGET_PASSWORD, data };
  }
}

function forgetPassword(user) {
  return (dispatch) => {
    userService.forgetPassword(user).then(
      (user) => {
        history.push("/login");
        dispatch(
          alertActions.success(
            "Votre mot de passe est bien réinitialisé. Merci de consulter vos mails."
          )
        );
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
        dispatch(
          alertActions.success(
            "Votre compte est bien créé, merci de vérifier vos mails."
          )
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
