import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

import PropTypes from 'prop-types';
import { appConstants } from "../../../../../_constants";

import 'react-phone-input-2/lib/style.css'

function PhoneFormGroupInput({ onChange = () => { }, value, label, className, submitted = false }) {
    const [inputValue, setInputValue] = useState(value);
    const [isValid, setIsValid] = useState(value ? true : false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    function handleOnChange(value, country) {
        const newPhone = `+${value}`;
        let valid = false;

        if (country?.format) {
            // Get number of digit required.
            const digitFormatCount = country.format?.split(".").length - 1;
            valid = digitFormatCount === value?.length;
        }
        setInputValue(value);
        setIsValid(valid);

        onChange(newPhone, label, valid);
    }

    return (
        <div className={className ? className : "form-group w-100 mx-3"}>
            <label className="text-input-font">
                {label}
            </label>
            <PhoneInput
                country={"fr"}
                value={inputValue}
                inputClass="input-font w-100"
                inputStyle={{ borderRadius: 0, backgroundColor: "#e6f0ff" }}
                buttonStyle={{
                    borderRadius: 0,
                    border: "solid 2px var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                }}
                onChange={handleOnChange}
            />
            {submitted ?
                inputValue ?
                    !isValid ?
                        <div className="invalid-feedback-custom">
                            {appConstants.INVALID_FEEDBACK_PHONE_FORMAT_ERROR}
                        </div>
                        : null
                    :
                    <div className="invalid-feedback-custom">
                        {appConstants.DEFAULT_INVALID_FEEDBACK}
                    </div>
                : null
            }
        </div>

    )
}


PhoneFormGroupInput.propTypes = {
    value: PropTypes.string,
    submitted: PropTypes.bool,
}

export default PhoneFormGroupInput;
