import React from 'react';
import NavbarEspace from './Navbar/Navbar';
import CourseReportForm from './Body/FormsCreate/report/courseReportForm';

class CourseReportFormPage extends React.Component {

    render() {

        return (
            <div className="window-size d-flex flex-column">
                <div className="d-block">
                    <NavbarEspace />
                </div>
                <div className="container-fluid h-100 d-flex px-0" style={{ maxHeight: 'calc(100vh - 80px)' }}>
                    <div className="row h-100 w-100 mx-0">
                        <div className="col px-0 h-100">
                            <CourseReportForm />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CourseReportFormPage;
