import React, { useEffect, useState } from "react";
import SelectAsyncSearchFormGroupInput from "../../../../Others/forms/components/selectAsyncSearchFormGroupInput";
import CRUDModal from "../../../../Modals/CRUDModal";
import { Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Swal from "sweetalert2";

import { apiService } from "../../../../../../_services/api.service";
import loading from "../../../../Others/process/loading";

import { appConstants } from "_constants";

import SchoolModel from "_models/school.model";
import OptionModel from "_models/option.model";

import "./adminCRUD.css";

export default function AdminCRUD({ value: valueProps, label = "", options: optionsProps = {}, className = "form-group d-flex w-100 align-items-end mx-3", hideLabel = false, submitted = false, onSearch = () => { return }, onChange: onChangeProps = () => { return },  onCreate = () => { return } }) {
    const [value, setValue] = useState(valueProps);
    const [openCRUDModal, setOpenCRUDModal] = useState(false);
    const [options, setOptions] = useState(optionsProps);

    useEffect(() => {
        if (valueProps instanceof OptionModel) {
            setValue(valueProps);
        } else {
            setValue(valueProps?.toOptionModel());
        }
    }, [valueProps])

    useEffect(() => {
        setOptions(optionsProps);
    }, [optionsProps])

    async function onSearch(input, callback) {
        await apiService.getSchoolWithCallback(input, callback);
    }

    async function onSubmit(school) {
        loading(true);
        if (!!school.id) {
            if (school.id === value.extra.id) {
                setValue(school.toOptionModel());
            }
            // Update
            await apiService.patchSchool(school.id, school.toForm()).then(schoolPatched => {
                if (schoolPatched instanceof SchoolModel) {
                    setValue(schoolPatched.toOptionModel());
                    onChangeProps(schoolPatched.toOptionModel(), label);
                } else {
                    setOpenCRUDModal(false);
                    Swal.fire({
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                        icon: appConstants.SWAL_ICON_ERROR,
                    });
                }
            });
        } else {
            // Create
            await apiService.postSchool(school.toForm())
                .then(newSchool => {
                    if (newSchool instanceof SchoolModel) {
                        setValue(newSchool.toOptionModel());
                        onCreate(newSchool);
                    } else {
                        setOpenCRUDModal(false);
                        Swal.fire({
                            title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                            text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                            icon: appConstants.SWAL_ICON_ERROR,
                        });
                    }
                })
                .catch(err => {
                    setOpenCRUDModal(false);
                    Swal.fire({
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: err?.response?.data?.message || appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                        icon: appConstants.SWAL_ICON_ERROR,
                    });
                })
        }
        loading(false);
    }

    async function onDelete(id) {
        loading(true);
        await apiService.deleteSchoolById(id)
            .then(result => {
                if (!!!result) {
                    Swal.fire({
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                        icon: appConstants.SWAL_ICON_ERROR,
                    });
                } else {
                    setOpenCRUDModal(false);
                    setValue(new OptionModel([], null, {}));
                }
            })
            .catch(err => {
                Swal.fire({
                    title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                    text: err?.response?.data?.message || appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                    icon: appConstants.SWAL_ICON_ERROR,
                });
            })
        loading(false);
    }

    function onChange(value, label) {
        setValue(value);
        onChangeProps(value, label);
    }

    return (
        <div className={className}>
            <CRUDModal open={openCRUDModal} /* onChange={onChange} */ onClose={() => setOpenCRUDModal(false)} defaultValue={value} options={options} onSearch={onSearch} onSubmit={onSubmit} onDelete={onDelete} />
            <SelectAsyncSearchFormGroupInput value={value} className="mb-0 w-100" defaultOptions={options?.schools} label={label} onChange={onChange} onSearch={onSearch} submitted={submitted} hideLabel={hideLabel} />
            <div className="d-flex ml-1">
                <Button variant="text" className="add-btn align-items-end w-100" style={{ outline: "none" }} onClick={() => setOpenCRUDModal(true)} >
                    <EditIcon />
                </Button>
            </div>
        </div>
    )
}