import OptionModel from "./option.model";
import TutorModel from "./tutor.model";

export default class MatchModel {
    constructor(percentMatchSkills, tutor = new TutorModel(), transport = {name: "", duration: -1}){
        this.percentMatchSkills = percentMatchSkills;
        this.tutor = tutor;
        this.transport = transport;
    }

    buildFromInstance(instance = {}) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }
}