import React, { } from 'react';
import TextFormGroupInput from '../../../../../components/admin/Others/forms/components/textFormGroupInput';
import SelectFormGroupInput from '../../../../../components/admin/Others/forms/components/selectFormGroupInput';
import TextAreaFormGroupInput from '../../../../../components/admin/Others/forms/components/textAreaFormGroupInput';
import SubjectArray from '../components/subjectsArray';
import MultiSelectFormGroupInput from 'components/admin/Others/forms/components/multiSelectFormGroupInput';
import PropTypes from 'prop-types';

import { appConstants } from '../../../../../_constants/app.constants';

function DetailsTab({ request, options, onChange, onlinePrices, homePrices, submitted }) {
    return (
        <div className="w-100 d-flex h-100 w-100 m-0" style={{ overflow: "auto" }}>
            <div
                className="col-5 h-100 pl-3 pr-4 d-flex flex-column police-gte-font"
                style={{
                    borderRight: "solid 1px var(--purple-tah-color)",
                    color: "#282828",
                    overflow: "hidden auto",
                }}
            >
                <p className="w-100 police-gilroy-font" style={{ fontSize: 110 + "%" }}>
                    DÉTAILS SÉANCE
                </p>

                <div className="d-flex justify-content-between w-100">
                    <TextFormGroupInput className="w-100 pr-3" label={appConstants.FORM_LABEL_NBR_LESSONS} value={request?.nbrSession} type="number" min="0" onChange={onChange} submitted={submitted} />
                    <TextFormGroupInput className="w-100 pl-3" label={appConstants.FORM_LABEL_LESSON_DURATION_OPTIONS} value={request?.duration} type="number" min="0" onChange={onChange} submitted={submitted} />
                </div>
                <div className="d-flex justify-content-between w-100">
                    <SelectFormGroupInput className="mx-0 w-50 pr-3" label={appConstants.FORM_LABEL_ONLINE_PRICE} value={request?.onlinePrice} options={onlinePrices} onChange={onChange} />
                    <SelectFormGroupInput className="mx-0 w-50 pl-3" label={appConstants.FORM_LABEL_PRICE} value={request?.price} options={homePrices} onChange={onChange} />
                </div>

                <div className="pl-0 form-group w-50 pr-3" style={{ height: "fit-content" }}>
                    <TextFormGroupInput className="w-100" label={appConstants.FORM_LABEL_STUDENT_LEVEL} value={request?.student?.level.title} disabled />
                </div>

                <p className="w-100 police-gilroy-font mt-3" style={{ fontSize: 110 + "%" }}>
                    PRÉFÉRENCES
                </p>

                <div className="d-flex justify-content-between w-100">
                    <SelectFormGroupInput className="mx-0 pr-3 w-100" label={appConstants.FORM_LABEL_GENDER_PREFERENCE} value={request?.genderPref} options={appConstants.DEFAULT_GENDER_PREFERENCE_OPTIONS} onChange={onChange} submitted={submitted} />
                    <SelectFormGroupInput className="mx-0 pl-3 w-100" label={appConstants.FORM_LABEL_GENDER_MANDAT} value={request?.genderMandat} options={appConstants.DEFAULT_TRUE_FALSE_OPTIONS} onChange={onChange} />
                </div>

                <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_NOTES} value={request?.notes} onChange={onChange} />
                <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_PRIVATE_NOTES} value={request?.privateNotes} onChange={onChange} dynamicHeight />

            </div>

            <div className="col-7 h-100 pr-1 pl-4 d-flex flex-column police-gte-font" style={{ overflow: "hidden auto" }}>
                <div className="mb-3">
                    <p className="w-100 mb-4 police-gilroy-font" style={{ fontSize: 110 + "%" }} >
                        DISPONIBILITÉ(S)
                    </p>
                    <TextAreaFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_AVAILABILITIES} value={request?.availabilities} onChange={onChange} dynamicHeight hideLabel/>
                </div>
                <div className="mb-3">
                    <p className="w-100 mb-4 police-gilroy-font" style={{ fontSize: 110 + "%" }} >
                        SPÉCIALITÉ(S)
                    </p>
                    <MultiSelectFormGroupInput className="mx-0 mb-3" label={appConstants.FORM_LABEL_OPTIONS} value={request?.student?.options} options={options?.subjects} hideLabel disable />
                </div>
                <div className="mb-3">
                    <SubjectArray className="mt-3" subjects={options?.subjects} subjectsRequest={request?.subjects} requestId={request?.id} onChange={onChange} submitted={submitted} />
                </div>
            </div>
        </div>
    )
}

DetailsTab.propTypes = {
    student: PropTypes.any,
    sessionData: PropTypes.any,
    subjectList: PropTypes.any,
    demandSubjectsList: PropTypes.any,
    pricesList: PropTypes.any,
    onlinePricesList: PropTypes.any,
    submitted: PropTypes.bool,
    onChange: PropTypes.func,
};

DetailsTab.defaultProps = {
    subjectList: [],
}

export default DetailsTab;
