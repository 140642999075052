import FormSection from "../../../../Others/forms/formSection";
import TextFormGroupInput from "../../../../Others/forms/components/textFormGroupInput";
import PhoneFormGroupInput from "../../../../Others/forms/components/phoneFormGroupInput";
import LoaderSpinner from "../../../../Others/loader/loader";
import SelectFormGroupInput from "../../../../Others/forms/components/selectFormGroupInput";
import MultiSelectFormGroupInput from "components/admin/Others/forms/components/multiSelectFormGroupInput";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../../_constants/app.constants";
import { apiConstants } from "_constants";
import TextAreaFormGroupInput from "../../../../Others/forms/components/textAreaFormGroupInput";

function MainInfoTab({ tutor, onChange, submitted, options }) {
    if (typeof tutor === "undefined") {
        return (<LoaderSpinner />)
    } else if (tutor === apiConstants.ERROR) {
        return <p className="text-center pt-5">{appConstants.DEFAULT_DATA_NOT_FOUND}</p>;
    }

    return (
        <form style={{ overflow: "hidden auto" }}>

            <FormSection title="INFORMATIONS PERSONNELLES">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_MAIL} value={tutor?.email} onChange={onChange} submitted={submitted} />
                </div>
                <div className="d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={tutor?.lastname} onChange={onChange} submitted={submitted} />
                    <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={tutor?.firstname} onChange={onChange} submitted={submitted} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={tutor?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                    <PhoneFormGroupInput label={appConstants.FORM_LABEL_PHONE} value={tutor?.phone} onChange={onChange} submitted={submitted} />
                </div>

                <div className="row w-100">
                    <div className="col-2">
                        <TextFormGroupInput label={appConstants.FORM_LABEL_ADDRESS_NUMBER} value={tutor?.address?.number} onChange={onChange} submitted={submitted} />
                    </div>
                    <div className="col-4">
                        <TextFormGroupInput label={appConstants.FORM_LABEL_ADDRESS_STREET} value={tutor?.address?.street} onChange={onChange} submitted={submitted} />
                    </div>
                    <div className="col-4">
                        <TextFormGroupInput label={appConstants.FORM_LABEL_ADDRESS_CITY} value={tutor?.address?.city} onChange={onChange} submitted={submitted} />
                    </div>
                    <div className="col-2">
                        <TextFormGroupInput label={appConstants.FORM_LABEL_ADDRESS_POSTCODE} value={tutor?.address?.zipcode} onChange={onChange} submitted={submitted} />
                    </div>
                </div>
            </FormSection>

            <FormSection title="COMPÉTENCES">
                <div className="row mt-3 w-100 m-0">
                    <MultiSelectFormGroupInput className="mx-0 col-6" label={appConstants.FORM_LABEL_SUBJECTS} value={tutor?.subjects} options={options?.subjects} onChange={onChange} invalidFeedBackMessage={appConstants.INVALID_FEEDBACK_SELECT_SKILLS_MIN_1} submitted={submitted} />
                    <MultiSelectFormGroupInput className="mx-0 col-6" label={appConstants.FORM_LABEL_PASSIONS} value={tutor?.hobbies} options={options?.hobbies} onChange={onChange} />
                </div>
            </FormSection>

            <FormSection title="INFORMATIONS COMPLEMENTAIRES">
                <div className="row mt-3 w-100 m-0">
                    <MultiSelectFormGroupInput className="mx-0 col-6" label={appConstants.FORM_LABEL_MOBILITY} value={tutor?.transports} options={options?.transports} onChange={onChange} />
                    <MultiSelectFormGroupInput className="mx-0 col-6" label={appConstants.FORM_LABEL_CHARACTERISTICS} value={tutor?.characteristics} options={options?.characteristics} onChange={onChange} />
                </div>
                <div className="row mt-3 w-100 m-0">
                    <TextAreaFormGroupInput className="ml-0 col-12" label={appConstants.FORM_LABEL_COMMENT} value={tutor?.notes} onChange={onChange} />
                </div>
            </FormSection>

            <FormSection title="INFORMATIONS BANCAIRES">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_SIRET} value={tutor?.siret} placeholder={!tutor?.siret ? appConstants.DEFAULT_NO_SIRET : null} onChange={onChange} submitted={submitted} required={false} />
                </div>
            </FormSection>

            <FormSection title="CRÉATION DE COMPTE">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_CREATION_DATE} value={tutor?.created} disabled />
                </div>
            </FormSection>
        </form>
    );

}

MainInfoTab.propTypes = {
    tutor: PropTypes.object,
    options: PropTypes.shape({
        subjects: PropTypes.array,
        hobbies: PropTypes.array,
    })
}

export default MainInfoTab;
