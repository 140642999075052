import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { appConstants } from "../../../../../_constants/app.constants";
import { useEffect, useState } from "react";


function ButtonLink({ children, className, link, firstname, lastname, newTab, style = {} }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const eventListener = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, []);

    return (
        <Link to={link ? link : ""} onClick={ (event) => !link ? event.preventDefault() : null } target={newTab ? "_blank" : null} rel={newTab ? "noreferrer" : null}>
            <Button
                className={className ? className : " btn w-100 oc-button-empty-blue px-3 py-2 align-self-center scrollbar-hidden"}
                style={{
                    ...style,
                    overflow: "auto hidden",
                    fontSize:
                        windowWidth > appConstants.SCREEN_WIDTH_EXTRA_LARGE
                            ? 1 + "vw"
                            : windowWidth > 992
                                ? 1 + "vw"
                                : windowWidth >= appConstants.SCREEN_WIDTH_MEDIUM
                                    ? 1.3 + "vw"
                                    : 2.2 + "vw",
                    border: !className ? "solid " + 2 + "px #3c8bec" : null,
                }}
            >
                <p
                    className="w-100 m-0"
                    style={{ overflow: "auto hidden" }}
                >
                    {!children ? firstname ? lastname ? `${firstname} ${lastname}` : `${firstname}` : appConstants.DEFAULT_NO_NAME : null}

                </p>
                {children ? children : null}
            </Button>
        </Link>
    )
}


ButtonLink.propTypes = {
    
}

export default ButtonLink;