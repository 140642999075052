import { appConstants, apiConstants } from "../../../../../_constants";
import FileFormGroupInput from "../../../Others/forms/components/fileFormFroupInput";
import SelectFormGroupInput from "../../../Others/forms/components/selectFormGroupInput";
import TextFormGroupInput from "../../../Others/forms/components/textFormGroupInput";
import LoaderSpinner from "../../../Others/loader/loader";
import ButtonLink from "../../../Others/buttons/buttonLink/buttonLink";
import MultiSelectFormGroupInput from "../../../Others/forms/components/multiSelectFormGroupInput";
import { useEffect, useState } from "react";

function MainInfoTab({ lesson: lessonProps, subjects, onChange, submitted }) {
    const [lesson, setLesson] = useState(lessonProps);
    const [disabledStateInput, setDisabledStateInput] = useState(lesson?.isPaidByCustomer);

    useEffect(() => {
        if (typeof lesson === "undefined" && lessonProps) {
            setDisabledStateInput(lessonProps?.isPaidByCustomer);
        }
        setLesson(lessonProps);
    }, [lessonProps, lesson]);

    if (typeof lesson === "undefined") {
        return <LoaderSpinner />
    } else if (lesson === apiConstants.ERROR) {
        return <p className="text-center pt-5">{appConstants.DEFAULT_DATA_NOT_FOUND}</p>;
    }

    return (
        <form style={{ overflow: "hidden auto" }}>

            <div className="d-flex align-items-center mb-3 pl-3 mt-1" style={{ flexShrink: 0 }}>
                <p className="mb-0 pl-0 col-2 police-gilroy-font">DEMANDE :</p>
                {lesson?.request?.id ?
                    <ButtonLink className="btn oc-button-empty-blue px-4 py-1 text-center" link={`/admin-home/formulaire-demande/${lesson?.request?.id}`} firstname={lesson?.request?.tutor?.firstname} lastname={`/ ${lesson?.request?.student?.firstname}`} />
                    : null
                }
            </div>

            <div className="d-flex justify-content-between w-100">
                <TextFormGroupInput label={appConstants.FORM_LABEL_LESSON_DURATION} value={lesson?.duration} type="number" onChange={onChange} submitted={submitted} />
                <MultiSelectFormGroupInput className="col-6" label={appConstants.FORM_LABEL_SUBJECTS} value={lesson?.skills} options={subjects} onChange={onChange} submitted={submitted} />
            </div>
            <div className="d-flex justify-content-between w-100">
                <SelectFormGroupInput label={appConstants.FORM_LABEL_LESSON_TYPE} value={lesson?.isOnline} options={appConstants.DEFAULT_LESSON_TYPE_OPTIONS} onChange={onChange} />
                <TextFormGroupInput label={appConstants.FORM_LABEL_DATE} value={lesson?.date} onChange={onChange} type="date" submitted={submitted} />
            </div>

            <div className="d-flex justify-content-between w-100">
                <TextFormGroupInput label={appConstants.FORM_LABEL_STATE} value={appConstants.DEFAULT_LESSON_PAYMENT_STATUS_OPTIONS.find(elt => elt.value === lesson?.isPaidByCustomer)?.label} options={appConstants.DEFAULT_LESSON_PAYMENT_STATUS_OPTIONS} disabled={true}/>
                <TextFormGroupInput label={appConstants.FORM_LABEL_DECLARATION_DATE} value={lesson?.created} onChange={onChange} type="date" submitted={submitted} />
            </div>

            <div className="form-row justify-content-between mx-3">
                <div className="col-6 pr-3 pl-0">
                    <FileFormGroupInput className="w-100" label={appConstants.FORM_LABEL_TUTOR_BILL} file={lesson?.invoices?.tutor} downloadOnly />
                </div>

                <div className="col-6 pl-3 pr-0">
                    <FileFormGroupInput className="w-100" label={appConstants.FORM_LABEL_CONSUMER_BILL} file={lesson?.invoices?.consumer} downloadOnly />
                </div>
            </div>

        </form>
    );
}

MainInfoTab.defaultProps = {
    onChange: () => { return }
}

export default MainInfoTab;