import { Button } from "react-bootstrap";

import { useEffect, useState } from "react";
import PropTypes from 'prop-types';


function ButtonMenu({ children, onClick, onActive, className, active = false }) {
    const [clicked, setClicked] = useState(active);

    useEffect(() => {
        setClicked(active);
    }, [active]);

    function handleClick(event) {
        setClicked(true);
        onClick(event, children, onActive);
    }

    return (
        <Button
            variant=""
            className={
                "w-100 mr-3 oc-button-empty " +
                (clicked ? "oc-button-clicked " : "") +
                className
            }
            style={{ width: 80 + "%", fontSize: "inherit" }}
            onClick={handleClick}
        >
            {children}
        </Button>

    )
}


ButtonMenu.ropTypes = {
    children: PropTypes.element,
    onClick: PropTypes.func,
    onActive: PropTypes.func,
    active: PropTypes.bool,
    className: PropTypes.string,
}

ButtonMenu.defaultProps = {
    onClick: () => { return },
    onActive: () => { return },
}

export default ButtonMenu;