import { apiConstants } from "_constants";
import SchoolModel from "./school.model";
import UserModel from "./user.model";

export default class thisModel extends UserModel {
    /**
     * @param {number} id
     * @param {string} lastname
     * @param {string} firstname
     * @param {string} email
     * @param {Array<OptionModel>} skills
     * @param {string} gender
     * @param {string} grade
     * @param {SchoolModel} school
     * @param {string} birthdate - Date of format : YYYY-MM-DD
     * @param {Array<OptionModel>} hobbies
     * @param {UserModel} parent
     * @param {object} lessons
     * @param options
     * @param characteristics
     */
    constructor(id, lastname, firstname, email, skills, gender, grade, school, birthdate, hobbies, parent, lessons, options, characteristics) {
        super(id, lastname, firstname, email);

        this.skills = skills ? skills : undefined;
        this.gender = gender ? gender : undefined;
        this.grade = grade ? grade : undefined;
        this.school = school ? school : undefined;
        this.birthdate = birthdate ? birthdate : undefined;
        this.hobbies = hobbies ? hobbies : undefined;
        this.parent = parent ? parent : undefined;
        this.lessons = lessons ? lessons : undefined;
        this.options = options || [];
        this.characteristics = characteristics || [];

        /**
         * @param {number} customerId - Student's parent ID.
         * @returns object to send thougth API.
         */
        this.toTutorForm = function (customerId) {
            return {
                customer: `${apiConstants.API_CUSTOMERS}/${customerId}`,
                firstname: this.firstname,
                lastname: this.lastname,
                skills: this.skills?.map(skill => `${apiConstants.API_SKILLS}/${skill.value}`),
                gender: this.gender,
                grade: `${apiConstants.API_GRADES}/${this.grade}`,
                birthdate: this.birthdate,
                options: this.options,
                school: `${apiConstants.API_SCHOOLS}/${this.school.value}`,
            }
        }
        /**
         * @returns object to send thougth API.
         */
        this.toFormUpdate = function () {
            const skills = [...(this.options ? this.options : []), ...(this.hobbies ? this.hobbies : [])]?.map(skill => `${apiConstants.API_SKILLS}/${skill.value}`)

            return {
                firstname: this.firstname,
                lastname: this.lastname,
                birthdate: this.birthdate,
                notes: this.notes,
                gender: this.gender,
                school: `${apiConstants.API_SCHOOLS}/${this.school.id}`,
                grade: `${apiConstants.API_GRADES}/${this.grade}`,
                skills: skills,
                email: this.email,
                characteristics: this.characteristics?.map(characteristic => `${apiConstants.API_CHARACTERISTICS}/${characteristic.value}`),
            };
        }
    }

    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }
}
