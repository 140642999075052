import { useEffect, useState } from "react";
import FormSection from "../../../../Others/forms/formSection";
import TextFormGroupInput from "../../../../Others/forms/components/textFormGroupInput";
import PhoneFormGroupInput from "../../../../Others/forms/components/phoneFormGroupInput";
import LoaderSpinner from "../../../../Others/loader/loader";
import SelectFormGroupInput from "../../../../Others/forms/components/selectFormGroupInput";
import FormAddress from "../../../../Others/forms/formAddress";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../../_constants/app.constants";

function MainInfoTab({ customer: customerProps, onChange, onSubmitAddress, submitted }) {
    const [customer, setCustomer] = useState(customerProps);

    useEffect(() => {
        setCustomer(customerProps);
    }, [customerProps]);

    if (typeof customer === "undefined") {
        return (
            <LoaderSpinner />
        )
    } else if (!customer) {
        return <div className="w-100 text-center">{appConstants.DEFAULT_DATA_NOT_FOUND}</div>
    }

    return (
        <form style={{ overflow: "hidden auto" }}>
            <FormSection title="INFORMATIONS PERSONNELLES">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_MAIL} value={customer?.email} onChange={onChange} submitted={submitted} />
                </div>

                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_LASTNAME} value={customer?.lastname} onChange={onChange} submitted={submitted} />
                    <TextFormGroupInput label={appConstants.FORM_LABEL_FIRSTNAME} value={customer?.firstname} onChange={onChange} submitted={submitted} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <SelectFormGroupInput label={appConstants.FORM_LABEL_CIVILITY} value={customer?.gender} options={appConstants.DEFAULT_GENDER_OPTIONS} onChange={onChange} submitted={submitted} />
                    <PhoneFormGroupInput label={appConstants.FORM_LABEL_PHONE} value={customer?.phone} onChange={onChange} submitted={submitted} />
                </div>
            </FormSection>

            <FormSection title="ADRESSE(S)">
                <FormAddress addresses={customer?.addresses} customer={customer?.extractUser()} onSubmitAddress={onSubmitAddress} />
            </FormSection>

            <FormSection title="CRÉATION DE COMPTE">
                <div className="mt-3 d-flex justify-content-between w-100">
                    <TextFormGroupInput label={appConstants.FORM_LABEL_CREATION_DATE} value={customer?.created} disabled />
                </div>
            </FormSection>
        </form>
    );

}

MainInfoTab.propTypes = {
    customer: PropTypes.object,
}

MainInfoTab.defaultProps = {
    onChange: () => { return }
}

export default MainInfoTab;
