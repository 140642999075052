import moment from "moment";
import { apiService } from "_services/api.service";

const { useState, useEffect } = require("react");

function InputTableFormGroup({ type: typeProps, value: valueProps, fieldName, urlContext, onChange: onChangeProps = () => { return }, edit = false, empty = false }) {
    const [value, setValue] = useState(valueProps ? valueProps : "");
    const [type, setType] = useState(typeProps);
    const [step, setStep] = useState(undefined);

    useEffect(() => {
        switch (typeProps) {
            case "integer":
                setStep(1);
                setType("number");
                break;
            case "float":
                setStep(0.01);
                setType("number");
                break;
            case "date":
                setStep(0.01);
                setType("datetime-local");
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        switch (typeProps) {
            case "integer":
                setType("number");
                break;
            case "float":
                setType("number");
                break;
            case "date":
                setType("datetime-local");
                break;
            default:
                setType(typeProps);
                break;
        }
    }, [typeProps]);

    useEffect(() => {
        // Update value when 'empty' prop is false
        if (!empty) {
            setValue(valueProps);
        }
    }, [valueProps, empty]);

    useEffect(() => {
        if (type === "datetime-local" && edit && !empty) {
            let newDate = moment(value, moment.HTML5_FMT.DATETIME_LOCAL_MS)?._i;
            // Avoid parsing date if date has no +XX:XX.
            if (newDate?.indexOf("+") > 0) {
                newDate = newDate?.substring(0, newDate?.indexOf("+"));
            }
            setValue(newDate);
        }
    }, [edit, empty, type, value]);

    function onChange(event) {
        event.preventDefault();
        let value = event.target.value;

        if (value === "true") {
            value = true
        } else if (value === "false") (
            value = false
        )

        setValue(value);
        onChangeProps(value, fieldName);
    }

    async function onSave(event) {
        event.preventDefault();
        if (!empty) {
            let rawKey = fieldName;
            if (rawKey.includes(" ID")) {
                rawKey = fieldName.split(" ID")[0]
            }
            rawKey = rawKey.replace(/\s/g, '');
            const form = {
                [rawKey]: value
            };

            await apiService.patch(urlContext, form);
        }
    }

    if (!edit) {
        return value === true ? 1 : value === false ? 0 : value ? value.toString() : null;
    }
    else if (type === "boolean") {
        return (
            <select className="w-100" value={value == true ? true : value == false ? false : value} onChange={onChange} style={{ height: 30 }} onBlur={onSave} >
                <option value="" hidden></option>
                <option value={true}>True</option>
                <option value={false}>False</option>
            </select>)
    }
    else {
        return (
            <input className="w-100" type={type} value={value} onChange={onChange} step={step} onBlur={onSave} />
        )
    }
}

export default InputTableFormGroup;
