import React from 'react'
import PropTypes from 'prop-types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

class MultiSelectCheckboxesApi extends React.Component {
    state = {
        optionsList: [],
    }

    componentDidMount = () => {
        this.setState({
            optionsList: this.props.optionsList,
            optionsSelectedList: this.props.optionsList
        })
    }

    onChangeMultiSelect = (value, event) => {
        //If "Tout" is selected -> no filter

        if (event.action === "select-option" && event.option.value === "") {
            this.setState({
                optionsSelectedList: this.state.optionsList,
            }, async () => { await this.props.onChange(this.state.optionsList) });

        } else if (event.action === "deselect-option" && event.option.value === "") {
            this.setState({
                optionsSelectedList: [],
            }, () => { this.props.onChange() });
        } else if (event.action === "deselect-option") {
            this.setState({
                optionsSelectedList: value.filter(o => o.value !== ""),
            }, () => { this.props.onChange(value.filter(o => o.value !== "")) });

        } else if (value.length === this.state.optionsList.length - 1) {
            this.setState({
                optionsSelectedList: this.state.optionsList,
            }, () => { this.props.onChange(this.state.optionsList) });
        } else {
            this.setState({
                optionsSelectedList: value,
            }, () => { this.props.onChange(value) });
        }
    }

    render() {
        // Use props 'optionsSelectedList' for persistance, otherwise data of this component will be lost on each reload of this component
        const optionsSelectedList = typeof this.props.optionsSelectedList !== "undefined" && this.props.optionsSelectedList > 0 ? this.props.optionsSelectedList : this.state.optionsSelectedList
        return (
            <ReactMultiSelectCheckboxes
                className="no-search-input-select"
                placeholderButtonLabel=""
                getDropdownButtonLabel={() => { return "" }}
                isMulti
                options={this.state.optionsList}
                value={optionsSelectedList}
                onChange={(value, e) => { this.onChangeMultiSelect(value, e) }}
                styles={
                    {
                        dropdownButton: () => ({
                            backgroundColor: '#ffffff',
                            borderColor: '#ffffff',
                            boxShadow: 'none',
                            border: 'none',
                            color: 'var(--purple-tah-color)'
                        }),
                        menu: () => ({
                            boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1),0 4px 11px hsla(0,0%,0%,0.1)'
                        })
                    }
                }
            />
        )
    }
}

MultiSelectCheckboxesApi.propTypes = {
    optionsList: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })),
    optionsSelectedList: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })),
    onChange: PropTypes.func,
}

MultiSelectCheckboxesApi.defaultProps = {
    onChange: () => { return },
}

export default MultiSelectCheckboxesApi;
