import { flatAddress } from "../_helpers";

export default class AddressModel {
    constructor(id, number, street, city, zipcode) {
        this.id = id ? id : null;
        this.number = number ? number : null;
        this.street = street ? street : null;
        this.city = city ? city : null;
        this.zipcode = zipcode ? zipcode : null;

        /**
         * Stringify address.
         * @returns string address.
         */
        this.stringify = function (format) {
            return flatAddress(this, format);
        }

        /**
         * Remove all data of object.
         * @returns cleared object;
         */
        this.clear = function () {
            this.id = null;
            this.number = null;
            this.street = null;
            this.city = null;
            this.zipcode = null;
            return this;
        }

        /**
         * Return the address object.
         * @returns the address object.
         */
        this.toObject = function () {
            return {
                id: this.id,
                number: this.number,
                street: this.street,
                city: this.city,
                zipcode: this.zipcode,
            }
        }

        /**
         * Return the address object for tutor in order to be send to API.
         * @returns the address object.
         */
        this.toTutorForm = function () {
            return {
                address: this.street,
                number: this.number,
                city: this.city,
                zipcode: this.zipcode,
            }
        }

        /**
         * Return the address object for customer in order to be send to API.
         * @returns the address object.
         */
         this.toCustomerForm = function () {
            return {
                street: this.street,
                number: this.number,
                city: this.city,
                zipcode: this.zipcode,
            }
        }

        /**
        * Return the address object.
        * @returns the address object.
        */
        this.build = function (input) {
            return Object.assign(this, input);
        }
    }

}