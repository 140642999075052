import FileFormGroupInput from "../../../../Others/forms/components/fileFormFroupInput";

import PropTypes from 'prop-types';

import { appConstants } from "../../../../../../_constants/app.constants";

import FileModel from '../../../../../../_models/file.model';
import LoaderSpinner from "../../../../Others/loader/loader";
import { apiConstants } from "_constants";

function DocumentsTab({ files, onChange = () => {return}  }) {
    if (typeof files === "undefined") {
        return <LoaderSpinner />
    }else if (files === apiConstants.ERROR) {
        return <p className="text-center pt-5">{appConstants.DEFAULT_DATA_NOT_FOUND}</p>;
    }

    return (
        <form>

            <FileFormGroupInput label={appConstants.FORM_LABEL_FILE_BAC} file={files?.bac} onChange={onChange}/>

            <FileFormGroupInput label={appConstants.FORM_LABEL_FILE_ID} file={files?.id} onChange={onChange}/>

            <FileFormGroupInput label={appConstants.FORM_LABEL_FILE_SCHOOL_PROOF} file={files?.schoolProof} onChange={onChange}/>

        </form>
    )
}

DocumentsTab.propTypes = {
    files: PropTypes.objectOf(PropTypes.instanceOf(FileModel)),
}

export default DocumentsTab;