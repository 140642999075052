import React, { useEffect, useState } from "react";
import { Dialog, IconButton } from "@material-ui/core";
import { Button } from "react-bootstrap";
import AddressFormGroupInput from "../Others/forms/components/addressFormGroupInput";
import MultiSelectFormGroupInput from "../Others/forms/components/multiSelectFormGroupInput";
import SelectAsyncSearchFormGroupInput from "../Others/forms/components/selectAsyncSearchFormGroupInput";
import TextFormGroupInput from "../Others/forms/components/textFormGroupInput";
import SimpleModal from "./modal";
import ButtonAction from "../Others/buttons/buttonAction/buttonAction";

import { keyToLabel, labelToKey } from "../../../_helpers";

import { appConstants, colorsConstants, modalConstants } from "../../../_constants";

import OptionModel from "../../../_models/option.model";

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AddressModel from "_models/address.model";

const ADDRESS_FIELDS = [appConstants.FORM_LABEL_ADDRESS_NUMBER, appConstants.FORM_LABEL_ADDRESS_STREET, appConstants.FORM_LABEL_ADDRESS_CITY, appConstants.FORM_LABEL_ADDRESS_POSTCODE]

export default function CRUDModal({ defaultValue: defaultValueProps = new OptionModel([], null, {}), open: openProps = false, onClose: onCloseProps = () => { return }, options = [], onChange: onChangeProps = (value) => { return }, onSearch: onSearchProps = (input, callback) => { return }, onSubmit: onSubmitProps = (value) => { return }, onDelete: onDeleteProps = () => { return } }) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(defaultValueProps);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        setOpen(openProps);
    }, [openProps])

    useEffect(() => {
        setValue(defaultValueProps);
    }, [defaultValueProps])

    function onChange(input, label) {
        let newValue = value;
        switch (label) {
            case appConstants.FORM_LABEL_SCHOOL:
                newValue = input;
                setValue(input);
                break;

            default:
                if (ADDRESS_FIELDS.includes(label)) {
                    newValue.extra.address[labelToKey(label)] = input;
                    setValue(newValue)
                } else {
                    let key = labelToKey(label);
                    newValue.extra[key] = input;
                    setValue(newValue)
                }
                break;
        }
        onChangeProps(newValue, label);
    }

    function onClose() {
        setOpen(false);
        onCloseProps();
    }

    async function onSubmit(_event) {
        onSubmitProps(value.extra);
    }

    function onCreateOption() {
        const emptyValue = options?.schools[0].extra;
        Object.keys(emptyValue).forEach(key => {
            // Case field is an array.
            if (key === "address") {
                emptyValue[key] = new AddressModel();
            } else if (Array.isArray(emptyValue[key])) {
                emptyValue[key] = [];
            } else {
                emptyValue[key] = undefined;
            }
        });
        setValue(new OptionModel(null, "", emptyValue));
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
        >
            {/** Confirm delete modal */}
            <SimpleModal open={!!idToDelete} onClose={() => setIdToDelete(null)} >
                <div className="form d-flex flex-column p-4 pb-4 form-container my-3 col-6 mx-auto w-100">
                    <h1
                        className="police-gilroy-font mb-4 text-center"
                        style={{ fontSize: 110 + "%" }}
                    >
                        {modalConstants.CONFIRM_SCHOOL_DELETE}
                    </h1>
                    <div className="d-flex">
                        <ButtonAction
                            className="w-100 align-self-center scrollbar-hidden mr-2"
                            color={colorsConstants.GREEN}
                            onClick={() => {
                                onDeleteProps(idToDelete)
                                setIdToDelete(null);
                            }}
                        >
                            Supprimer
                        </ButtonAction>
                        <ButtonAction
                            className="w-100 align-self-center scrollbar-hidden ml-2"
                            color={colorsConstants.RED}
                            onClick={() => {
                                setIdToDelete(null);
                            }}
                        >
                            Annuler
                        </ButtonAction>
                    </div>
                </div>
            </SimpleModal>

            {/** School form */}
            <div className="form d-flex flex-column p-4 pb-4 form-container my-0 mx-auto w-100" style={{ minHeight: "50vh", maxHeight: "65vh", overflow: "hidden scroll" }}>
                <h3 className="police-gilroy-font mx-auto" >CRUD</h3>
                <div className="form-group d-flex w-100 align-items-end">
                    <SelectAsyncSearchFormGroupInput value={value} className="w-100 mb-0" defaultOptions={options?.schools} label={appConstants.FORM_LABEL_SCHOOL} onChange={onChange} onSearch={onSearchProps} />
                    <div className="d-flex ml-3">
                        <Button variant="text" className="add-btn align-items-end w-100" style={{ outline: "none" }} onClick={onCreateOption} >
                            <AddIcon />
                        </Button>
                    </div>
                </div>
                <div className="card mt-4" hidden={!!!value?.extra}>
                    <div className="card-body">

                        {value?.extra && Object.keys(value.extra).map((key, index) => { // Display data from object as inputs.
                            switch (key) {
                                case "id":
                                    return <div className="d-flex justify-content-between align-items-center mb-4" index={index}>
                                        <h4 className="card-title police-gilroy-font mb-0">{!!value.extra[key] ? `École ID#${value.extra[key]}` : "Nouvelle école"}</h4>
                                        <IconButton className="p-1 outline-none" onClick={() => setIdToDelete(value.extra[key])}>
                                            <DeleteIcon style={{ color: "var(--red-tah-color)", outline: "none !important", border: "none !important" }} />
                                        </IconButton>
                                    </div>
                                case "address":
                                    return <AddressFormGroupInput index={index} label={appConstants.FORM_LABEL_ADDRESS} address={value.extra[key]} options={options[key]} onChange={onChange} formOnly />
                                default:
                                    if (Array.isArray(value.extra[key])) {
                                        return <MultiSelectFormGroupInput index={index} className="mx-0" label={keyToLabel(key)} options={options[key]} value={value.extra[key]} onChange={(value, label) => { onChange(value, label) }} />
                                    }
                                    return <TextFormGroupInput index={index} className="mx-0" label={keyToLabel(key)} value={value.extra[key]} onChange={onChange} />
                            }
                        })}
                    </div>

                    <Button variant="text" className="h-100 oc-button-empty-blue px-2 m-3" style={{ fontSize: "inherit" }} onClick={onSubmit}>
                        SAUVEGARDER
                    </Button>
                </div>

            </div>
        </Dialog>
    )
}