import React, { useEffect, useState } from 'react';
import LoaderSpinner from 'components/admin/Others/loader/loader';
import MultiSelectFormGroupInput from 'components/admin/Others/forms/components/multiSelectFormGroupInput';
import SelectFormGroupInput from 'components/admin/Others/forms/components/selectFormGroupInput';
import TextAreaFormGroupInput from 'components/admin/Others/forms/components/textAreaFormGroupInput';
import Swal from 'sweetalert2';
import { MixinSwal } from 'components/admin/Others/alert/mixinSwal';
import SelectAsyncSearchFormGroupInput from 'components/admin/Others/forms/components/selectAsyncSearchFormGroupInput';
import TextFormGroupInput from 'components/admin/Others/forms/components/textFormGroupInput';
import RatingStarsFormGroupInput from 'components/admin/Others/forms/components/ratingStarsFormGroupInput';

import _ from 'lodash';
import globalSwal from 'components/admin/Others/alert/globalSwal';
import { registerLocale } from 'react-datepicker';
import { apiService } from '_services/api.service';
import loading from 'components/admin/Others/process/loading';
import { checkMissingData } from '_helpers';
import { useHistory } from 'react-router-dom';
import fr from 'date-fns/locale/fr';

import { appConstants, buttonConstants, lessonConstants } from '_constants';
import ReportModel from '_models/report.model';
import OptionModel from '_models/option.model';

registerLocale('fr', fr);

export default function CourseReportForm() {
    const [report, setReport] = useState(new ReportModel());
    const [subjects, setSubjects] = useState();
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [courses, setCourses] = useState();
    const [courseTypesOptions, setCourseTypesOptions] = useState(_.cloneDeep(appConstants.DEFAULT_LESSON_TYPE_OPTIONS));
    const courseDurationOptions = lessonConstants.DURATIONS_LIST();
    const windowWidth = window.innerWidth;
    let history = useHistory();

    useEffect(() => {
        init()
    }, []);

    async function init() {
        const currentUser = await apiService.getCurrentUser();
        setReport({ ...report, comment: appConstants.DEFAULT_COMMENT_REPORT(currentUser?.firstname, currentUser?.lastname), })
        setSubjects(await apiService.getSkills(1, null));
        setCourses(await apiService.getRequestsToReport());
        setLoaded(true);
    };

    function onChange(value, label) {
        switch (label) {
            case appConstants.FORM_LABEL_COURSE_ID:
                const optionValue = value?.value;
                if (optionValue) {
                    const payload = {
                        request: new OptionModel(optionValue.id, value.label),
                        customerId: optionValue.customer.id,
                        tutorId: optionValue.tutor.id,
                    };

                    if (typeof optionValue?.price?.home !== "undefined" && typeof optionValue?.price?.online === "undefined") {
                        const newTypeCourseOptions = courseTypesOptions.map(elt => {
                            if (elt.value === true) {
                                elt.disabled = true
                            } else elt.disabled = false;
                            return elt;
                        });

                        setReport(report.updateFromRequestOption(payload, false));
                        setCourseTypesOptions(newTypeCourseOptions);
                    } else if (typeof optionValue.price?.home === "undefined" && typeof optionValue.price?.online !== "undefined") {
                        const newTypeCourseOptions = courseTypesOptions.map(elt => {
                            if (elt.value === false) {
                                elt.disabled = true
                            } else elt.disabled = false;
                            return elt;
                        });

                        setReport(report.updateFromRequestOption(payload, true));
                        setCourseTypesOptions(newTypeCourseOptions);
                    } else {
                        setReport(report.updateFromRequestOption(payload));
                        setCourseTypesOptions(_.cloneDeep(appConstants.DEFAULT_LESSON_TYPE_OPTIONS));
                    }
                } else {
                    setReport(report.updateFromRequestOption());
                    setCourseTypesOptions(_.cloneDeep(appConstants.DEFAULT_LESSON_TYPE_OPTIONS));
                }
                break;
            case appConstants.FORM_LABEL_COURSE_TYPE:
                setReport({ ...report, isOnline: value });
                break;
            case appConstants.FORM_LABEL_SUBJECTS:
                setReport({ ...report, skills: value });
                break;
            case appConstants.FORM_LABEL_DATE_COURSE:
                setReport({ ...report, start: value });
                break;
            case appConstants.FORM_LABEL_COURSE_DURATION:
                setReport({ ...report, duration: value });
                break;
            case appConstants.FORM_LABEL_COMMENT:
                setReport({ ...report, comment: value });
                break;
            case appConstants.FORM_LABEL_UNDERSTANDING_RATING:
                setReport({ ...report, grades: { ...report.grades, understanding: value } });
                break;
            case appConstants.FORM_LABEL_SPEED_RATING:
                setReport({ ...report, grades: { ...report.grades, speed: value } });
                break;
            case appConstants.FORM_LABEL_MOTIVATION_RATING:
                setReport({ ...report, grades: { ...report.grades, motivation: value } });
                break;
            case appConstants.FORM_LABEL_COURSE_SUMMARY:
                setReport({ ...report, summary: value });
                break;
            default:
                break;
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        setSubmitted(true);

        if (checkMissingData(report, ["comment"])) {
            loading(true);

            // Check for empty / undefined / null data has been proceed in handleOnSubmit function.
            await apiService.postLesson(report.toForm()).then(response => {
                setLoaded(true);
                setSubmitted(false);

                if (response) {
                    MixinSwal({
                        icon: appConstants.SWAL_ICON_SUCCESS,
                        title: appConstants.DEFAULT_SWAL_TITLE_SUCCESS,
                        text: appConstants.SWAL_MESSAGE_COURSE_DECLARATION_SUCCESS,
                    }).then(_ => {
                        history.push('/admin-home/gestion');
                    });
                } else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        text: appConstants.DEFAULT_SWAL_MESSAGE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            });
            loading(false);
        } else {
            setLoaded(true);
            Swal.fire({
                icon: appConstants.SWAL_ICON_ERROR,
                title: appConstants.DEFAULT_SWAL_TITLE_EMPTY_FIELDS,
                confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
            });
        };
    }

    if (!loaded) {
        return <LoaderSpinner />;
    }

    return (
        <div className="px-5 h-100 d-flex flex-column police-gte-font">

            <div className="pt-5 px-0 h-100 d-flex flex-column">
                <div className="w-100 mb-sm-5 mb-4 text-nowrap police-gilroy-font underline-title-font" style={{ fontSize: windowWidth > 992 ? 40 + 'px' : windowWidth > 576 ? 30 + 'px' : 5.3 + 'vw' }}>DÉCLARER UN COURS</div>

                <div className="row w-100 mx-0 h-100 mb-sm-5 mb-4" style={{ overflow: 'hidden auto' }}>

                    <div className={"h-100 w-100 d-flex flex-column px-1" + (windowWidth < 1200 ? " col px-0" : " sizing-adapted")}>
                        <form className="mb-5" onSubmit={onSubmit} autoComplete="off">
                            <div className="row w-100 m-0">

                                <div className="col-sm-6 col-12 pl-0 pr-sm-5 pr-0">
                                    <SelectAsyncSearchFormGroupInput value={report.request} className="pl-0 text-input-font" defaultOptions={courses} label={appConstants.FORM_LABEL_COURSE_ID} onChange={onChange} onSearch={apiService.getRequestWithCallback} submitted={submitted} />
                                </div>

                                <div className="col-sm-6 col-12 px-0 pl-sm-5 mb-sm-0 mb-3 mt-sm-0 mt-2 d-flex text-input-font">
                                    <SelectFormGroupInput className="mx-0" label={appConstants.FORM_LABEL_COURSE_TYPE} value={report.isOnline} options={courseTypesOptions} onChange={onChange} submitted={submitted} />
                                </div>
                            </div>

                            <div className="row w-100 m-0">
                                <MultiSelectFormGroupInput className="ml-0 w-100" label={appConstants.FORM_LABEL_SUBJECTS} value={report.skills} options={subjects} onChange={onChange} submitted={submitted} invalidFeedBackMessage={appConstants.INVALID_FEEDBACK_ADD_SKILLS_MIN_1} />
                            </div>

                            <div className="row w-100 m-0">
                                <div className="col-sm-6 col-12 px-0 pr-sm-5">
                                    <TextFormGroupInput className="w-100 pr-1 mb-0" label={appConstants.FORM_LABEL_DATE_COURSE} value={report.start} type="date" onChange={onChange} submitted={submitted} />
                                </div>
                                <div className="col-sm-6 col-12 px-0 pl-sm-5">
                                    <SelectFormGroupInput className="ml-0" label={appConstants.FORM_LABEL_COURSE_DURATION} value={report.duration} options={courseDurationOptions} onChange={onChange} submitted={submitted} />
                                </div>
                            </div>

                            <div className="row w-100 m-0">
                                <div className="col-sm-6 col-12 pl-0 pr-sm-5 pr-0">
                                    <TextAreaFormGroupInput className="ml-0 w-100" label={appConstants.FORM_LABEL_COURSE_SUMMARY} value={report.summary} onChange={onChange} />
                                </div>
                                <div className="col-sm-6 col-12 px-0 pl-sm-5">
                                    <TextAreaFormGroupInput className="ml-0 w-100" label={appConstants.FORM_LABEL_COMMENT} value={report.comment} onChange={onChange} />
                                </div>
                            </div>

                            <div className="row m-0 w-100">
                                <div className="col-sm col-12 d-sm-block d-flex px-0">
                                    <RatingStarsFormGroupInput size="large" label={appConstants.FORM_LABEL_UNDERSTANDING_RATING} value={report?.grades?.understanding} onChange={onChange} />
                                </div>
                                <div className="col-sm col-12 d-sm-block d-flex px-0">
                                    <RatingStarsFormGroupInput size="large" label={appConstants.FORM_LABEL_SPEED_RATING} value={report?.grades?.speed} onChange={onChange} />
                                </div>
                                <div className="col-sm col-12 d-sm-block d-flex px-0">
                                    <RatingStarsFormGroupInput size="large" label={appConstants.FORM_LABEL_MOTIVATION_RATING} value={report?.grades?.motivation} onChange={onChange} />
                                </div>
                            </div>
                            <div className="row w-100 mx-0 mb-5 mt-3">
                                <button className="btn empty-box-font px-5 py-2 custom-btn" style={{ fontSize: windowWidth > 1200 ? 1.1 + 'vw' : windowWidth > 992 ? 1.6 + 'vw' : windowWidth > 576 ? 2 + 'vw' : 4 + 'vw' }}  >{buttonConstants.FORM_SUBMIT}</button>
                            </div>

                        </form>
                    </div>

                </div>

            </div >
        </div >
    );
}