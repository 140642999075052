import React from 'react';
import NavbarEspace from './Navbar/Navbar';
import StatisticsTab from './Body/Tools/tabs/statisticsTab';

class TableauDeBordPage extends React.Component {

  render() {

    return (
      <div className="window-size d-flex flex-column">
        <div className="d-block">
          <NavbarEspace onglet="tableaudebord"/>
        </div>
        <div className="container-fluid h-100 d-flex px-0" style={{overflow: "hidden", maxHeight: 'calc(100vh - 80px)'}}>
          <div className="row h-100 w-100 mx-0">
            <div className="col px-0 mx-xl-5 py-5 h-100">
              <StatisticsTab hideLessons />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableauDeBordPage;

