import { apiConstants } from "_constants";
import OptionModel from "./option.model";

export default class ReportModel {
    /**
     * 
     * @param {OptionModel} request 
     * @param {number} tutorId 
     * @param {number} customerId 
     * @param {boolean} isOnline 
     * @param {Array<OptionModel>} skills 
     * @param {string} duration 
     * @param {string} start - Date of format : YYYY-MM-DD
     * @param {string} comment 
     */
    constructor(request, tutorId, customerId, isOnline, skills, duration, start, comment, grades = new GradesModel(), summary) {
        this.request = request || new OptionModel();
        this.tutorId = tutorId || undefined;
        this.customerId = customerId || undefined;
        this.isOnline = isOnline || undefined;
        this.skills = skills || [];
        this.duration = duration || undefined;
        this.start = start || undefined;
        this.comment = comment || undefined;
        this.grades = grades;
        this.summary = summary;

        /**
         * Update object according to parameters passed.
         * @param {object} payload 
         * @param {boolean} isOnline 
         * @returns report model object updated.
         */
        this.updateFromRequestOption = function (payload, isOnline) {
            if (typeof payload === 'undefined') {
                this.request = new OptionModel();
                this.customerId = undefined;
                this.tutorId = undefined;
                this.isOnline = undefined;
            } else {
                this.request = payload.request;
                this.customerId = payload.customerId;
                this.tutorId = payload.tutorId;
                this.isOnline = typeof isOnline !== undefined ? isOnline : this.isOnline;
            }
            return this;
        }

        /**
         * @returns object to send thougth API.
         */
        this.toForm = function () {
            return {
                isOnline: this.isOnline,
                request: `${apiConstants.API_REQUESTS}/${this.request.value}`,
                start: this.start,
                duration: (this.duration / 60).toString(),
                comment: this.comment,
                tutor: `${apiConstants.API_TUTORS}/${this.tutorId}`,
                customer: `${apiConstants.API_CUSTOMERS}/${this.customerId}`,
                skills: this.skills.map(subject => `${apiConstants.API_SKILLS}/${subject.value}`),
                motivationMark: this.grades?.motivation,
                understandingMark: this.grades?.understanding,
                progressMark: this.grades?.speed,
                summary: this.summary,
            }
        }

    }

}

class GradesModel {
    constructor(understanding, speed, motivation) {
        this.understanding = understanding;
        this.speed = speed;
        this.motivation = motivation;
    }
}