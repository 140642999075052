import { appConstants } from "../../../../../_constants/app.constants";

import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";

class ToolTipProps {
    constructor(title, placement, disabled) {
        return {
            title: title || "",
            placement: placement || "bottom",
            disabled: disabled !== undefined ? disabled : true,
        }
    }
}

function TextFormGroupInput({ onChange = () => { }, label, value = "", className, inputClassName, labelClassName, placeholder, submitted = false, disabled = false, hideLabel = false, type = "text", required = true, min = null, max = null, toolTip: toolTipProps = new ToolTipProps(), index }) {
    const [inputValue, setInputValue] = useState(value ? value : "");

    useEffect(() => {
        setInputValue(value ? value : "");
    }, [value]);

    function handleOnChange(event) {
        setInputValue(event.target.value);
        onChange(event.target.value, label);
    }

    return (
        <Tooltip title={toolTipProps.title} placement={toolTipProps.placement} disabled={toolTipProps.disabled} >
            <div className={className ? `form-group ${className}` : "form-group w-100 mx-3 "} index={index}>
                {!hideLabel ?
                    <label className={labelClassName ? labelClassName : "text-input-font"} data-testid="input-label">
                        {label}
                    </label>
                    : null
                }
                <input
                    className={
                        "form-control input-font " +
                        (submitted && !inputValue
                            ? "is-invalid "
                            : "") + (inputClassName ? inputClassName : "")
                    }
                    value={inputValue}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    disabled={disabled}
                    type={type}
                    min={min}
                    max={max}
                />
                {required ? <div className="invalid-feedback" data-testid="feedback">{appConstants.DEFAULT_INVALID_FEEDBACK}</div> : null}
            </div>
        </Tooltip>
    )
}

TextFormGroupInput.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    submitted: PropTypes.bool,
    type: PropTypes.string,
}

export default TextFormGroupInput;