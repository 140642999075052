import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLink from "components/admin/Others/buttons/buttonLink/buttonLink";
import { Button } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";

import { useState } from "react";
import { apiService } from "_services/api.service";
import loading from "components/admin/Others/process/loading";

import { apiConstants, appConstants, bddConstants } from "_constants";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import globalSwal from "components/admin/Others/alert/globalSwal";
import { dateConvertor, deleteUndefinedKeys } from "_helpers";
import moment from "moment";

export default function CandidatesArray({ list: listProps, publication, onSubmit = () => { }, applicant }) {
    const [list, setList] = useState(listProps);

    const windowWidth = window.innerWidth;
    const state = applicant ? bddConstants.APPLICATION_TYPE_ACCEPTED_DB : bddConstants.APPLICATION_TYPE_REJECTED_DB;
    let noData = false;
    let isData = false;

    async function handleClick(e, type, index) {
        e.preventDefault();

        list[index].state = type;
        setList(list);

        const form = deleteUndefinedKeys({
            status: type,
            request: type === bddConstants.APPLICATION_TYPE_ACCEPTED_DB ?
                {
                    published: !publication ? new Date() : undefined,
                    selectedTutor: new Date(),
                    state: bddConstants.REQUEST_ENABLED_DB,
                    tutor: list[index]?.tutor?.id ? `${apiConstants.API_TUTORS}/${list[index]?.tutor?.id}` : undefined,
                }
                : undefined
        });
        loading(true);
        await apiService.patchApplicationById(list[index].id, form)
            .then(async response => {
                if (response) {
                    const date = dateConvertor(moment().toISOString());
                    onSubmit({ state: bddConstants.REQUEST_AVAILABLE_DB, date: date }, [appConstants.FORM_LABEL_STATE, appConstants.FORM_LABEL_DATE_PUBLICATION, appConstants.FORM_LABEL_DATE_CANDIDATE_SELECTED]);
                } else {
                    globalSwal({
                        icon: appConstants.SWAL_ICON_ERROR,
                        title: appConstants.DEFAULT_SWAL_TITLE_ERROR,
                        confirmButtonText: appConstants.DEFAULT_SWAL_TEXT_BTN,
                    });
                }
            })
        loading(false);
    };

    if (!list) {
        return <div className="w-100 text-center">NO DATA</div>;
    }
    return (
        <div
            className="w-100 d-flex flex-column px-0 h-100"
            style={{ overflow: "hidden" }}
        >
            <div
                className="row m-0 mb-4 sticky-top police-gilroy-font"
                style={{
                    fontSize: windowWidth < 576 ? 4 + "vw" : 110 + "%",
                    borderBottom: "1px solid var(--purple-tah-color)",
                    backgroundColor: "#ffffff",
                    height: 35 + "px",
                }}
            >
                <div className="col-3 pl-0 mb-2 pl-1">TUTEUR</div>

                <div className="col-3 pl-5 mb-2 text-center">NUMÉRO</div>

                <div className="col-3 mb-2 text-center">DEMANDE(S)</div>
            </div>

            <div
                className="row d-block w-100 m-0 mb-0 h-100"
                style={{ overflow: "hidden auto" }}
            >
                {list?.map((obj, key) => {
                    if (obj.state === state || (!obj.state && state === bddConstants.APPLICATION_TYPE_ACCEPTED_DB)) {
                        isData = true;
                        return (
                            <div
                                className="row w-100 m-0 mb-2 py-2 police-gte-font align-items-center"
                                key={key}
                                style={{
                                    backgroundColor:
                                        obj.state === "validated"
                                            ? "rgba(31, 178, 59, 0.5)"
                                            : obj.state === "rejected"
                                                ? "rgba(234, 22, 22, 0.5)"
                                                : null,
                                }}
                            >
                                <div className="col-3 pr-0 pl-1">
                                    <ButtonLink link={obj?.tutor?.id ? "/admin-home/profils/tutors/" + obj.tutor?.id : null} firstname={obj?.tutor?.firstname} lastname={obj?.tutor?.lastname} newTab />
                                </div>

                                <div className="col-3 pl-5 text-center">{obj.phone}</div>

                                <div className="col-3 text-center">{obj.nbrDemand}</div>

                                {applicant ? (
                                    <div
                                        className="col-3 px-0 pr-1 d-flex justify-content-end"
                                        style={{ color: "#3c8bec" }}
                                    >
                                        <Button
                                            className="mr-4 custom-btn-green empty-box-font py-2"
                                            onClick={(e) => {
                                                handleClick(e, "validated", key);
                                            }}
                                        >
                                            <CheckIcon />
                                        </Button>
                                        <Button
                                            variant=""
                                            className="oc-button2 align-self-center py-2 px-3"
                                            style={{
                                                float: "right",
                                                paddingLeft: "15px",
                                                paddingRight: "15px",
                                            }}
                                            onClick={(e) => {
                                                handleClick(e, "rejected", key);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                className="oc-icon2"
                                                size="1x"
                                            />
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        );
                    } else {
                        noData = true;
                    }
                    return null;
                })
                }
                {noData && !isData ? (
                    <div className="w-100 text-center">NO DATA</div>
                ) : null}
            </div>
        </div>
    );
}
