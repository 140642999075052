export default class OptionModel {
    constructor(value, label, extra) {
        this.value = value ? value : undefined;
        this.label = label ? label : '';
        this.extra = extra;
    }
    
    buildFromInstance(instance) {
        Object.keys(this).forEach((key) => {
            this[key] = instance[key];
        });
        return this;
    }
}