import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';

import "./modal.css";

export default function SimpleModal({ open: openProps = false, children, onClose: onCloseProps = () => { return } }) {
    const [open, setOpen] = useState(openProps);

    useEffect(() => {
        setOpen(openProps);
    }, [openProps]);

    const handleClose = () => {
        setOpen(false);
        onCloseProps();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {children}
            </Modal>
        </div>
    );
}
